import React, {useState} from 'react';
import {Link, Redirect, useParams, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import InputField from '../../Components/InputField';
import validateLogin from '../../Libs/validateLogin';
import {login} from './actions';
import Loadable from '../../Components/Loadable';
import {makeSelectMode, makeSelectState, makeSelectStatus} from './selectors';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const state = useSelector(makeSelectState());
  const status = useSelector(makeSelectStatus());
  const [validation, setValidation] = useState('validated');
  const mode = useSelector(makeSelectMode());
  const {slug} = useParams();
  const {pathname} = useLocation();
  const dispatch = useDispatch();
  const handleLogin = (e) => {
    e.preventDefault();
    const details = {email, password};
    const validated = validateLogin(details);
    setValidation(validated);
    if (validated === 'validated') {
      dispatch(login(details));
    }
  };
  if (
    state === 'success' &&
    mode === 'login' &&
    pathname === `/shop/${slug}/authenticate`
  ) {
    return <Redirect to={`/shop/${slug}/profile/dashboard`} />;
  }
  return (
    <section id="login">
      <form className="card" onSubmit={handleLogin}>
        <div className="header">
          <h3>EXISTING MEMBER</h3>
        </div>
        {validation !== 'validated' ? (
          <p className="alert alert-danger">{validation}</p>
        ) : null}
        {state === 'error' ? (
          <p className="alert alert-danger">{status}</p>
        ) : null}
        <div className="body">
          <p>If you have an account, please log in below.</p>
          <InputField
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <InputField
            name="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="footer">
          <Link to="password/reset">FORGOT YOUR PASSWORD?</Link>
          <Loadable active={state === 'loading' && mode === 'login'}>
            <button type="submit">LOG IN</button>
          </Loadable>
        </div>
      </form>
    </section>
  );
}

export default Login;
