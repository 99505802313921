import React from 'react';
import {Link} from 'react-router-dom';

function OurFoodInfo() {
  return (
    <div className="column f-1">
      <p>
        Great taste and great value are not enough. You want to be confident
        that what you eat yourself and feed to your family is safe and
        wholesome.
      </p>
      <p>
        Our Traditional Chicken Pieces are prepared and cooked in store using
        top quality fresh chicken.
      </p>
      <p>
        Our Chicken Burgers, Crispy Fillet Strips and Chicken Poppas are not
        minced or processed, just tender chicken breast coated and cooked the
        way only Favorite can.
      </p>
      <p>
        Our Fries are pure potato, blanched in sunflower oil and all Favorite
        cooking oils are free from harmful trans-fats.
      </p>
      <h2>ALLERGENS IN OUR FOOD</h2>
      <p>
        There are 14 major allergens which need to be mentioned when they are
        used as ingredients in a food.
      </p>
      <p>
        The information provided here is to help inform you on the Allergens
        within our ingredients and we include Allergen Awareness for our store
        team as part fo their training.
      </p>
      <p>
        However at Favorite we freshly prepare our food in-store, in an open
        kitchen environment and as such we cannot declare any of our products to
        be wholly free of Allergens.
      </p>
      <p>
        At Favorite we freshly prepare our food in-store, in an open kitchen
        environment and as such we cannot declare any of our products to be
        wholly free of Allergens.
      </p>
      <p>
        Select an item to view allergen information or{' '}
        <Link className="text-primary" to="/our-food/allergens">
          click here to learn more
        </Link>
        .
      </p>
    </div>
  );
}

export default OurFoodInfo;
