import React from 'react';
import {useParams, Link} from 'react-router-dom';

const Topbar = () => {
  const {slug} = useParams();
  return (
    <section className="topbar">
      <div className="logo-wrapper">
        <Link to={slug ? `/shop/${slug}/menu` : '/'} className="logo" />
      </div>
      {/* <div className="app-wrapper"> */}
      {/*  <a */}
      {/*    target="_blank" */}
      {/*    href="https://play.google.com/store/apps/details?id=uk.co.favorite.storefinder" */}
      {/*    className="android" */}
      {/*  /> */}
      {/*  <a */}
      {/*    target="_blank" */}
      {/*    href="https://itunes.apple.com/WebObjects/MZStore.woa/wa/search?term=favorite%20fried%20chicken%20limited&mt=8" */}
      {/*    className="ios" */}
      {/*  /> */}
      {/* </div> */}
    </section>
  );
};

export default Topbar;
