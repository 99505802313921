import React, {useEffect} from 'react';
import loadBookTables from '../../Libs/loadBookTable';
import Master from '../Master';
import Navbar from '../../Components/Navbar';

function BookTable() {
  useEffect(() => {
    loadBookTables(() => {});
  }, []);
  return (
    <Master navbar={Navbar}>
      <section id="book-table">
        <div
          className="j-hostme-widget"
          data-hostme-id="16733"
          data-widget-type="vertical"
          data-allow-fast-booking="true"
          data-reserve-date="next_available"
          data-show-events="16733"
        />
      </section>
    </Master>
  );
}

export default BookTable;
