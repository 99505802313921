import React from 'react';
import {Link, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import SideCartItem from './SideCartItem';
import {
  makeSelectCheckoutMode,
  makeSelectProducts,
} from '../Pages/Cart/selectors';
import {cartTotal} from '../Libs/prices';
import Price from './Price';
import CheckoutMode from './CheckoutMode';

function SideCart() {
  const {slug} = useParams();
  const products = useSelector(makeSelectProducts());
  const mode = useSelector(makeSelectCheckoutMode());
  return (
    <div className="cart">
      <div className="header">
        <h2>BASKET</h2>
        <i className="fas fa-shopping-basket" />
      </div>
      <CheckoutMode />
      <div className="body">
        {products.length > 0 ? (
          products.map((product, index) => (
            <SideCartItem
              key={product.title + index}
              product={product}
              index={index}
            />
          ))
        ) : (
          <div className="alert">
            <p>Cart Empty</p>
          </div>
        )}
      </div>
      <div className="total">
        <h3 className="label">Total</h3>
        <p>
          <Price>{cartTotal(products, mode)}</Price>
        </p>
      </div>
      <Link to={`/shop/${slug}/cart`} className="checkout-button">
        <span>CHECKOUT</span>
      </Link>
    </div>
  );
}

export default SideCart;
