import {call, put, select, takeLatest, all} from 'redux-saga/effects';
import {push} from 'connected-react-router';
import apiUrl from '../../Configs/ApiUrl';
import apiCall from '../../Services/ApiCall';
import {CHECKOUT, SYNC_CART} from './constants';
import {
  checkoutError,
  checkoutSuccess,
  syncCartError,
  syncCartSuccess,
} from './actions';
import {
  makeSelectBookingId,
  makeSelectDetails,
  makeSelectUserId,
} from './selectors';
import {makeSelectToken} from '../Authentication/selectors';
import {
  makeSelectCheckoutMode,
  makeSelectProducts,
  makeSelectVoucher,
} from '../Cart/selectors';
import {makeSelectShop} from '../Shop/selectors';
import AuthorizedPostHeaders from '../../Configs/AuthorizedPostHeaders';
import {cartTotal, getDeliveryCharges} from '../../Libs/prices';
import {clearUser} from '../Authentication/actions';

export function* syncCart() {
  const details = yield select(makeSelectDetails());
  const products = yield select(makeSelectProducts());
  const shop = yield select(makeSelectShop());
  const token = yield select(makeSelectToken());
  const mode = yield select(makeSelectCheckoutMode());
  let requestUrl = '';
  if (token.length) {
    requestUrl = `${apiUrl}/cart/syncFullCart`;
  } else {
    requestUrl = `${apiUrl}/cart/syncFullCartGuest`;
  }
  const options = AuthorizedPostHeaders(
    {
      formData: details,
      email_subscription: 0,
      mobile_subscription: 0,
      cart: products,
      branch_slug: shop.slug,
      checkout_mode: mode,
    },
    token,
  );
  try {
    const {bookingID, userID} = yield call(apiCall, requestUrl, options);
    yield put(syncCartSuccess(bookingID, userID));
  } catch (e) {
    // console.log('err occurred', e);
    if (e.message === 'Unauthorized') {
      yield put(clearUser());
      yield put(push(`/shop/${shop.slug}/authenticate`));
    } else {
      yield put(syncCartError(e.message));
    }
  }
}

export function* checkout({payment}) {
  const details = yield select(makeSelectDetails());
  const bookingId = yield select(makeSelectBookingId());
  const shop = yield select(makeSelectShop());
  const products = yield select(makeSelectProducts());
  const mode = yield select(makeSelectCheckoutMode());
  const voucher = yield select(makeSelectVoucher());
  const userId = yield select(makeSelectUserId());
  const token = yield select(makeSelectToken());
  const subTotal = cartTotal(products, mode);

  let requestUrl = '';
  if (token.length) {
    requestUrl = `${apiUrl}/bookings/generateBooking`;
  } else {
    requestUrl = `${apiUrl}/bookings/generateBookingGuest`;
  }
  const options = AuthorizedPostHeaders(
    {
      payment,
      checkout: {
        user_id: userId,
        booking_id: bookingId,
        user_address_id: details.user_address_id,
        checkout_mode: mode,
        delivery_charges: getDeliveryCharges(
          shop.delivery_operation,
          mode,
          subTotal,
        ),
        sub_total: subTotal,
      },
      voucher,
    },
    token,
  );
  try {
    yield call(apiCall, requestUrl, options);
    yield put(checkoutSuccess());
  } catch (e) {
    console.log('err occurred', e);
    if (e.message === 'Unauthorized') {
      yield put(clearUser());
      yield put(push(`/shop/${shop.slug}/authenticate`));
    } else {
      yield put(checkoutError(e.message));
    }
  }
}

function* syncCartSaga() {
  yield takeLatest(SYNC_CART, syncCart);
}

function* checkoutSaga() {
  yield takeLatest(CHECKOUT, checkout);
}

export default function* rootSata() {
  yield all([syncCartSaga(), checkoutSaga()]);
}
