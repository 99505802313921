import React from 'react';
import {Link, useParams, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {logout} from '../Pages/Authentication/actions';
import {makeSelectUser} from '../Pages/Authentication/selectors';

function ProfileSideBar() {
  const user = useSelector(makeSelectUser());
  const {pathname} = useLocation();
  const {slug} = useParams();
  const dispatch = useDispatch();
  return (
    <div className="w-25 profile-summary">
      <div className="summary">
        <div className="header">
          <h2>{user.user_addresses[0].name}</h2>
          <p>
            <strong>E </strong>
            {user.email}
          </p>
          <p>
            <strong>T </strong>
            {user.user_addresses[0].telephone}
          </p>
        </div>
        <div className="body">
          <Link
            className={
              pathname === `/shop/${slug}/profile/dashboard` ? 'active' : ''
            }
            to={`/shop/${slug}/profile/dashboard`}
          >
            DASHBOARD
          </Link>
          <Link
            className={
              pathname === `/shop/${slug}/profile/address` ? 'active' : ''
            }
            to={`/shop/${slug}/profile/address`}
          >
            ADDRESS
          </Link>
          <Link
            className={
              pathname === `/shop/${slug}/profile/orders` ? 'active' : ''
            }
            to={`/shop/${slug}/profile/orders`}
          >
            ORDERS
          </Link>
          <Link
            className={
              pathname === `/shop/${slug}/profile/vouchers` ? 'active' : ''
            }
            to={`/shop/${slug}/profile/vouchers`}
          >
            VOUCHERS
          </Link>
          <Link
            className={
              pathname === `/shop/${slug}/profile/marketing-preferences`
                ? 'active'
                : ''
            }
            to={`/shop/${slug}/profile/marketing-preferences`}
          >
            MARKETING PREFERENCES
          </Link>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              dispatch(logout());
            }}
          >
            LOGOUT
          </a>
        </div>
      </div>
    </div>
  );
}

export default ProfileSideBar;
