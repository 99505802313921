import React from 'react';
import Master from '../Master';
import Navbar from '../../Components/Navbar';
import Header from '../../Components/Header';

function TermsConditions() {
  return (
    <Master navbar={Navbar}>
      <Header title="Terms and Conditions" />
      <section id="terms-conditions">
        <div className="column">
          <h1>WEBSITE AND APPLICATIONS TERMS OF USE</h1>
          <p>
            These terms of use set out the terms on which you may make use of
            the websites (izmirbargrill.co.uk, izmirbargrill.uk or individual
            store websites on these domains) and mobile, tablet and other
            applications (our &ldquo;<strong>Websites</strong>&rdquo;) that we
            have created, whether as a guest or registered user. Use of our
            Sites includes accessing, browsing, or registering to use the Sites.
          </p>
          <p>
            Please read these terms of use carefully before you start using our
            Websites, as these terms will apply to your use of our Websites. We
            recommend that you print a copy for future reference.
          </p>
          <p>
            By accessing, browsing and using our Websites, you confirm that you
            accept these terms of use and that you agree to comply with them. If
            you do not agree to these terms of use, you must not use any of our
            Websites.
          </p>
          <p>
            Your use of our Websites, and any orders you place, is governed by
            our{' '}
            <a href="/terms-conditions" className="text-primary">
              <strong>Terms of Use</strong>
            </a>
            ,{' '}
            <a href="/terms-of-sale" className="text-primary">
              <strong>Terms of Sale</strong>
            </a>
            ,{' '}
            <a href="/privacy-policy" className="text-primary">
              <strong>Privacy Policy</strong>
            </a>{' '}
            and our{' '}
            <a href="/cookies" className="text-primary">
              Cookie Policy
            </a>
            . Please take the time to read these as they include important terms
            which apply to you.
          </p>
          <h3>INFORMATION ABOUT US</h3>
          <p>
            Our Websites are operated by Favorite Fried Chicken Limited
            (&ldquo;we&rdquo;, &ldquo;our&rdquo; or &ldquo;us&rdquo;). We are
            registered in England and Wales under company number 2044486 at 7
            Davy Road, Clacton on Sea, Essex, CO15 4XD. You can contact us using
            our{' '}
            <a href="/contact-us" className="text-primary">
              <strong>Contact Form</strong>
            </a>
            . Our VAT number is 442116683.
          </p>
          <h3>ACCESSING OUR SITES</h3>
          <p>
            Our Websites are made available free of charge for your personal
            use.
          </p>
          <p>
            We do not guarantee that our Websites, or any content on them, will
            always be available or be uninterrupted. Access to our Websites is
            permitted on a temporary basis. We may suspend, withdraw,
            discontinue or change all or any part of our Websites without
            notice. We will not be liable to you if for any reason our Websites
            are unavailable at any time or for any period.
          </p>
          <p>
            You are responsible for making all arrangements necessary for you to
            have access to our Websites. You are also responsible for ensuring
            that all persons who access our Websites through your internet
            connection are aware of these terms of use and other applicable
            terms and conditions, and that they comply with them.
          </p>
          <h3>INTELLECTUAL PROPERTY RIGHTS</h3>
          <p>
            Our Websites, and their content, functionality and design, are
            protected in the UK and elsewhere in the world by copyright laws and
            treaties, trademarks and other intellectual property rights which
            are either used by us under licence or owned by us. All such rights
            are reserved.
          </p>
          <p>
            You may not distribute, reproduce, modify, transmit, reuse, re-post
            or use our Websites, or the content, functionality or design of our
            Websites (or any part of each) in any manner whatsoever, except as
            provided in these terms of use or the text on our Websites or within
            the terms of any written permission granted by us in advance of such
            use (and in respect of which we neither warrant nor represent that
            your proposed use will not infringe any third party rights).
          </p>
          <p>
            You may print off one copy, and download extracts, of any page(s)
            from our Websites for your personal use provided that (a) you do not
            remove or alter any copyright and other proprietary notices
            contained on the materials and (b) all use is for lawful purposes
            only.
          </p>
          <p>
            You must not modify the paper or digital copies of any materials you
            have printed off or downloaded in any way, and you must not use any
            illustrations, photographs, video or audio sequences or any graphics
            separately from any accompanying text.
          </p>
          <p>
            If you print off, copy or download any part of our Sites in breach
            of these terms of use, your right to use our Sites will cease
            immediately and you must, at our option, return or destroy any
            copies of the materials you have made.
          </p>
          <p>
            If you download any software from our Sites, the software, including
            all files, images, contained in or generated by the software and
            accompanying data (together, the &ldquo;Software&rdquo;) are
            licensed to you by us. The ownership of any such Software shall but
            remain with us. You may not resell, decompile, reverse engineer,
            disassemble or otherwise convert the Software to a human perceivable
            form. Either of us may terminate the licence at any time immediately
            with or without notice and on such termination you must destroy all
            materials including (but not limited to) the Software obtained from
            the Sites and all copies.
          </p>
          <p>
            If you would like to request permission to use photography or other
            content from our Sites, please contact marketing@favorite.co.uk.
          </p>
          <h3>OWNERSHIP OF TRADE MARKS</h3>
          <p>
            The trademarks and logos displayed on our Sites include the
            registered and unregistered trademarks of us and our licensors.
            Nothing in these terms of use may be construed as granting any
            licence or right to use any of those trademarks without the prior
            written permission of us or our licensors. Any use of the
            trademarks, except as provided in these terms of use, is strictly
            prohibited.
          </p>
          <h3>VIRUSES</h3>
          <p>
            You must not misuse our Websites by knowingly introducing viruses,
            trojans, worms, logic bombs or other material which is malicious or
            technologically harmful. You must not attempt to gain unauthorised
            access to our Websites, the server on which our Websites are stored,
            or any server, computer or database connected to our Websites.
          </p>
          <p>
            You must not attack our Websites via a denial-of-service attack or a
            distributed denial-of-service attack. By breaching this provision,
            you are committing a criminal offence under the Computer Misuse Act
            1990. We will report any such breach to the relevant law enforcement
            authorities and we will co-operate with those authorities by
            disclosing your identity to them. In the event of such a breach,
            your right to use our Websites will cease immediately.
          </p>
          <h3>EXCLUSIONS OF LIABILITY</h3>
          <p>
            The materials contained in or displayed on our Websites are provided
            &ldquo;as is&rdquo; and without warranties or representations of any
            kind either express or implied. We disclaim all warranties of
            satisfactory quality and fitness for a particular purpose. We do not
            warrant that the content and information displayed on our Websites
            is error-free, accurate, complete and up-to-date, or that it does
            not infringe the rights of any third party.
          </p>
          <p>
            We will not be liable for any damage or injury howsoever caused
            including, but not limited to, any failure of performance, error,
            omission, interruption, defect, delay in operation of transmission
            or line failure. We will not be liable for any damage or injury that
            results from your use of, or inability to use, the materials on our
            Websites howsoever caused.
          </p>
          <p>
            We use reasonable efforts to ensure that our Websites are free from
            bugs, viruses and other malicious content; however we do not
            guarantee that our Websites are secure. You are responsible for
            configuring your information technology, computer programmes and
            platform in order to access our Websites. You should use your own
            virus protection software.
          </p>
          <p>
            We and any other party involved in producing or delivering our
            Websites shall not be liable for any loss or damage caused by a
            virus, distributed denial-of-service attack, or other
            technologically harmful material that may infect your computer
            equipment, programs, data or other proprietary material due to your
            use of our Websites or to your downloading of any content on it, or
            on any website linked to it.
          </p>
          <p>
            Nothing in these terms of use excludes or limits our liability for
            death or personal injury arising from our negligence, or our fraud
            or fraudulent misrepresentation, or any other liability that cannot
            be excluded or limited by English law.
          </p>
          <p>
            You agree to indemnify and hold us harmless from all liabilities,
            claims and expenses that arise out of the content you submit, post
            or transmit via our Websites, or from your use/misuse of our
            Websites or the use/misuse by any person for whom you are
            responsible or from your violation of these terms of use.
          </p>
          <h3>WHO OWNS THE MATERIAL YOU SEND TO US?</h3>
          <p>
            Any communication or material you transmit to our Websites by
            electronic mail or otherwise, including any data, questions,
            comments, suggestions, ideas, graphics or the like is, and will be
            treated as, non-confidential and non-proprietary but we shall comply
            with our Privacy Policy in respect of any personal information we
            receive from you.
          </p>
          <p>
            Anything you transmit or post shall, subject to our Privacy Policy,
            become our exclusive property which may be used by us and selected
            third parties performing services on our behalf at any time and for
            any purpose, including, but not limited to, reproduction,
            disclosure, transmission, publication, broadcast and posting.
          </p>
          <p>
            We are free to use any ideas, concepts, know-how or techniques
            contained in any communication you send to our Websites for any
            purpose whatsoever including, but not limited to, developing,
            manufacturing and marketing products using such information.
          </p>
          <p>
            In addition, depending on how you submit your communication to us,
            we may include your Facebook profile name, Google user name, email
            user name or any other user name (but not your email address) with
            any publication or re-publication of the contents of your
            communication.
          </p>
          <p>
            The views expressed in any user generated content on our Websites
            are the opinions of those users and do not represent our views,
            opinions, beliefs or values.
          </p>
          <h3>LINKS TO OTHER WEBSITES AND APPS</h3>
          <p>
            Where our Websites contain links to other Websites and resources
            provided by third parties, these links are provided for your
            information only. We are not responsible for, or control or endorse,
            the content of any websites and applications linked to our Websites.
            We will not be liable for any loss or damage that may arise from
            your use of them.
          </p>
          <h3>CHANGES TO THESE TERMS</h3>
          <p>
            We may revise these terms of use at any time by amending this page.
            Please check this page from time to time to take notice of any
            changes we made, as they are binding on you.
          </p>
          <h3>CHANGES TO OUR SITES</h3>
          <p>
            We may update our Websites from time to time, and may change the
            content at any time. However, please note that any of the content on
            our Websites may be out of date at any given time, and we are under
            no obligation to update it.
          </p>
          <h3>THIRD PARTY RIGHTS</h3>
          <p>
            Only you and we shall be entitled to enforce these terms of use. No
            third party shall be entitled to enforce any of these terms of use,
            whether by virtue of the Contracts (Rights of Third Parties) Act
            1999 or otherwise.
          </p>
          <h3>LAW, JURISDICTION & LANGUAGE</h3>
          <p>
            These terms of use and any matter that arises out of your use of our
            Websites shall be governed by English law. You and we both agree
            that the courts of England and Wales shall have exclusive
            jurisdiction. All contracts shall be construed in English.
          </p>
          <p>
            You are responsible for compliance with applicable local laws
            relating to your use of our Websites. To the extent that our
            Websites or any activity contemplated by them would infringe any law
            of a jurisdiction other than England, you are prohibited from
            accessing our Websites or attempting to carry on any such offending
            activity and this provision shall override all other provisions of
            these terms of use.
          </p>
          <h3>CONTACT US</h3>
          <p>
            You can contact us using our{' '}
            <a href="/contact-us" className="text-primary">
              <strong>Contact Form</strong>
            </a>
            . Thank you for visiting our Websites.
          </p>
        </div>
      </section>
    </Master>
  );
}

export default TermsConditions;
