/* eslint-disable */
const initialForm = {
  email: '',
  email_confirmation: '',
};

export default function validateEditEmail(inputs = initialForm) {
  if (inputs.email.trim().length === 0) {
    return 'Email field is required';
  }
  if (!inputs.email.match(/^\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z.]{2,6}$/) || !inputs.email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
    return 'Email is not valid';
  }
  if(inputs.email.trim().toLowerCase() !== inputs.email_confirmation.trim().toLowerCase()) {
    return 'Emails must match';
  }
  return 'validated';
}
