import {call, put, takeLatest, select, all} from 'redux-saga/effects';

import {push} from 'connected-react-router';
import apiUrl from '../../Configs/ApiUrl';
import apiCall from '../../Services/ApiCall';
import {
  REGISTER,
  LOGIN,
  LOGOUT,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from './constants';
import {
  clearUser,
  forgotPasswordError,
  forgotPasswordSuccess,
  loginError,
  loginSuccess,
  logoutError,
  logoutSuccess,
  registerError,
  registerSuccess,
  resetPasswordError,
  resetPasswordSuccess,
} from './actions';
import PostHeaders from '../../Configs/PostHeaders';
import AuthorizedGetHeaders from '../../Configs/AuthorizedGetHeaders';
import {makeSelectToken} from './selectors';
import {makeSelectShop} from '../Shop/selectors';

export function* logout() {
  const store = yield select(makeSelectShop());
  const token = yield select(makeSelectToken());
  const requestUrl = `${apiUrl}/auth/logout`;

  try {
    yield call(apiCall, requestUrl, AuthorizedGetHeaders(token));
    yield put(logoutSuccess());
  } catch (e) {
    if (e.message === 'Unauthorized') {
      yield put(clearUser());
      yield put(push(`/shop/${store.slug}/authenticate`));
    } else {
      // console.log('login saga error occurred', e);
      yield put(logoutError(e.message));
    }
  }
}

export function* login(action) {
  const requestUrl = `${apiUrl}/auth/login`;
  try {
    const {token, user} = yield call(
      apiCall,
      requestUrl,
      PostHeaders(action.details),
    );
    if (user.user_addresses) {
      yield put(loginSuccess(user, token));
    } else {
      yield put(loginError('Invalid user address'));
    }
  } catch (e) {
    if (e.message === 'Unauthorized') {
      yield put(clearUser());
      yield put(push('/'));
    } else {
      // console.log('login saga error occurred', e);
      yield put(loginError(e.message));
    }
  }
}

export function* register(action) {
  const requestUrl = `${apiUrl}/auth/register`;
  try {
    const response = yield call(
      apiCall,
      requestUrl,
      PostHeaders({
        name: `${action.details.first_name} ${action.details.last_name}`,
        ...action.details,
      }),
    );
    yield put(registerSuccess(response));
  } catch (e) {
    if (e.message === 'Unauthorized') {
      yield put(clearUser());
      yield put(push('/'));
    } else {
      // console.log('register saga error occurred', e);
      yield put(registerError(e.message));
    }
  }
}

export function* forgotPassword({email}) {
  const requestUrl = `${apiUrl}/auth/resetpasswordrequest`;
  try {
    yield call(apiCall, requestUrl, PostHeaders({email}));
    yield put(forgotPasswordSuccess());
  } catch (e) {
    if (e.message === 'Unauthorized') {
      yield put(clearUser());
      yield put(push('/'));
    } else {
      yield put(forgotPasswordError(e.message));
    }
  }
}

export function* resetPassword({token, password}) {
  const requestUrl = `${apiUrl}/auth/resetpasswordresponse`;
  try {
    const response = yield call(
      apiCall,
      requestUrl,
      PostHeaders({token, password}),
    );
    console.log('response', response);
    yield put(resetPasswordSuccess());
  } catch (e) {
    if (e.message === 'Unauthorized') {
      yield put(push('/'));
      yield put(clearUser());
    } else {
      yield put(resetPasswordError(e.message));
    }
  }
}

function* loginSaga() {
  yield takeLatest(LOGIN, login);
}

function* registerSaga() {
  yield takeLatest(REGISTER, register);
}

function* logoutSaga() {
  yield takeLatest(LOGOUT, logout);
}

function* forgotPasswordSaga() {
  yield takeLatest(FORGOT_PASSWORD, forgotPassword);
}

function* resetPasswordSaga() {
  yield takeLatest(RESET_PASSWORD, resetPassword);
}

export default function* authSaga() {
  yield all([
    loginSaga(),
    registerSaga(),
    logoutSaga(),
    forgotPasswordSaga(),
    resetPasswordSaga(),
  ]);
}
