import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';
import Master from '../Master';
import ShopNavbar from '../../Components/ShopNavbar';
import CartItem from '../Checkout/CartItem';
import Price from '../../Components/Price';
import {
  makeSelectCheckoutMode,
  makeSelectProducts,
  makeSelectVoucher,
} from '../Cart/selectors';
import {makeSelectBookingId, makeSelectDetails} from '../Checkout/selectors';
import {
  cartTotal,
  getDeliveryCharges,
  getVoucherPrice,
} from '../../Libs/prices';
import {makeSelectShop} from '../Shop/selectors';
import {makeSelectResponse} from './selectors';
import Status from './Status';

function Response() {
  const products = useSelector(makeSelectProducts());
  const details = useSelector(makeSelectDetails());
  const voucher = useSelector(makeSelectVoucher());
  const mode = useSelector(makeSelectCheckoutMode());
  const bookingId = useSelector(makeSelectBookingId());
  const branch = useSelector(makeSelectShop());
  const response = useSelector(makeSelectResponse());
  const subTotal = cartTotal(products, mode);
  const discount = getVoucherPrice(voucher, subTotal);
  const deliveryCharges = getDeliveryCharges(
    branch.delivery_operation,
    mode,
    subTotal,
  );
  if (!bookingId) {
    return <Redirect to="./" />;
  }
  return (
    <Master navbar={ShopNavbar}>
      <section id="checkout-response">
        <div className="column">
          <div className="row booking-details">
            <div className="column customer-summary">
              <div className="header">
                <h3>ADDRESS DETAILS</h3>
              </div>
              <div className="body">
                <p>
                  <strong>
                    {details.first_name} {details.last_name}
                  </strong>
                </p>
                <p>{details.address}</p>
                <p>{details.city}</p>
                <p>{details.county}</p>
                <p>{details.post_code}</p>
              </div>
              <br />
              <div className="footer">
                <p>
                  <strong>Email:</strong>
                  {details.email}
                </p>
                <p>
                  <strong>Telephone</strong>
                  {details.telephone}
                </p>
              </div>
            </div>
            <div className="column order-summary">
              <div className="header">
                <h3>YOUR ORDER</h3>
              </div>
              {products.map((product, index) => (
                <CartItem key={product.title + index} product={product} />
              ))}
              <div className="notes">
                <h5>Order Instructions / Notes</h5>
                <p>{details.notes}</p>
              </div>
              <div className="summary">
                <div className="row">
                  <p className="label">Subtotal</p>
                  <p className="value">
                    <Price>{subTotal}</Price>
                  </p>
                </div>
                <div className="row">
                  <p className="label">Voucher</p>
                  <p className="value">
                    -<Price>{discount}</Price>
                  </p>
                </div>
                <div className="row">
                  <p className="label">
                    Delivery {mode === 'Takeaway' && '(CLICK AND COLLECT)'}
                  </p>
                  <p className="value">
                    {mode === 'Takeaway' ? (
                      'Free'
                    ) : (
                      <Price>{deliveryCharges}</Price>
                    )}
                  </p>
                </div>
                <div className="row">
                  <p className="label">Total</p>
                  <p className="total">
                    <Price>{subTotal + deliveryCharges - discount}</Price>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="column response">
          <div className="response-value">
            <Status response={response} />
          </div>
        </div>
      </section>
    </Master>
  );
}

export default Response;
