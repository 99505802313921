import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {
  makeSelectCheckoutMode,
  makeSelectProducts,
  makeSelectVoucher,
} from '../Cart/selectors';
import {makeSelectShop} from '../Shop/selectors';
import {
  cartTotal,
  getDeliveryCharges,
  getVoucherPrice,
} from '../../Libs/prices';
import {checkout} from '../Checkout/actions';

function PayOnCollection({methods}) {
  const dispatch = useDispatch();
  const {push} = useHistory();
  const {slug} = useParams();
  const products = useSelector(makeSelectProducts());
  const voucher = useSelector(makeSelectVoucher());
  const mode = useSelector(makeSelectCheckoutMode());
  const branch = useSelector(makeSelectShop());
  const subTotal = cartTotal(products, mode);
  const discount = getVoucherPrice(voucher, subTotal);
  const deliveryCharges = getDeliveryCharges(
    branch.delivery_operation,
    mode,
    subTotal,
  );
  const total = subTotal + deliveryCharges - discount;
  const index = methods.findIndex((method) => method.title === 'takeaway');

  const handleCheckout = () => {
    dispatch(
      checkout(
        {
          payment_mode: 'pay on collection',
          payment_amount: total,
        },
        {},
        '',
      ),
    );
    push(`/shop/${slug}/checkout/response`);
  };
  if (index === -1 || mode !== 'Takeaway') {
    return null;
  }
  return (
    <button
      type="button"
      className="method on-collection"
      onClick={handleCheckout}
    >
      Pay on Collection
    </button>
  );
}

export default PayOnCollection;
