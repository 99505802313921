import React from 'react';

const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

function Operation({takeaway_schedule, delivery_schedule}) {
  return (
    <div className="operations">
      <div className="head">
        <div className="o-day"></div>
        <div className="o-instore">IN-STORE</div>
        <div className="o-delivery">DELIVERY</div>
      </div>
      <div className="content">
        {days.map((d) => {
          const takeaway = takeaway_schedule.takeawayDays[d];
          const delivery = delivery_schedule.deliveryDays[d];
          return (
            <div className="o-row" key={d}>
              <div className="o-day">{d}</div>
              <div className="o-instore">
                {takeaway.length > 0 ? takeaway.map((t) => <span key={t}>{t}</span>) : 'closed'}
              </div>
              <div className="o-delivery">
                {delivery.length > 0 ? delivery.map((t) => <span key={t}>{t}</span>) : 'closed'}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Operation;
