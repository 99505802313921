export const SAVE_FORM_DATA = 'Favorite/Checkout/SAVE_FORM_DATA';

export const SAVE_PAYMENT = 'Favorite/Checkout/SAVE_PAYMENT';

export const SYNC_CART = 'Favorite/Checkout/SYNC_CART';

export const SYNC_CART_SUCCESS = 'Favorite/Checkout/SYNC_CART_SUCCESS';

export const SYNC_CART_ERROR = 'Favorite/Checkout/SYNC_CART_ERROR';

export const CHECKOUT = 'Favorite/Checkout/CHECKOUT';

export const CHECKOUT_SUCCESS = 'Favorite/Checkout/CHECKOUT_SUCCESS';

export const CHECKOUT_ERROR = 'Favorite/Checkout/CHECKOUT_ERROR';

export const CLEAR_CHECKOUT = 'Favorite/Checkout/CLEAR_CHECKOUT';
