import {RESPONSE, PAY, PAYMENT_ERROR, PAYMENT_SUCCESS} from './constants';

export const response = (status) => ({
  type: RESPONSE,
  status,
});

export const pay = (payment, mode) => ({
  type: PAY,
  payment,
  mode,
});

export const paymentSuccess = () => ({
  type: PAYMENT_SUCCESS,
});

export const paymentError = (status) => ({
  type: PAYMENT_ERROR,
  status,
});
