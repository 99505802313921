import React from 'react';
import {useHistory} from 'react-router-dom';
import Master from '../Master';
import Navbar from '../../Components/Navbar';
import Header from '../../Components/Header';

function OrderFavorite() {
  const {push} = useHistory();
  return (
    <Master navbar={Navbar}>
      <Header title="Order Favorite" />
      <section id="order-favorite">
        <div className="hero">
          <h1>Loved By People & Always Ready!</h1>
          <p>
            Order direct from your local izmir store for Delivery or Click &
            Collect.
          </p>
          <p>
            Izmir Bar and Grill has the answer with great tasting, great value
            food, with something to suit everyone!
          </p>
          <div className="row">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                push(`/find-store/${e.target[0].value}`);
              }}
            >
              <div className="label">FIND YOUR NEAREST STORE</div>
              <div className="form-group">
                <input
                  type="text"
                  required="required"
                  name="postcode"
                  placeholder="YOUR POSTCODE"
                />
                <button type="submit">FIND STORE</button>
              </div>
            </form>
          </div>
          <p className="note">
            *Izmir Online Ordering as well as delivery & click and collect only
            available at selected locations.
          </p>
        </div>
        {/* <div className="row" id="apps"> */}
        {/*  <div className="column"> */}
        {/*    <h2>DOWNLOAD THE APP</h2> */}
        {/*    <p> */}
        {/*      With the official Favorite app, find your nearest Favorite Chicken */}
        {/*      & Ribs store and order online* for collection or delivery. */}
        {/*    </p> */}
        {/*    <p> */}
        {/*      &ldquo;Britain&lsquo;s Tastiest Chicken!&rdquo; in just a few */}
        {/*      clicks! */}
        {/*    </p> */}
        {/*    <span>*At participating stores.</span> */}
        {/*  </div> */}
        {/*  <div className="column"> */}
        {/*    <div className="download"> */}
        {/*      <a */}
        {/*        target="_blank" */}
        {/*        href="https://play.google.com/store/apps/details?id=uk.co.favorite.storefinder" */}
        {/*        className="img" */}
        {/*      > */}
        {/*        <img */}
        {/*          src={require('../../Assets/images/download-android.png')} */}
        {/*          alt="download-android" */}
        {/*        /> */}
        {/*      </a> */}
        {/*      <a */}
        {/*        target="_blank" */}
        {/*        href="https://itunes.apple.com/WebObjects/MZStore.woa/wa/search?term=favorite%20fried%20chicken%20limited&mt=8" */}
        {/*        className="img" */}
        {/*      > */}
        {/*        <img */}
        {/*          src={require('../../Assets/images/download-apple.png')} */}
        {/*          alt="download-apple" */}
        {/*        /> */}
        {/*      </a> */}
        {/*    </div> */}
        {/*  </div> */}
        {/* </div> */}
      </section>
    </Master>
  );
}

export default OrderFavorite;
