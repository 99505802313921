import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import Product from './Product';
import {makeSelectShop} from '../Shop/selectors';
import {addToCart} from '../Cart/actions';
import {makeSelectCheckoutMode} from '../Cart/selectors';
import {fetchShop} from '../Shop/actions';

const mapDispatchToProps = (dispatch) => ({
  onAddToCart: (product) => dispatch(addToCart(product)),
  onFetchShop: (slug) => dispatch(fetchShop(slug)),
});

const mapStateToProps = createStructuredSelector({
  shop: makeSelectShop(),
  mode: makeSelectCheckoutMode(),
});

export default connect(mapStateToProps, mapDispatchToProps)(Product);
