/* eslint-disable */
const initialForm = {
  first_name: '',
  last_name: '',
  telephone: '',
  address: '',
  city: '',
  county: '',
  postcode: '',
  email: '',
  notes: '',
};

export default function validateCheckoutForm(inputs = initialForm) {
  if (inputs.first_name.trim().length === 0) {
    return 'First Name field is required';
  }
  if (!inputs.first_name.match(/^[a-zA-Z ,.'-]+$/)) {
    return 'First Name contain invalid characters';
  }
  if (inputs.last_name.trim().length === 0) {
    return 'Last Name field is required';
  }
  if (!inputs.last_name.match(/^[a-zA-Z ,.'-]+$/)) {
    return 'Last Name should be only Characters';
  }
  if (inputs.telephone.trim().length === 0) {
    return 'Mobile number is required.';
  }
  if(inputs.telephone.startsWith('0')) {
    if(!(inputs.telephone.trim().length === 11)) {
      return 'Mobile number must be 11 digits if starting from 0.';
    }
  } else {
    if(!(inputs.telephone.trim().length === 10)) {
      return 'Mobile number must be 10 digits.';
    }
  }
  if (!inputs.telephone.match(/^(\(?[0-9]{1}\d{1,4}?\)?\s?\d{3,4}\s?\d{3,4})$/)) {
    return 'Invalid mobile number.';
  }
  if (inputs.address.trim().length === 0) {
    return 'Address field is required';
  }
  if (inputs.city.trim().length === 0) {
    return 'City field is required';
  }
  if (!inputs.city.match(/^[a-zA-Z ,.'-]+$/)) {
    return 'City should be only Characters';
  }
  if (inputs.county.trim().length === 0) {
    return 'County field is required';
  }
  if (!inputs.county.match(/^[a-zA-Z ,.'-]+$/)) {
    return 'County should be only Characters';
  }
  if (inputs.postcode.trim().length === 0) {
    return 'Post Code field is required';
  }
  if (inputs.email.trim().length === 0) {
    return 'Email field is required';
  }
  if (!inputs.email.match(/^\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z.]{2,6}$/) || !inputs.email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
    return 'Email is not valid';
  }
  return 'validated';
}
