import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {useHistory} from 'react-router-dom';
import Master from '../Master';
import Navbar from '../../Components/Navbar';
import Header from '../../Components/Header';
import InputField from '../../Components/InputField';

function Jobs() {
  const {push} = useHistory();
  return (
    <Master navbar={Navbar}>
      <Header title="Contact Us" />
      <section id="jobs">
        <div className="hero">
          <h1>FIND YOUR FAVORITE</h1>
          <p>
            Find your Favorite and register your interest in joining the team.
          </p>
          <div className="row">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                push(`/find-store/${e.target[0].value}`);
              }}
            >
              <div className="label">FIND YOUR NEAREST STORE</div>
              <div className="form-group">
                <input
                  type="text"
                  required="required"
                  name="postcode"
                  placeholder="YOUR POSTCODE"
                />
                <button type="submit">FIND STORE</button>
              </div>
            </form>
          </div>
        </div>
        <div className="info">
          <h1>WANT TO WORK AT A FAVORITE?</h1>
          <div className="row">
            <div className="column f-2">
              <p>
                We&slqou;re helping our franchisees recruit people to work in
                their stores.
              </p>
              <p>
                If you are interested in working in a Favorite Franchise please
                fill out the contact form. In the message section state your
                local store and if you are looking for work as a customer
                server, cook or delivery driver.
              </p>
              <p>
                We&slqou;ll then pass your information on to the relevant store
                owner and they will contact you as and when there are any
                vacancies.
              </p>
              <p>
                We do not currently hold a list of which stores have vacancies
                and by completing the form you are only registering an interest
                to be contacted if and when there are available opportunities.
              </p>
              <p>
                Your data will be covered by our privacy policy available in the
                link in navigation below.
              </p>
            </div>
            <form className="column f-3">
              <InputField name="name" required />
              <InputField name="email" required />
              <div className="form-group">
                <p className="label">Type of Enquiry</p>
                <div className="radio">
                  <input
                    type="radio"
                    id="general"
                    name="type"
                    value="general"
                  />
                  <label htmlFor="general">General Feedback</label>
                  <input
                    type="radio"
                    id="customer"
                    name="type"
                    value="customer"
                  />
                  <label htmlFor="customer">Customer Feedback</label>
                </div>
              </div>
              <div className="form-group">
                <div className="label">Message</div>
                <textarea rows={4} />
              </div>
              <div className="form-group recaptcha">
                <div className="label" />
                <ReCAPTCHA
                  sitekey="6Lf129sZAAAAAF7OZOXgq8ZRLXKwhPXXNl03j3Oz"
                  onChange={() => false}
                />
              </div>
              <div className="footer">
                <p>
                  <span className="text-primary">*</span>
                  <span>Denotes mandatory fields</span>
                </p>
                <button type="submit">SEND</button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </Master>
  );
}

export default Jobs;
