import React from 'react';

function Paypal({methods}) {
  const index = methods.findIndex((method) => method.title === 'paypal');
  if (index === -1) {
    return null;
  }
  return (
    <button type="button" className="method paypal">
      Paypal
    </button>
  );
}

export default Paypal;
