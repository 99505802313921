import React from 'react';
import {useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {emptyCart} from '../Cart/actions';

function Discard(props) {
  const dispatch = useDispatch();
  const {push} = useHistory();
  const {slug} = useParams();
  const handleDiscard = (e) => {
    e.preventDefault();
    dispatch(emptyCart());
    push(`/shop/${slug}/menu`);
  };
  return (
    <button type="button" className="method discard" onClick={handleDiscard}>
      Discard
    </button>
  );
}

export default Discard;
