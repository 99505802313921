import React, {useEffect} from 'react';
import 'firebase/database';
import {useDispatch, useSelector} from 'react-redux';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {useStripe} from '@stripe/react-stripe-js';
import firebase from '../Configs/Firebase';
import firebaseRef from '../Configs/FirebaseReference';
import {
  makeSelectBookingId,
  makeSelectPaymentMethod,
  makeSelectSecret,
} from '../Pages/Checkout/selectors';
import {pay, response} from '../Pages/Response/actions';
import {makeSelectState} from '../Pages/Response/selectors';

function LiveComponent() {
  const bookingId = useSelector(makeSelectBookingId());
  const state = useSelector(makeSelectState());
  const secret = useSelector(makeSelectSecret());
  const {paymentMethod} = useSelector(makeSelectPaymentMethod());
  const dispatch = useDispatch();
  const stripe = useStripe();

  function handleOnlinePayment({booking, payment}) {
    if (booking?.id === bookingId) {
      if (
        paymentMethod?.id &&
        stripe &&
        payment.type.toLowerCase() === 'with card'
      ) {
        if (booking?.status === 'approved') {
          stripe
            .confirmCardPayment(secret, {
              payment_method: paymentMethod.id,
            })
            .then(function ({paymentIntent}) {
              if (
                paymentIntent &&
                payment.transaction_status === 'incomplete'
              ) {
                dispatch(response(booking?.status));
                dispatch(
                  pay({
                    booking_id: bookingId,
                    payment_mode: payment.type,
                    payment_intent_id: paymentIntent.id,
                  }),
                );
              }
            });
        } else {
          dispatch(response(booking?.status));
        }
      } else {
        dispatch(response(booking?.status));
      }
    }
  }

  function unsubscribeFirebase() {
    firebase.database().ref(firebaseRef).off();
  }

  useEffect(() => {
    unsubscribeFirebase();
    firebase
      .database()
      .ref(firebaseRef)
      .on('child_changed', (snapshot) => {
        if (!snapshot) {
          return;
        }
        const booking = snapshot.val();
        handleOnlinePayment(booking);
      });
  }, [bookingId, stripe, paymentMethod]);

  useEffect(() => {
    if (state === 'success') {
      toast.success('Booking Completed Successfully.', {
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  }, [state]);
  return <ToastContainer />;
}

export default LiveComponent;
