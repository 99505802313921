export const EDIT_PROFILE = 'Favorite/Profile/EDIT_PROFILE';

export const EDIT_PROFILE_SUCCESS = 'Favorite/Profile/EDIT_PROFILE_SUCCESS';

export const EDIT_PROFILE_ERROR = 'Favorite/Profile/EDIT_PROFILE_ERROR';

export const EDIT_PASSWORD = 'Favorite/Profile/EDIT_PASSWORD';

export const EDIT_PASSWORD_SUCCESS = 'Favorite/Profile/EDIT_PASSWORD_SUCCESS';

export const EDIT_PASSWORD_ERROR = 'Favorite/Profile/EDIT_PASSWORD_ERROR';

export const EDIT_EMAIL = 'Favorite/Profile/EDIT_EMAIL';

export const EDIT_EMAIL_SUCCESS = 'Favorite/Profile/EDIT_EMAIL_SUCCESS';

export const EDIT_EMAIL_ERROR = 'Favorite/Profile/EDIT_EMAIL_ERROR';

export const ADD_ADDRESS = 'Favorite/Profile/ADD_ADDRESS';

export const ADD_ADDRESS_SUCCESS = 'Favorite/Profile/ADD_ADDRESS_SUCCESS';

export const ADD_ADDRESS_ERROR = 'Favorite/Profile/ADD_ADDRESS_ERROR';

export const EDIT_ADDRESS = 'Favorite/Profile/EDIT_ADDRESS';

export const EDIT_ADDRESS_SUCCESS = 'Favorite/Profile/EDIT_ADDRESS_SUCCESS';

export const EDIT_ADDRESS_ERROR = 'Favorite/Profile/EDIT_ADDRESS_ERROR';

export const REMOVE_ADDRESS = 'Favorite/Profile/REMOVE_ADDRESS';

export const REMOVE_ADDRESS_SUCCESS = 'Favorite/Profile/REMOVE_ADDRESS_SUCCESS';

export const REMOVE_ADDRESS_ERROR = 'Favorite/Profile/REMOVE_ADDRESS_ERROR';

export const MAKE_DEFAULT = 'Favorite/Profile/MAKE_DEFAULT';

export const MAKE_DEFAULT_SUCCESS = 'Favorite/Profile/MAKE_DEFAULT_SUCCESS';

export const MAKE_DEFAULT_ERROR = 'Favorite/Profile/MAKE_DEFAULT_ERROR';
