import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import Item from './Item';
import {makeSelectMenu} from './selectors';
import {searchMenu} from '../../Libs/menu';
import ItemsNotFound from '../../Components/ItemsNotFound';

function MenuItems() {
  const menu = useSelector(makeSelectMenu());
  const {q} = useParams();

  const filteredMenu = useMemo(() => searchMenu(menu, q), [menu, q]);
  return (
    <ItemsNotFound
      condition={filteredMenu.length === 0}
      title="Items not found."
      message="Please try another postcode."
    >
      {filteredMenu.map((submenu) => (
        <div id={submenu.slug} className="menu-items" key={submenu.title}>
          <div className="header">
            <h2>{submenu.title}</h2>
          </div>
          <div className="body">
            {submenu.products?.map((item) => (
              <Item item={item} key={item.title} />
            ))}
          </div>
        </div>
      ))}
    </ItemsNotFound>
  );
}

export default MenuItems;
