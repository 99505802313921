import React from 'react';
import {useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {makeSelectMenu} from '../Pages/Shop/selectors';

function MenuSummary() {
  const {hash} = useLocation();
  const menu = useSelector(makeSelectMenu());
  return (
    <div className="menu">
      <ul>
        {menu.map((submenu) => (
          <a href={`#${submenu.slug}`} key={submenu.title}>
            <li className={`#${submenu.slug}` === hash ? 'active' : ''}>
              <h2>{submenu.title}</h2>
            </li>
          </a>
        ))}
      </ul>
    </div>
  );
}

export default MenuSummary;
