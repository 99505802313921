import {fromJS} from 'immutable';

import {
  LOGIN_SUCCESS,
  REGISTER_ERROR,
  REGISTER_SUCCESS,
  LOGIN_ERROR,
  REGISTER,
  LOGIN,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CLEAR_USER,
} from './constants';
import {
  ADD_ADDRESS_SUCCESS,
  EDIT_ADDRESS_SUCCESS,
  EDIT_EMAIL_SUCCESS,
  EDIT_PASSWORD_SUCCESS,
  EDIT_PROFILE_SUCCESS,
  MAKE_DEFAULT_SUCCESS,
  REMOVE_ADDRESS_SUCCESS,
} from '../Profile/constants';

const initialAuthState = fromJS({
  state: 'initial',
  mode: 'initial',
  status: '',
  verificationCode: '',
});

export function authReducer(state = initialAuthState, action) {
  switch (action.type) {
    case LOGIN:
      return state
        .set('mode', 'login')
        .set('state', 'loading')
        .set('status', '');
    case LOGIN_SUCCESS:
      return state.set('state', 'success');
    case LOGIN_ERROR:
      return state.set('state', 'error').set('status', action.status);
    case REGISTER:
      return state
        .set('mode', 'register')
        .set('state', 'loading')
        .set('status', '');
    case REGISTER_SUCCESS:
      return state.set('state', 'success');
    case REGISTER_ERROR:
      return state.set('state', 'error').set('status', action.status);
    case LOGOUT:
      return state
        .set('state', 'loading')
        .set('mode', 'logout')
        .set('status', '');
    case LOGOUT_SUCCESS:
      return state.set('state', 'success');
    case LOGOUT_ERROR:
      return state.set('state', 'error').set('status', action.status);
    case FORGOT_PASSWORD:
      return state
        .set('mode', 'forget-password')
        .set('status', 'loading')
        .set('status', '');
    case FORGOT_PASSWORD_SUCCESS:
      return state.set('state', 'success').set('verificationCode', action.code);
    case FORGOT_PASSWORD_ERROR:
      return state.set('state', 'error').set('status', action.status);
    case RESET_PASSWORD:
      return state
        .set('mode', 'reset-password')
        .set('status', 'loading')
        .set('status', '');
    case RESET_PASSWORD_SUCCESS:
      return state.set('state', 'success');
    case RESET_PASSWORD_ERROR:
      return state.set('state', 'error').set('status', action.status);
    default:
      return state;
  }
}

const initialUserState = fromJS({
  token: '',
  user: {},
});
export function userReducer(state = initialUserState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return state.set('user', fromJS(action.user)).set('token', action.token);
    case LOGOUT_SUCCESS:
      return state.set('token', '').set('user', fromJS({}));
    case CLEAR_USER:
      return state.set('token', '').set('user', fromJS({}));
    case EDIT_EMAIL_SUCCESS:
      return state.set('token', action.token).set('user', fromJS(action.user));
    case EDIT_PROFILE_SUCCESS:
      return state.set('user', fromJS(action.user));
    case EDIT_PASSWORD_SUCCESS:
      return state.set('user', fromJS(action.user)).set('token', action.token);
    case ADD_ADDRESS_SUCCESS:
      return state.set('user', fromJS(action.user));
    case REMOVE_ADDRESS_SUCCESS:
      return state.set('user', fromJS(action.user));
    case EDIT_ADDRESS_SUCCESS:
      return state.set('user', fromJS(action.user));
    case MAKE_DEFAULT_SUCCESS:
      return state.set('user', fromJS(action.user));
    default:
      return state;
  }
}
