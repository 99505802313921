import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import {makeSelectUser} from '../Authentication/selectors';
import UserAddress from './UserAddress';

function AuthenticatedStep2({handleSaveFormData}) {
  const {slug} = useParams();
  // eslint-disable-next-line camelcase
  const {user_addresses, email} = useSelector(makeSelectUser());
  const [selectedAddress, setSelectedAddress] = useState({});
  const [status, setStatus] = useState('');
  const [notes, setNotes] = useState('');

  const handleContunue = () => {
    handleSaveFormData({
      ...selectedAddress,
      user_address_id: selectedAddress.id,
      email,
      notes,
    });
  };
  return (
    <>
      <div className="user-addresses">
        <h2>SELECT AN ADDRESS</h2>
        <div className="address-suggestions">
          {user_addresses.map((address) => (
            <UserAddress
              key={address.address}
              address={address}
              selectedAddress={selectedAddress}
              setSelectedAddress={setSelectedAddress}
              setStatus={setStatus}
            />
          ))}
          <div className="add-address">
            <Link to={`/shop/${slug}/profile/address/new`}>
              ADD NEW ADDRESS
            </Link>
          </div>
        </div>
      </div>
      <div className="instructions">
        <strong>Order Instructions</strong>
        <textarea
          name="notes"
          cols="30"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          rows="7"
        />
      </div>
      {status.length ? (
        <div className="row">
          <p className="alert alert-danger w-100">{status}</p>
        </div>
      ) : null}
      <div className="row">
        <button
          className={`continue ${!selectedAddress.address ? 'disabled' : ''}`}
          disabled={!selectedAddress.address}
          type="button"
          onClick={handleContunue}
        >
          CONTINUE
        </button>
      </div>
    </>
  );
}

export default AuthenticatedStep2;
