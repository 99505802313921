import React from 'react';
import {checkIfObjectEmpty} from '../../Libs/object';

function Modal({allergen, setAllergen}) {
  const handleClose = (e) => {
    e.preventDefault();
    if (e.target.classList.contains('modal')) {
      setAllergen({});
    }
  };
  if (checkIfObjectEmpty(allergen)) {
    return null;
  }
  return (
    <div className="modal" onClick={handleClose}>
      <div className="content">
        <div className="header">
          <h2>{allergen.title}</h2>
          <span className="remove" onClick={() => setAllergen({})}>
            x
          </span>
        </div>
        <div className="body">
          <div className="img">
            <img src={allergen.image} alt="" />
          </div>
          <div className="details">
            <p>The following food contains {`  "${allergen.title}"`}</p>
            {allergen.product.map((product) => (
              <div key={product.id} className="product">
                <p>{product.title}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
