import React from 'react';

const MarketingPreferences = () => {
  return (
    <div className="marketing">
      <div className="header">
        <h3>MARKETING PREFERENCES</h3>
      </div>
      <p>Hey, let’s keep in touch!</p>
      <div className="form-group">
        <input type="checkbox" id="email" name="email" value="Email" />
        <label htmlFor="email">
          Update me with the latest news and offers by Email
        </label>
      </div>
      <div className="form-group">
        <input type="checkbox" id="sms" name="sms" value="SMS" />
        <label htmlFor="sms">
          Update me with the latest news and offers by SMS
        </label>
      </div>
      <br />
    </div>
  );
};

export default MarketingPreferences;
