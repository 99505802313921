import React, {useEffect, useState} from 'react';
import Master from '../Master';
import Navbar from '../../Components/Navbar';
import Header from '../../Components/Header';
// import allergens from '../../Services/allergens.json';
import apiCall from '../../Services/ApiCall';
import apiUrl from '../../Configs/ApiUrl';
import GetHeaders from '../../Configs/GetHeaders';
import Modal from './Modal';
import FullLoader from '../../Components/FullLoader';
import AllergensInfo from './AllergensInfo';

function Allergens() {
  const [allergens, setAllergens] = useState([]);
  const [allergen, setAllergen] = useState({});
  const [state, setState] = useState('loading');
  useEffect(() => {
    setState('loading');
    apiCall(`${apiUrl}/allergens/allergenwithProduct`, GetHeaders)
      .then((response) => {
        setAllergens(response);
        setState('success');
      })
      .catch(() => {
        setState('error');
      });
  }, []);
  if (state === 'loading') {
    return (
      <Master navbar={Navbar}>
        <Header title="Our Food" />
        <section id="allergens">
          <div className="row">
            <h1>ALLERGENS</h1>
          </div>
          <Modal allergen={allergen} setAllergen={setAllergen} />
          <div className="row">
            <AllergensInfo />
            <div className="column f-2">
              <FullLoader />
            </div>
          </div>
        </section>
      </Master>
    );
  }
  return (
    <Master navbar={Navbar}>
      <Header title="Our Food" />
      <section id="allergens">
        <div className="row">
          <h1>ALLERGENS</h1>
        </div>
        <Modal allergen={allergen} setAllergen={setAllergen} />
        <div className="row">
          <AllergensInfo />
          <div className="column f-2">
            <div className="items">
              {allergens.map((item) => (
                <div
                  className="item"
                  key={item.title}
                  onClick={() => setAllergen(item)}
                >
                  <div className="thumb">
                    <img src={item.image} alt={item.title} />
                  </div>
                  <div className="title">{item.title}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Master>
  );
}

export default Allergens;
