import React from 'react';
import {ConnectedRouter} from 'connected-react-router/immutable';
import {Route, Switch, Router} from 'react-router-dom';

import history from '../Libs/history';
import Home from './Home';
import FindStore from './FindStore';
import Shop from './Shop';
import Authentication from './Authentication';
import Details from './Details/Details';
import Cart from './Cart';
import Checkout from './Checkout';
import Product from './Product';
import FindItem from './FindItem';
import ForgetPassword from './ForgetPassword';
import OurFood from './OurFood';
import Allergens from './Allergens';
import About from './About';
import Contact from './Contact';
import OrderFavorite from './OrderFavorite';
import Cookies from './Cookies';
import PrivacyPolicy from './PrivacyPolicy';
import TermsConditions from './TermsConditions';
import TermsOfSale from './TermsOfSale';
import SiteMap from './SiteMap';
import Profile from './Profile';
import Response from './Response';
import LiveComponent from '../Components/LiveComponent';
import Jobs from './Jobs';
import Payments from './Payments';
import NotFound404 from '../Components/404';
import BookTable from './BookTable';
import FoodMenu from './OurMenu/FoodMenu';
import DrinkMenu from './OurMenu/DrinkMenu';
import LunchMenu from './OurMenu/LunchMenu';

const Root = () => {
  return (
    <ConnectedRouter history={history}>
      <Router history={history}>
        <LiveComponent />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/cookies" component={Cookies} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/terms-conditions" component={TermsConditions} />
          <Route path="/terms-of-sale" component={TermsOfSale} />
          <Route exact path="/sitemap" component={SiteMap} />
          <Route exact path="/jobs" component={Jobs} />
          <Route exact path="/our-food" component={OurFood} />
          <Route exact path="/our-food/allergens" component={Allergens} />
          <Route path="/our-food/order-now" component={OrderFavorite} />
          <Route exact path="/contact-us" component={Contact} />
          <Route path="/find-store/:postcode?" component={FindStore} />
          <Route exact path="/shop/:slug/menu" component={Shop} />
          <Route exact path="/shop/:slug/details" component={Details} />
          <Route path="/shop/:slug/profile" component={Profile} />
          <Route path="/shop/:slug/product/:product" component={Product} />
          <Route path="/shop/:slug/search/:q?" component={FindItem} />
          <Route path="/shop/:slug/authenticate" component={Authentication} />
          <Route path="/shop/:slug/cart" component={Cart} />
          <Route exact path="/shop/:slug/checkout" component={Checkout} />
          <Route exact path="/shop/:slug/payments" component={Payments} />
          <Route path="/shop/:slug/checkout/response" component={Response} />
          <Route path="/book-table" component={BookTable} />
          <Route exact path="/food-menu" component={FoodMenu} />
          <Route exact path="/lunch-menu" component={LunchMenu} />{' '}
          <Route exact path="/drink-menu" component={DrinkMenu} />
          <Route
            component={() => <NotFound404 title="Page Not Found" message="Looks like you are lost!" condition />}
          />
        </Switch>
      </Router>
    </ConnectedRouter>
  );
};

export default Root;
