import React from 'react';
import {useSelector} from 'react-redux';
import {productPrice} from '../../Libs/prices';
import Price from '../../Components/Price';
import {makeSelectCheckoutMode} from '../Cart/selectors';

const CartItem = ({product}) => {
  const mode = useSelector(makeSelectCheckoutMode());
  return (
    <div className="item">
      <div className="header">
        <h4 className="title">
          {product.quantity}x {product.title}
        </h4>
        <div className="price">
          <Price>{productPrice(product, mode)}</Price>
        </div>
      </div>
      <div className="body">
        {product.tiers.map((tier, i) => {
          if (!tier) {
            return null;
          }
          if (tier.type === 'Addon') {
            return (
              <div className="tier" key={tier + i}>
                <strong>{tier.basket_title}</strong>
                <ul>
                  {tier.selected.free &&
                    tier.selected.free.map((addon) => (
                      <li key={addon.id}>
                        <strong>{addon.quantity}x</strong> {addon.title}{' '}
                        <strong>(free)</strong>
                      </li>
                    ))}
                  {tier.selected.paid &&
                    tier.selected.paid.map((addon) => (
                      <li key={addon.id}>
                        <strong>{addon.quantity}x</strong> {addon.title}
                      </li>
                    ))}
                </ul>
              </div>
            );
          }
          return (
            <div className="tier" key={tier.title + i}>
              <strong>{tier.basket_title}:&nbsp;</strong>
              {tier.selected.title}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CartItem;
