import React from 'react';

const PostCodeMarker = () => {
  return (
    <div className="marker-wrapper">
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <img
        width={30}
        height={30}
        src={require('../Assets/images/icon-map-marker-house.png')}
        alt="Favorite Chicken"
      />
    </div>
  );
};

export default PostCodeMarker;
