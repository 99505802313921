import React from 'react';
import {useHistory} from 'react-router-dom';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';

const Hero = () => {
  const history = useHistory();
  return (
    <section id="hero">
      <Slider autoplay={5000}>
        <div>
          <div className="hero-wrapper">
            <img className="hero-img" src={require('../Assets/images/hero1.png')} alt="Hero 1" />
          </div>
          <div className="hero-footer">
            <h1>Izmir Bar and Grill</h1>
            <p>
              Welcome to IZMIR – where relationships flourish. At IZMIR, Turkish Restaurant culture tradition comes
              together with traditional culinary methods. We focus on pristine traditional ingredients and cultural
              dishes that can be enjoyed and shared between friends and family. Our menu pays modern homage to some of
              Turkey’s most ancient flavours – showcasing beautiful Turkish influences and exotic flavours.
            </p>
            <p className="alert alert-danger">
              <strong>Disclaimer!</strong>&nbsp;Please be advised, for drinks only, the restaurant must be notified
              beforehand or risk refused service.
            </p>
          </div>
        </div>
      </Slider>

      {/* <form
        onSubmit={(e) => {
          e.preventDefault();
          history.push(`/find-store/${e.target[0].value}`);
        }}
      >
        <div className="label">FIND YOUR NEAREST STORE</div>
        <input className="postcode" type="text" placeholder="YOUR POSTCODE" />
        <button className="submit" type="submit">
          GO
        </button>
      </form> */}
    </section>
  );
};

export default Hero;
