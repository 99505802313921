/* eslint-disable-next-line camelcase */
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import InputField from '../../Components/InputField';
import validateRegister from '../../Libs/validateRegister';
import {register} from './actions';
import {makeSelectMode, makeSelectState, makeSelectStatus} from './selectors';
import Loadable from '../../Components/Loadable';
import {reformatTelephoneNumber} from '../../Libs/profile';

function Register() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [telephone, setTelephone] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [county, setCounty] = useState('');
  const [postCode, setPostCode] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validation, setValidation] = useState('validated');
  const dispatch = useDispatch();
  const state = useSelector(makeSelectState());
  const mode = useSelector(makeSelectMode());
  const status = useSelector(makeSelectStatus());
  const handleRegister = (e) => {
    e.preventDefault();
    const details = {
      first_name: firstName,
      last_name: lastName,
      telephone,
      address,
      city,
      county,
      postcode: postCode,
      email,
      password,
      confirm_password: confirmPassword,
    };
    e.preventDefault();
    const validated = validateRegister(details);
    setValidation(validated);
    if (validated === 'validated') {
      dispatch(
        register({...details, telephone: reformatTelephoneNumber(telephone)}),
      );
    }
  };
  useEffect(() => {
    if (state === 'success' && mode === 'register') {
      setFirstName('');
      setLastName('');
      setTelephone('');
      setAddress('');
      setCity('');
      setCounty('');
      setPostCode('');
      setEmail('');
      setPassword('');
      setConfirmPassword('');
    }
  }, [state, mode]);
  return (
    <section id="register">
      <form className="card" onSubmit={handleRegister} autoComplete="off">
        <div className="header">
          <h3>REGISTER</h3>
        </div>
        <div className="body">
          <p>
            Create an account to access member only benefits, move through the
            checkout process faster, view and track your orders and more.
          </p>
          {state === 'success' && mode === 'register' ? (
            <p className="alert alert-success">
              Registered Successfully. Please use login form to sign in.
            </p>
          ) : null}
          {/* eslint-disable-next-line no-nested-ternary */}
          {validation !== 'validated' ? (
            <p className="alert alert-danger">{validation}</p>
          ) : state === 'error' && mode === 'register' ? (
            <p className="alert alert-danger">{status}</p>
          ) : null}
          <InputField
            name="first_name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <InputField
            name="last_name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <InputField
            name="mobile"
            value={telephone}
            onChange={(e) => setTelephone(e.target.value)}
            required
            className="telephone-input"
            prefix="+44"
          />
          <div className="separator">
            <hr />
          </div>
          <InputField
            name="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
          <InputField
            name="town"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            required
          />
          <InputField
            name="county"
            value={county}
            onChange={(e) => setCounty(e.target.value)}
            required
          />
          <InputField
            name="post_code"
            value={postCode}
            onChange={(e) => setPostCode(e.target.value)}
            required
          />
          <InputField
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <div className="separator">
            <hr />
            <p className="note">Your email will be used as your username.</p>
          </div>
          <InputField
            type="password"
            label="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <InputField
            type="password"
            label="confirm_password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <div className="footer">
          <p>* Mandatory fields</p>
          <Loadable active={state === 'loading' && mode === 'register'}>
            <button type="submit">CREATE ACCOUNT</button>
          </Loadable>
        </div>
      </form>
    </section>
  );
}

export default Register;
