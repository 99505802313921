import {fromJS} from 'immutable';

import {RESPONSE, PAY, PAYMENT_SUCCESS, PAYMENT_ERROR} from './constants';
import {EMPTY_CART} from '../Cart/constants';
import {CLEAR_CHECKOUT} from '../Checkout/constants';

const initialState = fromJS({
  state: 'initial',
  status: '',
  response: 'initial',
  mode: '',
  payment: {},
});

export default function responseReducer(state = initialState, action) {
  switch (action.type) {
    case RESPONSE:
      return state.set('response', action.status);
    case PAY:
      return state
        .set('state', 'loading')
        .set('status', '')
        .set('mode', action.mode);
    case PAYMENT_SUCCESS:
      return state.set('state', 'success');
    case PAYMENT_ERROR:
      return state.set('state', 'error').set('status', action.status);
    case EMPTY_CART:
      return fromJS(initialState);
    case CLEAR_CHECKOUT:
      return fromJS(initialState);
    default:
      return state;
  }
}
