import React from 'react';

function TodayDeliveryStatus({shop}) {
  return (
    <div className="operation">
      <h3>Delivery</h3>
      <p>{shop.delivery === 1 ? 'Yes' : 'No'}</p>
      <span>({shop.delivery_time_taken} minutes)</span>
    </div>
  );
}

export default TodayDeliveryStatus;
