import React from 'react';
import {useSelector} from 'react-redux';
import {onlyPrice, onlyPriceWithDiscount} from '../Libs/prices';
import {makeSelectCheckoutMode} from '../Pages/Cart/selectors';
import Price from './Price';
import {checkIfObjectEmpty} from '../Libs/object';

function OriginalPrice({product}) {
  const mode = useSelector(makeSelectCheckoutMode());
  if (checkIfObjectEmpty(product)) {
    return null;
  }
  const discountedPrice = onlyPriceWithDiscount(product, mode);
  const price = onlyPrice(product);
  if (price !== discountedPrice) {
    return (
      <p className="discount-price">
        <strike>
          <Price>{price}</Price>
        </strike>
      </p>
    );
  }
  return null;
}

export default OriginalPrice;
