import React from 'react';
import {Link, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {makeSelectUser} from '../Authentication/selectors';
import DefaultAddress from './DefaultAddress';

function Dashboard() {
  const user = useSelector(makeSelectUser());
  const {slug} = useParams();
  return (
    <div className="w-75 account">
      <h1>ACCOUNT</h1>
      <div className="header">
        <div className="row">
          <div className="label">
            <h2>{user.name}</h2>
          </div>
        </div>
        <div className="row">
          <div className="label">
            <h3>Email</h3>
          </div>
          <div className="value">
            <p>{user.email}</p>
          </div>
        </div>
        <div className="row">
          <div className="label">
            <h3>Telephone</h3>
          </div>
          <div className="value">
            <p>{user.user_addresses[0].telephone}</p>
          </div>
        </div>
      </div>
      <div className="navs">
        <Link className="active" to={`/shop/${slug}/profile/edit`}>
          UPDATE DETAILS
        </Link>
        <Link to={`/shop/${slug}/profile/email/update`}>UPDATE EMAIL</Link>
        <Link to={`/shop/${slug}/profile/password/update`}>
          UPDATE PASSWORD
        </Link>
      </div>
      <div className="seperator">
        <hr />
      </div>
      <div className="addresses">
        <DefaultAddress address={user.user_addresses[0]} />
      </div>
    </div>
  );
}

export default Dashboard;
