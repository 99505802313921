import {
  CLEAR_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_SUCCESS,
  LOGIN,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  LOGOUT_ERROR,
  LOGOUT_SUCCESS,
  REGISTER,
  REGISTER_ERROR,
  REGISTER_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
} from './constants';

export const login = (details) => ({
  type: LOGIN,
  details,
});

export const loginError = (status) => ({
  type: LOGIN_ERROR,
  status,
});

export const loginSuccess = (user, token) => ({
  type: LOGIN_SUCCESS,
  user,
  token,
});

export const register = (details) => ({
  type: REGISTER,
  details,
});

export const registerSuccess = (user) => ({
  type: REGISTER_SUCCESS,
  user,
});

export const registerError = (status) => ({
  type: REGISTER_ERROR,
  status,
});

export const logout = () => ({
  type: LOGOUT,
});

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});

export const logoutError = () => ({
  type: LOGOUT_ERROR,
});

export const forgotPassword = (email) => ({
  type: FORGOT_PASSWORD,
  email,
});

export const forgotPasswordSuccess = () => ({
  type: FORGOT_PASSWORD_SUCCESS,
});

export const forgotPasswordError = () => ({
  type: FORGOT_PASSWORD_ERROR,
});

export const resetPassword = (token, password) => ({
  type: RESET_PASSWORD,
  token,
  password,
});

export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS,
});

export const resetPasswordError = (status) => ({
  type: RESET_PASSWORD_ERROR,
  status,
});

export const clearUser = () => ({
  type: CLEAR_USER,
});
