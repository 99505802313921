import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Price from '../../Components/Price';
import {productPriceWithoutQuantity} from '../../Libs/prices';
import {changeQuantity, removeFromCart} from './actions';
import {makeSelectCheckoutMode} from './selectors';

function CartItem({product, index}) {
  const dispatch = useDispatch();
  const mode = useSelector(makeSelectCheckoutMode());
  const onChangeQuantity = (e) => {
    dispatch(changeQuantity(parseInt(e.target.value || 0, 10), index));
  };
  const onUpdateQuantity = (e) => {
    if (e.keyCode === 38) {
      dispatch(changeQuantity(product.quantity + 1, index));
    }
    if (e.keyCode === 40 && product.quantity > 1) {
      dispatch(changeQuantity(product.quantity - 1, index));
    }
  };
  const price = productPriceWithoutQuantity(product, mode);

  const onIncrementQuantity = () => {
    dispatch(changeQuantity(product.quantity + 1, index));
  };

  const onDecrementQuantity = () => {
    if (product.quantity > 1) {
      dispatch(changeQuantity(product.quantity - 1, index));
    }
  };
  return (
    <div className="row item-row">
      <div className="c-item">
        <div className="remove" onClick={() => dispatch(removeFromCart(index))}>
          <i className="fas fa-minus" />
        </div>
        <div className="content">
          <h2>{product.title}</h2>
          {product.tiers.map((tier, i) => {
            if (!tier) {
              return null;
            }
            if (tier.type === 'Addon') {
              return (
                <div key={tier + i}>
                  <strong>{tier.basket_title}</strong>
                  <ul>
                    {tier.selected.free &&
                      tier.selected.free.map((addon) => (
                        <li key={addon.id}>
                          <strong>{addon.quantity}x</strong> {addon.title}{' '}
                          <strong>(free)</strong>
                        </li>
                      ))}
                    {tier.selected.paid &&
                      tier.selected.paid.map((addon) => (
                        <li key={addon.id}>
                          <strong>{addon.quantity}x</strong> {addon.title}
                        </li>
                      ))}
                  </ul>
                </div>
              );
            }
            return (
              <p key={tier.title + i}>
                <strong>{tier.basket_title}:&nbsp;</strong>
                {tier.selected.title}
              </p>
            );
          })}
        </div>
      </div>
      <div className="c-price">
        <Price>{price}</Price>
      </div>
      <div className="c-quantity">
        <button
          type="button"
          className="increment"
          onClick={onIncrementQuantity}
        >
          <i className="fas fa-plus" />
        </button>
        <input
          name="quantity"
          type="text"
          autoComplete="off"
          value={product.quantity}
          onChange={onChangeQuantity}
          onKeyDown={onUpdateQuantity}
        />
        <button
          type="button"
          className="decrement"
          onClick={onDecrementQuantity}
        >
          <i className="fas fa-minus" />
        </button>
      </div>
      <div className="c-subtotal">
        <Price>{price * product.quantity}</Price>
      </div>
    </div>
  );
}

export default CartItem;
