import React from 'react';
import Master from '../Master';
import Navbar from '../../Components/Navbar';
import Header from '../../Components/Header';

function SiteMap() {
  return (
    <Master navbar={Navbar}>
      <Header title="Sitemap" />
      <section id="sitemap">
        <div className="row">
          <h1>SITEMAP</h1>
        </div>
        <div className="row">
          <div className="column f-3">
            <p>
              <strong>
                We have compiled a comprehensive list of all pages and
                information available on our website. If you still can’t find
                what you require, please{' '}
                <a href="/contact-us" className="text-primary">
                  contact us
                </a>
                .
              </strong>
            </p>
          </div>
          <div className="column f-2">
            <h2>PAGES</h2>
            <ul>
              <li>
                <a href="#">Order Now</a>
              </li>
              <ul>
                <li>
                  <a href="/our-food/order-favorite">Order Favorite</a>
                </li>
              </ul>
              <li>
                <a href="/cookies">Cookies</a>
              </li>
              <li>
                <a href="/privacy-policy">Privacy</a>
              </li>
              <li>
                <a href="/terms-conditions">Terms & Conditions</a>
              </li>
              <li>
                <a href="/terms-of-sale">Terms of Sale</a>
              </li>
              <li>
                <a href="/sitemap">Sitemap</a>
              </li>
            </ul>
          </div>
          <div className="column f-3">
            <h2>NEWS</h2>
            <ul>
              <li>Brand-new Snack Rappa in stores this week.</li>
              <li>All we want for Christmas....</li>
              <li>Favorite opening soon in Hull Grampian</li>
              <li>6 plus 4 to share</li>
              <li>Favorite Chicken & Ribs Beckenham Re-opens!</li>
              <li>
                New Menu Design on its way to your Favorite Ben & Jerry&lsquo;s
                now at selected Favorite locations
              </li>
              <li>Get in - Bring on the wings!</li>
              <li>
                Britain&lsquo;s Tastiest Chicken arrives in Hemel Hempstead!
              </li>
              <li>It&lsquo;s all about the Gravy!</li>
              <li>Now Open in Great Linford!</li>
            </ul>
          </div>
        </div>
      </section>
    </Master>
  );
}

export default SiteMap;
