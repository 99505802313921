import React from 'react';
import Master from '../Master';
import ShopNavbar from '../../Components/ShopNavbar';
import Login from './Login';
import Register from './Register';
import Header from '../../Components/Header';

function Authentication() {
  return (
    <Master navbar={ShopNavbar}>
      <Header title="Login" />
      <section id="account">
        <Login />
        <Register />
      </section>
    </Master>
  );
}

export default Authentication;
