import React from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

function Content({step, setStep, addToDone, done}) {
  const renderStep = () => {
    switch (step) {
      case 'step-1':
        return (
          <Step1
            step={step}
            addToDone={addToDone}
            setStep={setStep}
            done={done}
          />
        );
      case 'step-2':
        return (
          <Step2
            step={step}
            addToDone={addToDone}
            done={done}
            setStep={setStep}
          />
        );
      case 'step-3':
        return <Step3 step={step} />;
      default:
        return null;
    }
  };
  return <div className="content">{renderStep()}</div>;
}

export default Content;
