/* eslint-disable no-use-before-define */
import findIndex from 'lodash/findIndex';
import {fromJS} from 'immutable';
import {getProperPrice} from './prices';

export const checkIfProductCompleted = (selected, {tiers = []}) => {
  let completable = true;
  tiers.every((tier, index) => {
    if (tier.required === 1) {
      completable = !!selected.tiers[index];
      return completable;
    }
    return true;
  });
  return completable;
};
export const getProductDiscount = (discount, mode) => {
  return discount[mode];
};

export const getAddonIndex = (addon, addons = []) => {
  return findIndex(addons, (a) => a.id === addon.id);
};

export const addAddonToTier = (selected, index, addon, tier) => {
  const freeQuantity = getFreeAddonsQuantity(
    selected.getIn(['tiers', index, 'selected'])?.toJS() || [],
  );
  const paidQuantity = getPaidAddonsQuantity(
    selected.getIn(['tiers', index, 'selected'])?.toJS() || [],
  );
  let tag = 'paid';
  if (freeQuantity < tier.free_addons) {
    tag = 'free';
  }
  if (paidQuantity === tier.max_addons) {
    return selected;
  }
  const addonIndex = getAddonIndex(
    addon,
    selected.getIn(['tiers', index, 'selected', tag])?.toJS(),
  );
  if (addonIndex !== -1) {
    return selected.setIn(
      ['tiers', index, 'selected', tag, addonIndex, 'quantity'],
      selected.getIn([
        'tiers',
        index,
        'selected',
        tag,
        addonIndex,
        'quantity',
      ]) + 1,
    );
  }
  if (!selected.getIn(['tiers', index])) {
    return selected
      .setIn(['tiers', index], fromJS(tier))
      .updateIn(['tiers', index, 'selected', tag], (addons = fromJS([])) =>
        addons.push({
          ...addon,
          price: getProperPrice(addon, selected.getIn(['tiers', 0])),
          quantity: 1,
        }),
      );
  }
  return selected.updateIn(
    ['tiers', index, 'selected', tag],
    (addons = fromJS([])) =>
      addons.push({
        ...addon,
        price: getProperPrice(addon, selected.getIn(['tiers', 0])),
        quantity: 1,
      }),
  );
};
export const getAddonQuantity = (addons) => {
  const freeTotal =
    addons.free?.reduce((total, a) => a.quantity + total, 0) || 0;
  const paidTotal =
    addons.paid?.reduce((total, a) => a.quantity + total, 0) || 0;
  return freeTotal + paidTotal;
};

export const getFreeAddonsQuantity = (addons) => {
  return addons.free?.reduce((total, a) => a.quantity + total, 0) || 0;
};

export const getPaidAddonsQuantity = (addons) => {
  return addons.paid?.reduce((total, a) => a.quantity + total, 0) || 0;
};

export const getProductsCount = (products) =>
  products.reduce((total, product) => product.quantity + total, 0);

export const getSelectedAddonQuantity = (selected = [], addonId) => {
  const index = selected.findIndex((addon) => addon.id === addonId);
  if (index !== -1) {
    return selected[index].quantity;
  }
  return 0;
};

export const getAdditionalTiers = (specificTiers, clickable = []) =>
  specificTiers.filter((specificTier) => specificTier.tag === clickable.title);
