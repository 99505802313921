import {createStore, applyMiddleware} from 'redux';
import {routerMiddleware} from 'connected-react-router/immutable';
import {persistStore, autoRehydrate} from 'redux-persist-immutable';
// eslint-disable-next-line import/no-extraneous-dependencies
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware from 'redux-saga';
import {fromJS} from 'immutable';

import createReducer from './Reducers';
import rootSaga from './Sagas';
import history from '../Libs/history';

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState = {}) {
  const middlewares = [sagaMiddleware, routerMiddleware(history)];

  const enhancers = [applyMiddleware(...middlewares), autoRehydrate()];

  const store = createStore(
    createReducer(history),
    fromJS(initialState),
    composeWithDevTools(...enhancers),
  );

  store.runSaga = sagaMiddleware.run(rootSaga);

  /* const persistedStore = */ persistStore(store, {
    whitelist: ['shop', 'cart', 'user'],
    version: 1,
  });

  // persistedStore.purge();

  return store;
}
