import {createSelector} from 'reselect';

export const selectAuth = (state) => state.get('auth');

export const selectUser = (state) => state.get('user');

export const makeSelectUser = () =>
  createSelector(selectUser, (UserState) => UserState.get('user').toJS());

export const makeSelectState = () =>
  createSelector(selectAuth, (AuthState) => AuthState.get('state'));

export const makeSelectToken = () =>
  createSelector(selectUser, (UserState) => UserState.get('token'));

export const makeSelectMode = () =>
  createSelector(selectAuth, (AuthState) => AuthState.get('mode'));

export const makeSelectStatus = () =>
  createSelector(selectAuth, (AuthState) => AuthState.get('status'));

export const makeSelectVerificationCode = () =>
  createSelector(selectAuth, (AuthState) => AuthState.get('verificationCode'));

export const makeSelectUserAddresses = () =>
  createSelector(selectUser, (UserState) =>
    UserState.getIn(['user', 'user_addresses']).toJS(),
  );
