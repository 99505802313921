import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Price from './Price';
import {productPrice} from '../Libs/prices';
import {removeFromCart} from '../Pages/Cart/actions';
import {makeSelectCheckoutMode} from '../Pages/Cart/selectors';

function SideCartItem({product, index}) {
  const dispatch = useDispatch();
  const mode = useSelector(makeSelectCheckoutMode());
  return (
    <div className="cart-item">
      <div className="remove" onClick={() => dispatch(removeFromCart(index))}>
        <i className="fas fa-minus" />
      </div>
      <div className="cart-body">
        <div className="title">
          <p>{product.quantity}x</p>
          <h2>{product.title}</h2>
          <div className="price">
            <p>
              <Price>{productPrice(product, mode)}</Price>
            </p>
          </div>
        </div>
        {product.tiers.map((tier, i) => {
          if (!tier) {
            return null;
          }
          if (tier.type === 'Addon') {
            return (
              <div className="tier" key={tier + i}>
                <h2 className="tier-title">{tier.basket_title}</h2>
                <ul className="tier-value addon">
                  {tier.selected.free &&
                    tier.selected.free.map((addon) => (
                      <li key={addon.id}>
                        <strong>{addon.quantity}x</strong> {addon.title}{' '}
                        <strong>(free)</strong>
                      </li>
                    ))}
                  {tier.selected.paid &&
                    tier.selected.paid.map((addon) => (
                      <li key={addon.id}>
                        <strong>{addon.quantity}x</strong> {addon.title}
                      </li>
                    ))}
                </ul>
              </div>
            );
          }
          return (
            <div className="tier" key={tier + i}>
              <h2 className="tier-title">{tier.basket_title}:</h2>
              <p className="tier-value">{tier.selected.title}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SideCartItem;
