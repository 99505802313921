import React from 'react';
import {Link, useParams} from 'react-router-dom';
import Login from '../Authentication/Login';

function Step1({done, setStep, addToDone}) {
  const {slug} = useParams();
  return (
    <div className="step-1">
      <div className="column">
        <Login />
      </div>
      <div className="column">
        <div className="not-member">
          <div className="header">
            <h3>CONTINUE TO CHECKOUT</h3>
          </div>
          <div className="body">
            <p>
              Continue placing your order and create an account for future
              convenience!
            </p>
            <p>Registering with us allows you to have:</p>
            <ul>
              <li>Fast and easy checkout</li>
              <li>Easy access to your order history and status</li>
            </ul>
          </div>
          <div className="footer">
            <Link to={`/shop/${slug}/authenticate`} className="register">
              CONTINUE & REGISTER
            </Link>
            <button
              type="button"
              className="guest"
              onClick={() => {
                addToDone([...done, 'step-1']);
                setStep('step-2');
              }}
            >
              CONTINUE AS GUEST
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Step1;
