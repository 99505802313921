import React from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from '../../Components/Marker';
import PostCodeMarker from '../../Components/PostCodeMarker';

const Map = ({stores: [nearest, ...rest], loading}) => {
  if (loading) {
    return null;
  }
  return (
    <div id="map">
      {nearest && (
        <GoogleMapReact
          bootstrapURLKeys={{key: 'AIzaSyDvv6ZhBhV4rHG5EP_pGDi4blouzI_9VhU'}}
          defaultCenter={{
            lat: parseFloat(nearest?.lat || 51.1152),
            lng: parseFloat(nearest?.lon || 0.1893),
          }}
          defaultZoom={9}
        >
          <Marker
            store={nearest}
            lat={parseFloat(nearest.lat)}
            lng={parseFloat(nearest.lon)}
          />
          <PostCodeMarker
            lat={parseFloat(nearest.client_coords.lat)}
            lng={parseFloat(nearest.client_coords.lng)}
          />
          {rest.map((store) => (
            <Marker
              store={store}
              lat={parseFloat(store.lat)}
              lng={parseFloat(store.lon)}
              key={store.slug}
            />
          ))}
        </GoogleMapReact>
      )}
    </div>
  );
};

export default Map;
