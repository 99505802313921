import React from 'react';
import {useSelector} from 'react-redux';
import {getProperPrice, priceByMode} from '../../Libs/prices';
import {checkIfObjectEmpty} from '../../Libs/object';
import {makeSelectCheckoutMode} from '../Cart/selectors';

const Sizes = ({tier, onSelectSize, index, size, enabled}) => {
  const mode = useSelector(makeSelectCheckoutMode());
  const tierSelected = !checkIfObjectEmpty(size);
  return (
    <div className={`tier ${tierSelected ? 'tier-selected' : ''}`}>
      {tier.required === 1 && checkIfObjectEmpty(size) && (
        <div className="required">*</div>
      )}
      <select
        name="item"
        id=""
        disabled={!enabled}
        value={checkIfObjectEmpty(size) ? '' : size.index}
        onChange={(e) =>
          onSelectSize(index, tier.sizes[e.target.value], {
            id: tier.id,
            title: tier.title,
            type: tier.type,
            basket_title: tier.basket_title,
            type_id: tier.type_id,
            index: e.target.value,
          })
        }
      >
        <option value="" disabled>
          {tier.title}
        </option>
        {tier.sizes.map((s, i) => {
          const prices = getProperPrice(s);
          const itemPrice = priceByMode(prices, mode);
          return (
            <option value={i} key={s.title}>
              {s.title} {itemPrice === 0 ? '' : `+£${itemPrice / 100}`}
            </option>
          );
        })}
      </select>
      {tierSelected && (
        <div className="check-box">
          <i className="fa fa-check-circle" />
        </div>
      )}
    </div>
  );
};

export default Sizes;
