const stripePublicKey =
  'pk_live_51GvmdMHO2dTikXh6ek3LFos059VeGm30lIfAqJaNQVZa0YLvCPdGbeXYeWvlYJYatGyOH3LShCDC6J7vmhyCK0Yv00QRrZssdl';
// const stripeSecretKey = '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';
// Stripe test keys
// const stripePublicKey =
//   'pk_test_51GvmdMHO2dTikXh6S5c6zPimL3X7mxhychNM0SGw1mbBmj86NmbjfEtko487Wt56A6Ots142VgcmN6ulDUP10KtF00nPmt7x5t';
// const stripeSecretKey =
//   'sk_test_51GvmdMHO2dTikXh6nTUSP9g4IW6YOBYkERf8e1do0pdADXKpaTBe5jOpWkiKBJhRBEkIuAJxlI41GmsFQZQOTetv00WIYOhH2M';

export default stripePublicKey;
