import {createSelector} from 'reselect';

export const selectCart = (state) => state.get('cart');

export const makeSelectProducts = () =>
  createSelector(selectCart, (CartState) => CartState.get('products').toJS());

export const makeSelectCheckoutMode = () =>
  createSelector(selectCart, (CartState) => CartState.get('mode'));

export const makeSelectState = () =>
  createSelector(selectCart, (CartState) => CartState.get('state'));

export const makeSelectVoucher = () =>
  createSelector(selectCart, (CartState) => CartState.get('voucher').toJS());
