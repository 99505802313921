import React from 'react';
import {
  Redirect,
  useParams,
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Master from '../Master';
import ShopNavbar from '../../Components/ShopNavbar';
import {makeSelectState, makeSelectToken} from '../Authentication/selectors';
import ProfileSideBar from '../../Components/ProfileSideBar';
import FullLoader from '../../Components/FullLoader';
import Dashboard from './Dashboard';
import Edit from './Edit';
import EditEmail from './EditEmail';
import EditPassword from './EditPassword';
import Addresses from './Addresses';
import AddAddress from './AddAddress';
import EditAddress from './EditAddress';
import Orders from './Orders';
import Vouchers from './Vouchers';
import MarketingPreferences from './MarketingPreferences';

function Profile() {
  const token = useSelector(makeSelectToken());
  const state = useSelector(makeSelectState());
  const {slug} = useParams();
  const {path} = useRouteMatch();
  if (token.length === 0) {
    return <Redirect to={`/shop/${slug}/authenticate`} />;
  }
  if (state === 'loading') {
    return (
      <Master navbar={ShopNavbar}>
        <FullLoader />
      </Master>
    );
  }
  return (
    <Master navbar={ShopNavbar}>
      <section id="profile">
        <ProfileSideBar />
        <Switch>
          <Route path={`${path}/dashboard`} component={Dashboard} />
          <Route path={`${path}/edit`} component={Edit} />
          <Route path={`${path}/email/update`} component={EditEmail} />
          <Route path={`${path}/password/update`} component={EditPassword} />
          <Route path={`${path}/address/edit/:id`} component={EditAddress} />
          <Route path={`${path}/address/new`} component={AddAddress} />
          <Route path={`${path}/address`} component={Addresses} />
          <Route path={`${path}/orders`} component={Orders} />
          <Route path={`${path}/orders/:id`} component={Orders} />
          <Route path={`${path}/vouchers`} component={Vouchers} />
          <Route
            path={`${path}/marketing-preferences`}
            component={MarketingPreferences}
          />
        </Switch>
      </section>
    </Master>
  );
}

export default Profile;
