import React from 'react';
import {checkIfObjectEmpty} from '../../Libs/object';

function Clickable({
  clickables,
  handleSelectClickable,
  selectedClickable,
  handleRemoveClickable,
}) {
  if (!(clickables?.length > 0)) {
    return null;
  }
  return (
    <div
      className={`tier ${
        !checkIfObjectEmpty(selectedClickable) ? 'tier-selected' : ''
      }`}
    >
      <div className="clickables">
        {clickables.map((clickable) => (
          <div className="clickable" key={clickable.id}>
            <div
              className="title"
              onClick={() => handleSelectClickable(clickable)}
            >
              {clickable.title}
            </div>
            {!checkIfObjectEmpty(selectedClickable) &&
              selectedClickable.id === clickable.id && (
                <button
                  type="button"
                  className="remove-clickable"
                  onClick={handleRemoveClickable}
                >
                  <i className="fa fa-times" />
                </button>
              )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Clickable;
