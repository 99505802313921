/* eslint-disable */
const initialForm = {
  first_name: '',
  last_name: '',
  telephone: '',
  mobile: '',
};

export default function validateEditProfile(inputs = initialForm) {
  if (inputs.first_name.trim().length === 0) {
    return 'First Name field is required';
  }
  if (!inputs.first_name.match(/^[a-zA-Z ,.'-]+$/)) {
    return 'First Name contain invalid characters';
  }
  if (inputs.last_name.trim().length === 0) {
    return 'Last Name field is required';
  }
  if (!inputs.last_name.match(/^[a-zA-Z ,.'-]+$/)) {
    return 'Last Name contain invalid characters';
  }
  if (inputs.telephone.trim().length === 0) {
    return 'Mobile number is required.';
  }
  if(inputs.telephone.startsWith('0')) {
    if(!(inputs.telephone.trim().length === 11)) {
      return 'Mobile number must be 11 digits if starting from 0.';
    }
  } else {
    if(!(inputs.telephone.trim().length === 10)) {
      return 'Mobile number must be 10 digits.';
    }
  }
  if (!inputs.telephone.match(/^(\(?[0-9]{1}\d{1,4}?\)?\s?\d{3,4}\s?\d{3,4})$/)) {
    return 'Invalid mobile number.';
  }
  return 'validated';
}
