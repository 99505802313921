import {fromJS} from 'immutable';

import {
  FETCH_MENU,
  FETCH_MENU_SUCCESS,
  FETCH_MENU_ERROR,
  FETCH_SHOP_SUCCESS,
  SAVE_SHOP,
  DESELECT_SHOP,
  SAVE_POSTCODE,
} from './constants';

const initialState = fromJS({
  state: 'initial',
  menu: [],
  shop: {},
  status: '',
  postcode: '',
});

export default function menuReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MENU:
      return state.set('state', 'loading').set('status', '');
    case FETCH_MENU_SUCCESS:
      return state.set('menu', fromJS(action.menu)).set('state', 'success');
    case FETCH_MENU_ERROR:
      return state.set('status', action.status).set('state', 'error');
    case FETCH_SHOP_SUCCESS:
      return state.set('shop', fromJS(action.shop));
    case SAVE_SHOP:
      return state.set('shop', fromJS(action.shop));
    case DESELECT_SHOP:
      return state.set('shop', fromJS({}));
    case SAVE_POSTCODE:
      return state.set('postcode', action.postcode);
    default:
      return state;
  }
}
