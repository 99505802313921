import {
  ADD_TO_CART,
  ADD_VOUCHER,
  ADD_VOUCHER_ERROR,
  ADD_VOUCHER_SUCCESS,
  BACK_TO_CART,
  CHANGE_CHECKOUT_MODE,
  CHANGE_QUANTITY,
  CLEAR_VOUCHER,
  EMPTY_CART,
  REMOVE_FROM_CART,
} from './constants';

export const addToCart = (product) => ({
  type: ADD_TO_CART,
  product,
});

export const removeFromCart = (index) => ({
  type: REMOVE_FROM_CART,
  index,
});

export const changeQuantity = (quantity, index) => ({
  type: CHANGE_QUANTITY,
  quantity,
  index,
});

export const emptyCart = () => ({
  type: EMPTY_CART,
});

export const changeCheckoutMode = (mode) => ({
  type: CHANGE_CHECKOUT_MODE,
  mode,
});

export const addVoucher = (code) => ({
  type: ADD_VOUCHER,
  code,
});

export const addVoucherSuccess = (voucher) => ({
  type: ADD_VOUCHER_SUCCESS,
  voucher,
});

export const addVoucherError = (status) => ({
  type: ADD_VOUCHER_ERROR,
  status,
});

export const backToCart = () => ({
  type: BACK_TO_CART,
});

export const clearVoucher = () => ({
  type: CLEAR_VOUCHER,
});
