import {createSelector} from 'reselect';

export const selectCheckout = (state) => state.get('checkout');

export const makeSelectDetails = () =>
  createSelector(selectCheckout, (CheckoutState) =>
    CheckoutState.get('details').toJS(),
  );

export const makeSelectPayment = () =>
  createSelector(selectCheckout, (CheckoutState) =>
    CheckoutState.get('payment').toJS(),
  );

export const makeSelectState = () =>
  createSelector(selectCheckout, (CheckoutState) => CheckoutState.get('state'));

export const makeSelectStatus = () =>
  createSelector(selectCheckout, (CheckoutState) =>
    CheckoutState.get('status'),
  );

export const makeSelectUserId = () =>
  createSelector(selectCheckout, (CheckoutState) =>
    CheckoutState.get('userId'),
  );

export const makeSelectBookingId = () =>
  createSelector(selectCheckout, (CheckoutState) =>
    CheckoutState.get('bookingId'),
  );

export const makeSelectMode = () =>
  createSelector(selectCheckout, (CheckoutState) => CheckoutState.get('mode'));

export const makeSelectPaymentMethod = () =>
  createSelector(selectCheckout, (CheckoutState) =>
    CheckoutState.get('paymentMethod').toJS(),
  );

export const makeSelectSecret = () =>
  createSelector(selectCheckout, (CheckoutState) =>
    CheckoutState.get('secret'),
  );
