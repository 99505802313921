import {call, put, takeLatest, select} from 'redux-saga/effects';

import {push} from 'connected-react-router';
import apiUrl from '../../Configs/ApiUrl';
import apiCall from '../../Services/ApiCall';
import {PAY} from './constants';
import {paymentError, paymentSuccess} from './actions';
import {clearUser} from '../Authentication/actions';
import {makeSelectShop} from '../Shop/selectors';
import PostHeaders from '../../Configs/PostHeaders';

export function* pay({payment}) {
  const store = yield select(makeSelectShop());
  const requestUrl = `${apiUrl}/payments`;

  try {
    yield call(apiCall, requestUrl, PostHeaders(payment));
    // console.log('response', response);
    yield put(paymentSuccess());
  } catch (e) {
    if (e.message === 'Unauthorized') {
      yield put(push(`/shop/${store.slug}/authenticate`));
      yield put(clearUser());
    } else {
      yield put(paymentError(e.message));
    }
    console.log('err occurred', e);
  }
}

export default function* paySaga() {
  yield takeLatest(PAY, pay);
}
