import {call, put, takeLatest} from 'redux-saga/effects';

import apiUrl from '../../Configs/ApiUrl';
import apiCall from '../../Services/ApiCall';
import {ADD_VOUCHER} from './constants';
import {addVoucherSuccess, addVoucherError} from './actions';
import GetHeaders from '../../Configs/GetHeaders';

export function* addVoucher(action) {
  const requestUrl = `${apiUrl}/vouchers/${action.code}/verify`;
  try {
    const voucher = yield call(apiCall, requestUrl, GetHeaders);
    yield put(addVoucherSuccess(voucher));
  } catch (e) {
    console.log('err occurred', e);
    yield put(addVoucherError(e.message));
  }
}

export default function* addVoucherSaga() {
  yield takeLatest(ADD_VOUCHER, addVoucher);
}
