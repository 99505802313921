import React from 'react';
import Loader from 'react-loaders';

function FullLoader({...rest}) {
  return (
    <div className="loader-wrapper">
      <Loader type="line-scale" {...rest} />
    </div>
  );
}

export default FullLoader;
