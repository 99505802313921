import React, {useEffect, useState} from 'react';

const TOP = 98;

function Sticky({children}) {
  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () =>
      setSticky(window.pageYOffset >= TOP),
    );
    return () => {
      window.removeEventListener('scroll', () =>
        setSticky(window.pageYOffset >= TOP),
      );
    };
  }, []);
  return <div id={isSticky ? 'sticky' : ''}>{children}</div>;
}

export default Sticky;
