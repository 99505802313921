import React from 'react';
import ReactDOM from 'react-dom';

import './Assets/styles/favorite.scss';
import {Provider} from 'react-redux';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import Root from './Pages/Root';

import configureStore from './Redux/Store';
import Sticky from './Components/Sticky';
import Hydrated from './Components/Hydrated';
import stripePublicKey from './Configs/Stripe';

const stripePromise = loadStripe(stripePublicKey);
const store = configureStore({});
ReactDOM.render(
  <Provider store={store}>
    <Hydrated>
      <Sticky>
        <Elements stripe={stripePromise}>
          <Root />
        </Elements>
      </Sticky>
    </Hydrated>
  </Provider>,
  document.getElementById('root'),
);
