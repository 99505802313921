import React from 'react';
import {Link} from 'react-router-dom';
import Master from '../Pages/Master';
import Navbar from './Navbar';

function NotFound404({title, message, children, condition}) {
  if (condition) {
    return (
      <Master navbar={Navbar}>
        <section id="not-found" className="row">
          <h1>{title}</h1>
          <p>{message}</p>
          <Link to="/">CLICK HERE TO RETURN TO HOME</Link>
        </section>
      </Master>
    );
  }
  return children;
}

export default NotFound404;
