import {
  CHECKOUT,
  CHECKOUT_ERROR,
  CHECKOUT_SUCCESS,
  CLEAR_CHECKOUT,
  SAVE_FORM_DATA,
  SAVE_PAYMENT,
  SYNC_CART,
  SYNC_CART_ERROR,
  SYNC_CART_SUCCESS,
} from './constants';

export const saveFromData = (details) => ({
  type: SAVE_FORM_DATA,
  details,
});

export const syncCart = () => ({
  type: SYNC_CART,
});

export const syncCartSuccess = (bookingId, userId) => ({
  type: SYNC_CART_SUCCESS,
  userId,
  bookingId,
});

export const syncCartError = (status) => ({
  type: SYNC_CART_ERROR,
  status,
});

export const savePayment = (payment) => ({
  type: SAVE_PAYMENT,
  payment,
});

export const checkout = (payment, paymentMethod, secret) => ({
  type: CHECKOUT,
  paymentMethod,
  payment,
  secret,
});

export const checkoutSuccess = () => ({
  type: CHECKOUT_SUCCESS,
});

export const checkoutError = (status) => ({
  type: CHECKOUT_ERROR,
  status,
});

export const clearCheckout = () => ({
  type: CLEAR_CHECKOUT,
});
