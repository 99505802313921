import React from 'react';
import {Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Master from '../Master';
import ShopNavbar from '../../Components/ShopNavbar';
import PaymentMethods from './PaymentMethods';
import {makeSelectBookingId} from '../Checkout/selectors';

function Payments() {
  const bookingId = useSelector(makeSelectBookingId());
  if (!bookingId) {
    return <Redirect to="./checkout" />;
  }
  return (
    <Master navbar={ShopNavbar}>
      <section id="checkout-response">
        <div className="column response">
          <div className="response-value">
            <div className="status">
              <PaymentMethods />
            </div>
          </div>
        </div>
      </section>
    </Master>
  );
}

export default Payments;
