import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {useDispatch, useSelector} from 'react-redux';
import PaymentForm from './PaymentForm';
import apiCall from '../../Services/ApiCall';
import apiUrl from '../../Configs/ApiUrl';
import AuthorizedPostHeaders from '../../Configs/AuthorizedPostHeaders';
import {makeSelectToken} from '../Authentication/selectors';
import {
  cartTotal,
  getDeliveryCharges,
  getVoucherPrice,
} from '../../Libs/prices';
import {
  makeSelectCheckoutMode,
  makeSelectProducts,
  makeSelectVoucher,
} from '../Cart/selectors';
import {makeSelectShop} from '../Shop/selectors';
import {makeSelectBookingId, makeSelectDetails} from '../Checkout/selectors';
import {checkout} from '../Checkout/actions';

const WithCard = ({methods, setShowCard, showCard}) => {
  const dispatch = useDispatch();
  const {push} = useHistory();
  const token = useSelector(makeSelectToken());
  const products = useSelector(makeSelectProducts());
  const mode = useSelector(makeSelectCheckoutMode());
  const voucher = useSelector(makeSelectVoucher());
  const branch = useSelector(makeSelectShop());
  const subTotal = cartTotal(products, mode);
  const details = useSelector(makeSelectDetails());
  const discount = getVoucherPrice(voucher, subTotal);
  const deliveryCharges = getDeliveryCharges(
    branch.delivery_operation,
    mode,
    subTotal,
  );
  const bookingId = useSelector(makeSelectBookingId());
  const total = subTotal + deliveryCharges - discount;
  const [secret, setSecret] = useState('');

  const handleCheckout = (paymentMethod) => {
    dispatch(checkout(paymentMethod, secret));
    push('/response');
  };

  useEffect(() => {
    apiCall(
      `${apiUrl}/stripe/paymentIntent/create`,
      AuthorizedPostHeaders(
        {
          amount: total,
          email: details.email,
          booking_id: bookingId,
        },
        token,
      ),
    )
      .then((response) => {
        setSecret(response.client_secret);
      })
      .catch((e) => {
        console.log('create intent error', e);
      });
  }, [bookingId, details.email, token, total]);

  const index = methods.findIndex((method) => method.title === 'stripe');
  if (index === -1) {
    return null;
  }
  return (
    <>
      {showCard && (
        <p className="card-title">PLEASE PROVIDE YOUR CARD DETAILS.</p>
      )}
      <PaymentForm
        handleCheckout={handleCheckout}
        showCard={showCard}
        setShowCard={setShowCard}
        secret={secret}
        total={total}
      />
    </>
  );
};

export default WithCard;
