import React from 'react';
import Master from '../Master';
import Navbar from '../../Components/Navbar';
import Header from '../../Components/Header';

function TermsOfSale() {
  return (
    <Master navbar={Navbar}>
      <Header title="Terms and Conditions of Sale" />
      <section id="terms-conditions-of-sale">
        <div className="column">
          <h1>TERMS AND CONDITIONS OF SALE</h1>
          <p>
            These are the terms and conditions on which our independent Favorite
            franchisees (&ldquo;<strong>Franchisees</strong>&rdquo;) supply
            products to you, whether in store or by phone, via our websites
            (favorite.co.uk and favorite.uk) and via our mobile, tablet or other
            applications (our &ldquo;<strong>Websites</strong>&rdquo;).
          </p>
          <p>
            Please read these terms and conditions carefully before ordering as
            these terms will apply to any orders you place. We recommend that
            you print and retain a copy for future reference.
          </p>
          <p>
            By placing an order, you agree to be bound by these terms and
            conditions. If you do not accept these terms and conditions, you
            should not place an order.
          </p>
          <p>
            If you have any questions relating to these terms and conditions
            please contact Favorite using our{' '}
            <a href="/contact-us" className="text-primary">
              Contact Form
            </a>{' '}
            before you place an order.
          </p>
          <p>
            Your use of our Websites, and any orders you place, is governed by
            our{' '}
            <a href="/terms-conditions" className="text-primary">
              <strong>Terms of Use</strong>
            </a>
            ,{' '}
            <a href="/terms-of-sale" className="text-primary">
              <strong>Terms of Sale</strong>
            </a>
            ,{' '}
            <a href="/privacy-policy" className="text-primary">
              <strong>Privacy Policy</strong>
            </a>{' '}
            and our{' '}
            <a href="/cookies" className="text-primary">
              Cookie Policy
            </a>
            . Please take the time to read these as they include important terms
            which apply to you.
          </p>
          <h3>INFORMATION ABOUT US</h3>
          <p>
            Our Websites are operated by Favorite Fried Chicken Limited
            (&ldquo;we&rdquo;, &ldquo;our&rdquo; or &ldquo;us&rdquo;). We are
            registered in England and Wales under company number 2044486 at 7
            Davy Road, Clacton on Sea, Essex, CO15 4XD. You can contact us using
            our{' '}
            <a href="/contact-us" className="text-primary">
              <strong>Contact Form</strong>
            </a>
            . Our VAT number is 442116683.
          </p>
          <p>
            Favorite is a franchised business, where all Favorite stores (the
            &ldquo;<strong>Store(s)</strong>&rdquo;) are owned and operated by
            independent Franchisees. We operate our Websites on behalf of our
            Franchisees.
          </p>
          <p>
            When we refer to the &ldquo;Favorite Group&rdquo; we mean Favorite
            Fried Chicken Limited (the franchisor) together with the Favorite
            franchisees who independently own and operate the Stores.
          </p>
          <p>
            Each Franchisee is independently responsible for its own legal and
            regulatory compliance and for the operation of its own Store(s) and
            all compliance and other issues arising from any transactions with
            you. This means that each Franchisee is solely liable for all
            products you purchase from them whether via our Websites or in
            store.
          </p>
          <p>
            We accept orders on our Websites as agent on behalf of our
            Franchisees. The legal contract in relation to your order is between
            you and the Franchisee that operates the Store providing your order.
            Your order is subject to these terms and conditions.
          </p>
          <p>
            Apple Inc. is not a sponsor of, nor is it responsible for, any
            promotional offers within our iOS applications.
          </p>
          <h3>SERVICE AVAILABILITY</h3>
          <p>
            Subject to minimum delivery spends, Stores offer a delivery service
            to certain prescribed areas of the UK to ensure that our products
            are at their best when they reach your door. If you live outside a
            prescribed delivery area, you will not be able to have our products
            delivered to your door although you may collect any products you
            order from your chosen Store.
          </p>
          <p>
            We do not accept orders from individuals to a country in which we do
            not have a Store.
          </p>
          <p>
            For the safety of our drivers, a driver will only deliver to the
            main door/reception when delivering to apartment blocks, flats or
            hotels.
          </p>
          <h3>ORDERING</h3>
          <p>
            You may only place orders if you are at least 18 years old. By
            ordering, you warrant that you are at least 18 years old and you are
            legally capable of entering into binding contracts.
          </p>
          <p>
            If you place an order via our Websites, you will be presented with
            confirmation on your screen that your order has been received and
            accepted by our Franchisee. Your contract with the Franchisee is
            only formed when you have been presented with this confirmation.
          </p>
          <p>
            You will also receive a confirmation email. Please make sure that
            the email address, home address and telephone number you provide are
            correct and in proper working order, as these are required to fulfil
            your order. Please also ensure that, where you have placed an order
            for collection, you have ordered from the correct Store.
          </p>
          <p>
            If you place an order in a Store, your contract will be formed when
            you receive your receipt of purchase.
          </p>
          <p>
            You are responsible for ensuring that your store preferences and
            &lsquo;saved order&lsquo; details are up to date. If your saved
            store preferences indicate that you wish to order for collection
            from a particular store, please check your order confirmation email
            carefully in case your preferred store is unable to fulfil your
            order.
          </p>
          <p>
            In the unlikely scenario that you place an order on our Websites for
            collection from a store that is unable to fulfil your order, for
            example, if the store is closed. You should check your order
            confirmation email carefully before travelling to collect your
            order.
          </p>
          <p>
            Our Franchisees provide a quality service. We accept no
            responsibility or liability for the quality or quantity of any
            products delivered by or collected from a Franchisee. All food
            preparation and deliveries are the sole responsibility of the
            Franchisee accepting your order.
          </p>
          <p>
            On website orders we will only accept a maximum of 2x discount codes
            to be applied per order.{' '}
          </p>
          <h3>PRODUCTS</h3>
          <p>
            All products are subject to availability. In most cases, our
            Franchisee will offer an alternative for any out-of-stock item. Some
            Franchisees do not sell all of our products.
          </p>
          <p>
            For allergen information see our{' '}
            <a href="/our-store/allergens" className="text-primary">
              <strong>allergen information here</strong>
            </a>
            . For online orders there are links to Allergen information within
            the product details.
          </p>
          <p>
            If you have an allergy and are ordering over the telephone at your
            chosen Store please inform your order-taker at the Store in full of
            your allergies.
          </p>
          <p>
            We have some products that are suitable for vegetarians however
            please note that we do not have a dedicated preparation or cooking
            area in our stores for vegetarian food. If you are a vegetarian
            please inform your order-taker who will do their best to ensure that
            any risk of cross-contamination with your order is minimised.
          </p>
          <p>
            Great care is taken in the preparation of all our products, however,
            we cannot guarantee that all bones from meat products have been
            removed and some may remain.
          </p>
          <p>
            All images of our products contained on our menus and Websites are
            for illustrative purposes only.
          </p>
          <h3>AVAILABILITY AND DELIVERY</h3>
          <p>
            We strive to maintain on-time delivery within the guidance on the
            individual Franchisee pages. However, unfortunately, things do not
            always go to plan and factors such as the weather and traffic
            conditions may occasionally prevent us from achieving this.
          </p>
          <p>
            Our Franchisee will do its best to fulfil your order in accordance
            with the date and time confirmed to you at the time of your order.
          </p>
          <p>
            If your order is for delivery and you have requested delivery
            &lsquo;asap&lsquo;, our Franchisee will do its best to fulfil your
            order within a reasonable time of your confirmation email, taking
            into account the volume of orders and circumstances facing the
            Franchisee at the time.
          </p>
          <h3>CANCELLATION & REFUNDS</h3>
          <p>
            You have the right to cancel an order by telephoning the relevant
            Store up until either:
          </p>
          <ol>
            <li>
              in the case of any advance order (which is/are order(s) placed
              more than one day before a requested delivery date), up to one day
              before the order is due to be delivered; or
            </li>
            <li>
              in the case of same day orders and deliveries, within a reasonable
              time prior to any food being used to start preparing your order.
            </li>
          </ol>
          <p>
            If you wish to cancel an order after food has been used to start
            preparing it, you may, at the Franchisee&lsquo;s sole discretion, be
            charged the full price of the order and no refund will be due to
            you.
          </p>
          <p>
            In the unfortunate circumstance that the Franchisee needs to cancel
            your order after it has been accepted, the Franchisee will notify
            you. We and our Franchisees reserve the right to cancel any order,
            before or after acceptance, and will notify you immediately of any
            such cancellation.
          </p>
          <p>
            You will not be charged for any orders we or our Franchisees cancel.
            Any payment made prior to an order being cancelled by us or our
            Franchisee will typically be reimbursed using the same method
            originally used by you to pay for your purchase.
          </p>
          <p>
            In the unlikely event that you are not satisfied with your meal
            and/or the service provided please contact the Store directly so
            that they can resolve the issue. Should you wish to contact our own
            customer feedback team please email us using our{' '}
            <a href="/contact-us" className="text-primary">
              <strong>contact form</strong>
            </a>
            .
          </p>
          <h3>PRICE AND PAYMENT</h3>
          <p>
            Prices are as quoted on our menus, Websites and in store and may
            change from time to time, although changes will not affect orders
            already placed (unless due to a technical error). Prices include VAT
            where applicable. Prices are set by each Franchisee and will vary
            between Stores. Each Franchisee will typically verify prices as part
            of the order process and its decision in relation to pricing is
            final and binding.
          </p>
          <p>
            Payments made electronically, for orders placed via the Websites may
            be made directly to us via payment service providers and
            subsequently passed to the relevant Franchisee. Cash payments, and
            for orders placed in stores, are made directly to the Franchisee.{' '}
            <strong>
              We do not store credit card details nor do we share customer
              details with any 3rd parties
            </strong>
            .
          </p>
          <h3>OUR LIABILITY</h3>
          <p>
            If we and/or a Franchisee fail to comply with these terms, we and/or
            the Franchisee (as applicable) are responsible for loss or damage
            you suffer that is a foreseeable result of the breach of these terms
            or negligence, up to the amount of the purchase price you paid for
            the order applicable to your claim.
          </p>
          <p>
            However, we and/or the Franchisee (as applicable) are not
            responsible for any loss or damage that is not foreseeable. Loss or
            damage is foreseeable if it is an obvious consequence of a breach or
            if it would reasonably be contemplated by you, us and the Franchisee
            at the time of your purchase.
          </p>
          <p>
            We and our Franchisees only supply products for private and business
            use. You agree not to use any product for commercial or re-sale
            purposes and we have no liability to you for any loss of profit,
            loss of business, business interruption, or loss of business
            opportunity.
          </p>
          <p>
            We, and our Franchisees, do not exclude or limit our liability for:
          </p>
          <ol>
            <li>
              death or personal injury caused by our, or our Franchisee&lsquo;s,
              negligence;
            </li>
            <li>fraud or fraudulent misrepresentation; or</li>
            <li>any liability which cannot legally be limited or excluded.</li>
          </ol>
          <h3>WRITTEN COMMUNICATION</h3>
          <p>
            Applicable laws require that some of the information or
            communications we send to you should be in writing. When using the
            Websites, you accept that communication with us will be mainly
            electronic. We will contact you by email or provide you with
            information by posting notices on the Websites. For contractual
            purposes, you agree to electronic means of communication and you
            acknowledge that all contracts, notices, information and other
            communications that we provide to you electronically comply with any
            legal requirement that such communications be in writing. This
            condition does not affect your statutory rights.
          </p>
          <p>
            If you wish to contact us or a Franchisee at any time, you should
            contact us using our{' '}
            <a href="/contact-us" className="text-primary">
              <strong>Contact Form</strong>
            </a>{' '}
            or contact the Franchisee at your local Store.
          </p>
          <h3>EVENTS OUTSIDE OUR CONTROL</h3>
          <p>
            We and our Franchisees will not be liable or responsible for any
            failure to perform, or delay in performance of, any of our
            obligations under a contract that is caused by events outside our
            reasonable control (a &ldquo;<strong>Force Majeure Event</strong>
            &rdquo;).
          </p>
          <p>
            A Force Majeure Event includes any act, event, non-happening,
            omission or accident beyond both our reasonable control and the
            reasonable control of our Franchisees and includes in particular
            (without limitation) the following:
          </p>
          <ol>
            <li>strikes, lock-outs or other industrial action;</li>
            <li>
              civil commotion, riot, invasion, terrorist attack or threat of
              terrorist attack, war (whether declared or not) or threat or
              preparation for war;
            </li>
            <li>
              fire, explosion, storm, flood, extreme snow, ice, earthquake,
              subsidence, epidemic or other natural disaster;
            </li>
            <li>
              impossibility of the use of railways, shipping, aircraft, motor
              transport or other means of public or private transport;
            </li>
            <li>
              impossibility of the use of public or private telecommunications
              networks;
            </li>
            <li>
              the acts, decrees, legislation, regulations or restrictions of any
              government; or
            </li>
            <li>non-performance by suppliers or subcontractors.</li>
          </ol>
          <h3>WAIVER</h3>
          <p>
            If we or our Franchisees fail to insist that you perform any of your
            obligations under these terms and conditions, or if we or our
            Franchisees do not enforce our rights against you, or delay in doing
            so, that will not mean that we or our Franchisees have waived our
            rights against you or that you do not have to comply with those
            obligations. If we or our Franchisees waive a default by you, this
            will only be done in writing, and will not mean that we or our
            Franchisees will automatically waive any later default by you.
          </p>
          <h3>SEVERABILITY</h3>
          <p>
            Each of the paragraphs of these terms and conditions operates
            separately. If any court or relevant authority decides that any of
            them are unlawful or unenforceable, the remaining paragraphs will
            remain in full force and effect.
          </p>
          <h3>ASSIGNMENT</h3>
          <p>
            We or our Franchisees may transfer our rights and obligations under
            any contract with you to another organisation, but this will not
            affect your rights or our obligations under these terms and
            conditions. You may only transfer your rights or your obligations
            under these terms and conditions to another person if we agree in
            writing.
          </p>
          <h3>ENTIRE AGREEMENT</h3>
          <p>
            These terms and conditions and any document expressly referred to in
            them represent the entire agreement in relation to the subject
            matter of any contract and supersede any prior agreement,
            understanding or arrangement between you, us and our Franchisees,
            whether oral or in writing.
          </p>
          <h3>VARIATION OF THESE TERMS AND CONDITIONS</h3>
          <p>
            We have the right to amend these terms and conditions from time to
            time. Any changes we make to these terms and conditions will be
            posted on this page. You will be subject to the policies and terms
            and conditions in force at the time that you order, unless any
            change to those policies or these terms and conditions is required
            to be made by law or governmental authority.
          </p>
          <h3>THIRD PARTY RIGHTS</h3>
          <p>
            No one other than a party to these terms and conditions shall have
            any right to enforce any of its terms and conditions.
          </p>
          <h3>LAW AND JURISDICTION</h3>
          <p>
            Any dispute or claim arising out of or in connection with your order
            or their subject matter or formation (including non-contractual
            disputes or claims) will be governed by English law. Any dispute or
            claim arising out of or in connection with such contracts or their
            formation (including non-contractual disputes or claims) shall be
            subject to the non-exclusive jurisdiction of the courts of England
            and Wales.
          </p>
        </div>
      </section>
    </Master>
  );
}

export default TermsOfSale;
