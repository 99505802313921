import React from 'react';
import Master from '../Master';
import Navbar from '../../Components/Navbar';
import Header from '../../Components/Header';

function Cookies() {
  return (
    <Master navbar={Navbar}>
      <Header title="Cookies" />
      <section id="cookies">
        <div className="row">
          <h1>COOKIES & COPYRIGHT</h1>
        </div>
        <div className="row">
          <div className="column">
            <h4>COOKIES</h4>
            <p>
              This site uses cookies - small text files that are placed on your
              machine to help the site provide a better user experience.
            </p>
            <p>
              In general, cookies are used to retain user preferences, store
              information for things like shopping carts, and provide anonymised
              tracking data to third party applications like Google Analytics.
            </p>
            <p>
              As a rule, cookies will make your browsing experience better.
              However, you may prefer to disable cookies on this site and on
              others. The most effective way to do this is to disable cookies in
              your browser.
            </p>
            <p>
              We suggest consulting the Help section of your browser or taking a
              look at the About Cookies website which offers guidance for all
              modern browsers.
            </p>
          </div>
          <div className="column">
            <h4>COPYRIGHT</h4>
            <p>
              All content included on this interactive service, such as text,
              graphics, icons, images and software, is the intellectual property
              of Favorite Fried Chicken Limited and is protected by UK and
              European copyright laws.
            </p>
            <p>
              The content and software on this interactive service may only be
              used for the purposes of information or as a shopping resource.
              Any other use, including the reproduction, modification,
              distribution, transmission, republication, or display, of the
              content on this interactive service is strictly prohibited.
            </p>
            <p>
              Favorite Chicken & Ribs graphics, logos and trade names are
              trademarks of Favorite Fried Chicken Limited. Favorite trademarks
              may not be used in connection with any product or service that is
              not affiliated with Favorite, nor in any manner that is likely to
              cause confusion among customers, nor in any manner that disparages
              or discredits Favorite Chicken & Ribs.
            </p>
          </div>
        </div>
      </section>
    </Master>
  );
}

export default Cookies;
