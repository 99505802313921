import {createSelector} from 'reselect';

export const selectResponse = (state) => state.get('response');

export const makeSelectResponse = () =>
  createSelector(selectResponse, (ResponseState) =>
    ResponseState.get('response'),
  );

export const makeSelectPayment = () =>
  createSelector(selectResponse, (ResponseState) =>
    ResponseState.get('payment').toJS(),
  );

export const makeSelectState = () =>
  createSelector(selectResponse, (ResponseState) => ResponseState.get('state'));

export const makeSelectStatus = () =>
  createSelector(selectResponse, (ResponseState) =>
    ResponseState.get('status'),
  );

export const makeSelectMode = () =>
  createSelector(selectResponse, (ResponseState) => ResponseState.get('mode'));
