import React, {useEffect, useState} from 'react';
import Master from '../Master';
import Navbar from '../../Components/Navbar';
import Header from '../../Components/Header';

import apiCall from '../../Services/ApiCall';
import apiUrl from '../../Configs/ApiUrl';
import GetHeaders from '../../Configs/GetHeaders';
import OurFoodInfo from './OurFoodInfo';
import FullLoader from '../../Components/FullLoader';
import Modal from './Modal';

function OurFood() {
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({});
  const [state, setState] = useState('initial');
  useEffect(() => {
    setState('loading');
    apiCall(
      `${apiUrl}/menus/byBranchSlugWithProductsAllergens/favorite-chicken`,
      GetHeaders,
    )
      .then((response) => {
        setProducts(response);
        setState('success');
      })
      .catch(() => {
        setState('error');
      });
  }, []);
  if (state === 'loading') {
    return (
      <Master navbar={Navbar}>
        <Header title="Our Food" />
        <section id="our-food">
          <div className="row">
            <h1>OUR FOOD</h1>
          </div>
          <div className="row">
            <OurFoodInfo />
            <div className="column f-2">
              <FullLoader />
            </div>
          </div>
        </section>
      </Master>
    );
  }
  return (
    <Master navbar={Navbar}>
      <Header title="Our Food" />
      <section id="our-food">
        <div className="row">
          <h1>OUR FOOD</h1>
        </div>
        <Modal product={product} setProduct={setProduct} />
        <div className="row">
          <OurFoodInfo />
          <div className="column f-2">
            {products.map((submenu) => (
              <div id={submenu.slug} className="submenu" key={submenu.title}>
                <h2>{submenu.title}</h2>
                <div className="items">
                  {submenu.products.map((item) => (
                    <div
                      className="item"
                      key={item.title}
                      onClick={() => setProduct(item)}
                    >
                      <div className="thumb">
                        <img src={item.image} alt={item.title} />
                      </div>
                      <div className="title">{item.title}</div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Master>
  );
}

export default OurFood;
