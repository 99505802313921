import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import findIndex from 'lodash/findIndex';
import FullLoader from '../../Components/FullLoader';
import InputField from '../../Components/InputField';
import Loadable from '../../Components/Loadable';
import {makeSelectMode, makeSelectState, makeSelectStatus} from './selectors';
import validateAddAddress from '../../Libs/validateAddAddress';
import {editAddress} from './actions';
import {makeSelectUser} from '../Authentication/selectors';
import {quantizeAddress, reformatTelephoneNumber} from '../../Libs/profile';
import {getFirstLastName} from '../../Libs/checkout';

function EditAddress() {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    user_addresses: [defaultAddress],
  } = useSelector(makeSelectUser());
  const {id} = useParams();
  const mode = useSelector(makeSelectMode());
  const state = useSelector(makeSelectState());
  const status = useSelector(makeSelectStatus());
  const user = useSelector(makeSelectUser());
  const [first, last] = getFirstLastName(defaultAddress.name);
  const [aState, setAState] = useState('initial');
  const [addresses, setAddresses] = useState([]);
  const [firstName, setFirstName] = useState(first || '');
  const [lastName, setLastName] = useState(last || '');
  const [telephone, setTelephone] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [county, setCounty] = useState('');
  const [postcode, setPostcode] = useState('');
  const [validation, setValidation] = useState(status);

  const handleEdit = (e) => {
    e.preventDefault();
    const details = {
      first_name: firstName,
      last_name: lastName,
      telephone,
      address,
      city,
      county,
      postcode,
    };
    const validated = validateAddAddress(details);
    setValidation(validated);
    if (validated === 'validated') {
      // console.log('address id', id);
      dispatch(
        editAddress(
          {...details, telephone: reformatTelephoneNumber(telephone)},
          id,
        ),
      );
    }
  };

  const searchPostCode = async (p) => {
    // console.log('postcode', postcode);
    if (p.length === 0) {
      return;
    }
    setAddresses([]);
    setAState('loading');
    try {
      const response = await fetch(
        `https://api.getaddress.io/find/${p}?api-key=s5r1ZZOZ-kuzBUQ9n_yZqw29840`,
      );
      const json = await response.json();
      // console.log('addresses', json);
      if (json.addresses && json.addresses?.length) {
        setAState('success');
        setAddresses(json?.addresses);
      } else {
        setAState('error');
      }
    } catch (e) {
      setAState('error');
      console.log('post code search error', e);
    }
  };

  const selectAddress = (adrs, cty, cnty) => {
    setAddress(adrs);
    setCity(cty);
    setCounty(cnty);
  };

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    const aIndex = findIndex(user.user_addresses, (a) => id == a.id);
    if (aIndex >= 0) {
      const currentAddress = user.user_addresses[aIndex];
      setFirstName(currentAddress.name.split(' ')[0]);
      setLastName(currentAddress.name.split(' ')[1]);
      setTelephone(currentAddress.telephone.substring(4));
      setAddress(currentAddress.address);
      setCity(currentAddress.city);
      setCounty(currentAddress.county);
      setPostcode(currentAddress.postcode);
      searchPostCode(currentAddress.postcode);
    }
  }, [id]);

  return (
    <div className="w-75 account">
      <h1>UPDATE ADDRESS</h1>
      {state === 'loading' ? (
        <FullLoader />
      ) : (
        <>
          {state === 'success' && mode === 'edit-address' ? (
            <p className="alert alert-success">Address successfully updated.</p>
          ) : null}
          {!(
            validation === 'validated' ||
            validation === 'initial' ||
            validation.length === 0
          ) ? (
            <p className="alert alert-danger">{validation}</p>
          ) : null}
          <form onSubmit={handleEdit}>
            <InputField
              className="w-80"
              name="first_name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
            <InputField
              className="w-80"
              name="last_name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />

            <InputField
              className="w-80"
              prefix="+44"
              name="mobile"
              value={telephone}
              onChange={(e) => setTelephone(e.target.value)}
              required
            />
            <div className="seperator">
              <hr />
            </div>
            <div className="row">
              <div className="column">
                <InputField
                  className="w-73"
                  name="postcode"
                  value={postcode}
                  onChange={(e) => setPostcode(e.target.value)}
                  required
                />
              </div>
              <button
                type="button"
                className="secondary"
                onClick={() => searchPostCode(postcode)}
              >
                <Loadable color="white" active={aState === 'loading'}>
                  GET ADDRESSES
                </Loadable>
              </button>
            </div>
            {addresses.length > 0 ? (
              <div className="address-suggestions">
                <h2>Please select an address</h2>
                {addresses.map((addressString) => {
                  const [adrs, cty, cnty] = quantizeAddress(addressString);
                  return (
                    <div
                      key={addressString}
                      className={`address-suggestion ${
                        adrs === address ? 'selected' : ''
                      }`}
                      onClick={() => selectAddress(adrs, cty, cnty)}
                    >
                      <div className="address">
                        <h5>{adrs}</h5>
                      </div>
                      <div className="other">
                        <div className="other-info">{cty},</div>
                        <div className="other-info">{cnty}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="address-suggestions">
                <div className="center">No addresses</div>
              </div>
            )}
            <div className="actions">
              <button
                type="button"
                className="light"
                onClick={() => history.goBack()}
              >
                BACK TO ACCOUNT
              </button>
              <button type="submit" className="secondary">
                <Loadable active={state === 'loading'} color="white">
                  SAVE ADDRESS
                </Loadable>
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
}

export default EditAddress;
