import React, {useState} from 'react';
import {Link, useParams, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import FullLoader from '../../Components/FullLoader';
import {emptyCart} from '../Cart/actions';
import apiCall from '../../Services/ApiCall';
import apiUrl from '../../Configs/ApiUrl';
import PostHeaders from '../../Configs/PostHeaders';
import {makeSelectBookingId, makeSelectUserId} from '../Checkout/selectors';
import Loadable from '../../Components/Loadable';

function Status({response}) {
  const dispatch = useDispatch();
  const bookingId = useSelector(makeSelectBookingId());
  const userId = useSelector(makeSelectUserId());
  const [state, setState] = useState('initial');
  const [status, setStatus] = useState('');
  const {push} = useHistory();
  const {slug} = useParams();

  const handleDone = () => {
    dispatch(emptyCart());
    push(`/shop/${slug}/menu`);
  };
  const handleDiscard = (e) => {
    e.preventDefault();
    setState('loading');
    setStatus('');
    apiCall(
      `${apiUrl}/bookings/cancel`,
      PostHeaders({
        booking_id: bookingId,
        user_id: userId,
      }),
    )
      .then((r) => {
        console.log('response', r);
        setState('success');
        push(`/shop/${slug}/menu`);
        dispatch(emptyCart());
      })
      .catch((err) => {
        setState('error');
        setStatus(err.message);
        console.log('discard error', err);
      });
  };
  if (response === 'initial' || response === 'incomplete') {
    return (
      <>
        <div className="status">
          <p className="info">
            Please wait while your order is being processed.
          </p>
          <FullLoader />
          <button
            className="method discard"
            type="button"
            onClick={handleDiscard}
          >
            {state === 'error' ? (
              <p className="alert alert-danger">{status}</p>
            ) : null}
            <Loadable color="white" active={state === 'loading'}>
              Discard
            </Loadable>
          </button>
        </div>
        <Link to={`/shop/${slug}/cart`} type="button" className="method">
          Back To Cart
        </Link>
      </>
    );
  }
  if (response === 'rejected') {
    return (
      <>
        <div className="status">
          <div className="rejected">Your order has been rejected.</div>
        </div>
        <Link to={`/shop/${slug}/cart`} type="button" className="method">
          Back To Cart
        </Link>
      </>
    );
  }
  if (response === 'approved') {
    return (
      <>
        <div className="status">
          <div className="accepted">Your order has been approved.</div>
        </div>
        <button onClick={handleDone} type="button" className="method">
          Done
        </button>
      </>
    );
  }
  return null;
}

export default Status;
