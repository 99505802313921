import {
  EDIT_EMAIL,
  EDIT_EMAIL_ERROR,
  EDIT_EMAIL_SUCCESS,
  EDIT_PASSWORD,
  EDIT_PASSWORD_ERROR,
  EDIT_PASSWORD_SUCCESS,
  EDIT_PROFILE,
  EDIT_PROFILE_ERROR,
  EDIT_PROFILE_SUCCESS,
  ADD_ADDRESS,
  ADD_ADDRESS_SUCCESS,
  ADD_ADDRESS_ERROR,
  EDIT_ADDRESS,
  EDIT_ADDRESS_SUCCESS,
  EDIT_ADDRESS_ERROR,
  REMOVE_ADDRESS,
  REMOVE_ADDRESS_ERROR,
  REMOVE_ADDRESS_SUCCESS,
  MAKE_DEFAULT,
  MAKE_DEFAULT_SUCCESS,
  MAKE_DEFAULT_ERROR,
} from './constants';

export const editProfile = (details, id) => ({
  type: EDIT_PROFILE,
  details,
  id,
});

export const editProfileSuccess = (user) => ({
  type: EDIT_PROFILE_SUCCESS,
  user,
});

export const editProfileError = (status) => ({
  type: EDIT_PROFILE_ERROR,
  status,
});

export const editPassword = (details) => ({
  type: EDIT_PASSWORD,
  details,
});

export const editPasswordSuccess = (token, user) => ({
  type: EDIT_PASSWORD_SUCCESS,
  token,
  user,
});

export const editPasswordError = (status) => ({
  type: EDIT_PASSWORD_ERROR,
  status,
});

export const editEmail = (details) => ({
  type: EDIT_EMAIL,
  details,
});

export const editEmailSuccess = (token, user) => ({
  type: EDIT_EMAIL_SUCCESS,
  token,
  user,
});

export const editEmailError = (status) => ({
  type: EDIT_EMAIL_ERROR,
  status,
});

export const addAddress = (details) => ({
  type: ADD_ADDRESS,
  details,
});

export const addAddressSuccess = (user) => ({
  type: ADD_ADDRESS_SUCCESS,
  user,
});

export const addAddressError = (status) => ({
  type: ADD_ADDRESS_ERROR,
  status,
});

export const editAddress = (details, id) => ({
  type: EDIT_ADDRESS,
  details,
  id,
});

export const editAddressSuccess = (user) => ({
  type: EDIT_ADDRESS_SUCCESS,
  user,
});

export const editAddressError = (status) => ({
  type: EDIT_ADDRESS_ERROR,
  status,
});

export const removeAddress = (id) => ({
  type: REMOVE_ADDRESS,
  id,
});

export const removeAddressSuccess = (user) => ({
  type: REMOVE_ADDRESS_SUCCESS,
  user,
});

export const removeAddressError = (status) => ({
  type: REMOVE_ADDRESS_ERROR,
  status,
});

export const makeDefault = (id) => ({
  type: MAKE_DEFAULT,
  id,
});

export const makeDefaultSuccess = (user) => ({
  type: MAKE_DEFAULT_SUCCESS,
  user,
});

export const makeDefaultError = (status) => ({
  type: MAKE_DEFAULT_ERROR,
  status,
});
