import React from 'react';
import {useHistory} from 'react-router-dom';

function MarketingPreferences() {
  const history = useHistory();
  return (
    <div className="w-75 account">
      <h1>MARKETING PREFERENCES</h1>
      <div className="preferences">
        <h3>Please select your marketing preferences below.</h3>
        <div className="form-group">
          <input type="checkbox" id="email" name="email" value="Email" />
          <label htmlFor="email">
            Update me with the latest news and offers by Email
          </label>
        </div>
        <div className="form-group">
          <input type="checkbox" id="sms" name="sms" value="SMS" />
          <label htmlFor="sms">
            Update me with the latest news and offers by SMS
          </label>
        </div>

        <div className="actions">
          <button
            type="button"
            className="light"
            onClick={() => history.goBack()}
          >
            BACK TO ACCOUNT
          </button>
          <button type="submit" className="secondary">
            UPDATE DETAILS
          </button>
        </div>
      </div>
    </div>
  );
}

export default MarketingPreferences;
