import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import InputField from '../../Components/InputField';
import {editEmail} from './actions';
import {makeSelectMode, makeSelectState, makeSelectStatus} from './selectors';
import Loadable from '../../Components/Loadable';
import FullLoader from '../../Components/FullLoader';
import validateEditEmail from '../../Libs/validateEditEmail';

function EditEmail() {
  const history = useHistory();
  const dispatch = useDispatch();
  const mode = useSelector(makeSelectMode());
  const state = useSelector(makeSelectState());
  const status = useSelector(makeSelectStatus());
  const [email, setEmail] = useState('');
  const [cEmail, setCEmail] = useState('');
  const [validation, setValidation] = useState(status);

  const handleEdit = (e) => {
    e.preventDefault();
    const details = {
      email,
      email_confirmation: cEmail,
    };
    const validated = validateEditEmail(details);
    setValidation(validated);
    if (validated === 'validated') {
      dispatch(editEmail(details));
    }
  };
  useEffect(() => {
    setValidation(status);
  }, [status]);
  return (
    <div className="w-75 account">
      <h1>EDIT EMAIL</h1>
      {state === 'loading' ? (
        <FullLoader />
      ) : (
        <>
          {state === 'success' && mode === 'email' ? (
            <p className="alert alert-success">Email successfully updated.</p>
          ) : null}
          {!(
            validation === 'validated' ||
            validation === 'initial' ||
            validation.length === 0
          ) ? (
            <p className="alert alert-danger">{validation}</p>
          ) : null}
          <form onSubmit={handleEdit}>
            <InputField
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <InputField
              name="confirm_email"
              value={cEmail}
              onChange={(e) => setCEmail(e.target.value)}
              required
            />
            <div className="actions">
              <button
                type="button"
                className="light"
                onClick={() => history.goBack()}
              >
                BACK TO ACCOUNT
              </button>
              <button type="submit" className="secondary">
                <Loadable active={state === 'loading'} color="white">
                  UPDATE DETAILS
                </Loadable>
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
}

export default EditEmail;
