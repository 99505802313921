import React from 'react';
import moment from 'moment';

const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
function TodayDeliveryOperation({status, operation}) {
  const day = moment().day();
  const delivery = operation.deliveryDays[days[day]];
  return (
    <div className="operation">
      <h3>{status === 1 ? 'Delivering Today' : 'Delivery Closed Today'}</h3>
      <p>
        {delivery.map((t) => (
          <span key={t}>{t}</span>
        ))}
      </p>
    </div>
  );
}

export default TodayDeliveryOperation;
