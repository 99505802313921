import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import InputField from '../../Components/InputField';
import {editProfile} from './actions';
import {makeSelectMode, makeSelectState, makeSelectStatus} from './selectors';
import validateEditProfile from '../../Libs/validateEditProfile';
import Loadable from '../../Components/Loadable';
import FullLoader from '../../Components/FullLoader';
import {makeSelectUser} from '../Authentication/selectors';
import {
  deformatTelephoneNumber,
  reformatTelephoneNumber,
} from '../../Libs/profile';
import {getFirstLastName} from '../../Libs/checkout';

function Edit() {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    user_addresses: [defaultAddress],
  } = useSelector(makeSelectUser());
  const mode = useSelector(makeSelectMode());
  const state = useSelector(makeSelectState());
  const status = useSelector(makeSelectStatus());
  const [first, last] = getFirstLastName(defaultAddress.name);
  const [firstName, setFirstName] = useState(first || '');
  const [lastName, setLastName] = useState(last || '');
  const [telephone, setTelephone] = useState(
    deformatTelephoneNumber(defaultAddress.telephone),
  );
  const [validation, setValidation] = useState('validated');

  const handleEdit = (e) => {
    e.preventDefault();
    const details = {
      first_name: firstName,
      last_name: lastName,
      telephone,
      address: defaultAddress.address,
      city: defaultAddress.city,
      county: defaultAddress.county,
      postcode: defaultAddress.postcode,
    };
    const validated = validateEditProfile(details);
    setValidation(validated);
    if (validated === 'validated') {
      dispatch(
        editProfile(
          {...details, telephone: reformatTelephoneNumber(telephone)},
          defaultAddress.id,
        ),
      );
    }
  };

  if (state === 'loading') {
    return (
      <div className="w-75 account">
        <h1>UPDATE PROFILE</h1>
        <FullLoader />
      </div>
    );
  }
  return (
    <div className="w-75 account">
      <h1>UPDATE PROFILE</h1>
      <>
        {state === 'success' && mode === 'profile' ? (
          <p className="alert alert-success">Profile successfully updated.</p>
        ) : null}
        {/* eslint-disable-next-line no-nested-ternary */}
        {validation !== 'validated' ? (
          <p className="alert alert-danger">{validation}</p>
        ) : state === 'error' && mode === 'profile' ? (
          <p className="alert alert-danger">{status}</p>
        ) : null}
        <form onSubmit={handleEdit}>
          <InputField
            name="first_name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <InputField
            name="last_name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <InputField
            name="mobile"
            value={telephone}
            onChange={(e) => setTelephone(e.target.value)}
            required
            className="telephone-input"
            prefix="+44"
          />
          <div className="actions">
            <button
              type="button"
              className="light"
              onClick={() => history.goBack()}
            >
              BACK TO ACCOUNT
            </button>
            <button type="submit" className="secondary">
              <Loadable active={state === 'loading'} color="white">
                UPDATE DETAILS
              </Loadable>
            </button>
          </div>
        </form>
      </>
    </div>
  );
}

export default Edit;
