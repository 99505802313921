import React from 'react';
import Master from '../Master';
import Navbar from '../../Components/Navbar';
import Header from '../../Components/Header';

function PrivacyPolicy() {
  return (
    <Master navbar={Navbar}>
      <Header title="Privacy Policy" />
      <section id="privacy-policy">
        <div className="column">
          <h1>PRIVACY POLICY</h1>
          <p>
            We are committed to complying with GDPR and respecting the privacy
            rights of our customers. This privacy policy explains how we
            collect, store and use personal data about you when you visit our
            websites (favorite.co.uk and sub domains) and our other mobile
            applications (our “Websites”). This policy applies to all our
            Websites regardless of how they are made available to you or
            accessed by you.
          </p>
          <p>
            Please read this privacy policy carefully before you start using our
            Websites, as this policy will apply to your use of our Websites. We
            recommend that you print and retain a copy for future reference.
          </p>
          <p>
            By accessing, browsing and using our Websites, you confirm that you
            accept this privacy policy and that you agree to comply with the
            terms. If you do not want your data to be used as set out in this
            privacy policy, you should not use our Websites.
          </p>
          <p>
            Your use of our Websites, and any orders you place, is governed by
            our{' '}
            <a href="/terms-conditions" className="text-primary">
              <strong>Terms of Use</strong>
            </a>
            ,{' '}
            <a href="/terms-of-sale" className="text-primary">
              <strong>Terms of Sale</strong>
            </a>
            ,{' '}
            <a href="/privacy-policy" className="text-primary">
              <strong>Privacy Policy</strong>
            </a>{' '}
            and our{' '}
            <a href="/cookies" className="text-primary">
              <strong>Cookie Policy</strong>
            </a>
            . Please take the time to read these as they include important terms
            which apply to you.
          </p>
          <h3>WHO ARE WE?</h3>
          <p>
            For the purpose of the GDPR (the &ldquo;<strong>Act</strong>
            &rdquo;), the data controller of our Websites is Favorite Fried
            Chicken Limited (&ldquo;<strong>we</strong>&rdquo;, &ldquo;
            <strong>our</strong>&rdquo; or &ldquo;<strong>us</strong>&rdquo;).
            We are registered in England and Wales under company number 2044486
            at 7 Davy Road, Clacton on Sea, Essex, CO15 4XD. You can contact us
            using our{' '}
            <a href="/contact-us" className="text-primary">
              <strong>Contact Form</strong>
            </a>
            . Our VAT number is 442116683.
          </p>
          <p>
            Favorite is a franchised business, where all stores are owned and
            operated by independent Favorite franchisees (each a &ldquo;
            <strong>Franchisee</strong>&rdquo; and together our &ldquo;
            <strong>Franchisees</strong>&ldquo;). In this policy, references to
            <strong>
              ‘we’, ‘us’ and ‘our’ does not include our Franchisees and this
              policy does not apply to or cover the practices of our Franchisees
            </strong>
            .
          </p>
          <p>
            Each Franchisee is independently responsible for its own legal and
            regulatory compliance and for the operation of its own Favorite
            store(s) (our &ldquo;<strong>Store(s)</strong>&ldquo;) and all
            compliance arising from any transactions with you, any products
            ordered by you from our Websites and any products ordered from our
            Stores.
          </p>
          <p>
            When we refer to the &ldquo;<strong>Favorite Group</strong>&ldquo;
            we mean us together with our Franchisees.
          </p>
          <p>
            If you would like to review the privacy policy and practices of any
            of our Franchisees, please visit the Store website of the relevant
            Franchisee (if one exists) or contact the Store directly.
          </p>

          <h3>WHAT INFORMATION DO WE COLLECT?</h3>
          <p>When you visit our Websites, we may collect:</p>
          <ul>
            <li>
              information such as your computer&lsquo;s IP address, browser type
              and version and anonymous information collected from cookies when
              you are browsing our Websites to help us understand how to improve
              our Websites and services to you; and
            </li>
            <li>
              personal information such as your name, address, telephone number
              and email address as provided by you when you register or place an
              order with us and enter your payment card details.
            </li>
          </ul>
          <p>
            Once you register or place an order (even if you do not register
            with us) on our Websites, we know who you are and are able to link
            the information we collect about your computer activities to you.
          </p>
          <p>
            We also engage third party payment processors to process the payment
            of your online order. These third party processors may store your
            payment card details, if you want them to, to speed up your
            transaction time. If you do not want to take advantage of this,
            please do not select this function.{' '}
            <strong>
              We do not store credit card details nor do we share customer
              details with any 3rd parties
            </strong>
            .
          </p>
          <h3>WHAT DO WE DO WITH THE INFORMATION WE COLLECT?</h3>
          <p>
            We use this information for all sorts of reasons. The main reason we
            use this information is to provide you with great products and to
            tell you about our products and services. We may also use the
            information:
          </p>

          <ul>
            <li>for processing your order and answering your queries;</li>
            <li>for product development;</li>
            <li>
              for statistical, market research, testing and survey purposes to
              administer our Websites and for internal operations;
            </li>
            <li>
              to improve our Websites and ensure that content is presented in
              the most effective manner for you and your computer;
            </li>
            <li>
              to notify you about changes to our Websites or the services
              provided by the Favorite Group;
            </li>
            <li>
              to carry out data matching with third parties such as Google in
              order to send you targeted advertising;
            </li>
            <li>
              to measure and understand the effectiveness of advertising we
              serve to you and to deliver relevant advertising to you;
            </li>
            <li>
              to provide you with information and products or services we feel
              may interest you (where you have not otherwise opted-out of direct
              marketing).
            </li>
          </ul>
          <p>
            We may retain copies of any correspondence you send us and we will
            keep your registration and order details. Any personal information
            that you provide to us and any information from which we can
            identify you, is held in accordance with the registration we have
            with the Information Commissioner’s Office.
          </p>
          <h3>WHO DO WE SHARE THIS INFORMATION WITH?</h3>
          <p>
            We may share your personal information with a purchaser or a
            potential purchaser of our business.
          </p>
          <p>
            We may also share your personal information with the Favorite Group.
          </p>
          <p>
            The types of functions that companies engaged by Favorite typically
            carry out include assisting us to more effectively fulfil orders;
            managing and conducting marketing; providing technical assistance
            and support and supporting marketing activities. However, those
            companies will only be permitted to use your personal information
            for the purpose of performing that particular function strictly in
            accordance with our instructions and not for any other purpose.
          </p>
          <p>
            We may also share some of your personal information with certain
            third party companies for analytical purposes. The reason for doing
            so is that it allows us to understand your purchasing options and
            therefore allows us to, where appropriate, send you generic
            marketing offers which may be of most relevance to you.
          </p>
          <p>
            We may provide your details to Google and other third party
            organisations to enable them to carry out a data matching exercise
            on our behalf. The purpose of this data matching exercise is to
            ascertain which of our customers also obtain services from these
            organisations with a view to us delivering bespoke information and
            marketing to you. You can opt out of participating in Google
            Analytics. Please visit our{' '}
            <a href="/cookies" className="text-primary">
              <strong>Cookie Policy</strong>
            </a>{' '}
            to find out more.
          </p>
          <p>
            Personal data may be shared with government authorities and/or law
            enforcement officials if required for the purposes above, if
            mandated by law or if required for the legal protection of our
            legitimate interests in compliance with applicable laws.
          </p>
          <p>
            We expect all third parties that we work with to treat your personal
            information as confidential and to fully comply with all applicable
            UK data protection and consumer legislation.
          </p>
          <h3>WHERE WE STORE YOUR PERSONAL DATA</h3>
          <p>
            The data that we collect from you may be transferred to, and stored
            at, a destination outside the European Economic Area
            (&ldquo;EEA&rdquo;). It may also be processed by selected third
            parties operating outside the EEA engaged in, for example, the
            processing of your payment details. By submitting your personal
            data, you agree to this transfer, storing or processing. We will
            take all steps reasonably necessary to ensure that your data is
            treated securely and in accordance with this privacy policy.
          </p>
          <p>
            Unfortunately, the transmission of information via the internet is
            not completely secure. Although we will do our best to protect your
            personal data, we cannot guarantee the security of any data
            transmitted to our Websites and any transmission is at your own
            risk.
          </p>
          <p>
            Once we have received your information, we will use strict
            procedures and security features to try to prevent unauthorised
            access. All information you provide to us is stored on secure
            servers. In order to prevent unauthorised access or disclosure, we
            have put in place suitable physical, electronic and policies to
            safeguard and secure the information we receive.
          </p>
          <h3>
            WITHDRAWING CONSENT OR OTHERWISE OBJECTING TO DIRECT MARKETING
          </h3>
          <p>
            Wherever we rely on your consent, you will always be able to
            withdraw that consent, although we may have other legal grounds for
            processing your data for other purposes, such as those set out
            above. In some cases, we are able to send you direct marketing
            without your consent, where we rely on our legitimate interests. You
            have an absolute right to opt-out of direct marketing, or profiling
            we carry out for direct marketing, at any time. You can do this by
            following the instructions in the communication where this is an
            electronic message, or by contacting us using the details set out
            below in the “How to contact us?” section.
          </p>
          <h3>WHAT RIGHTS DO I HAVE?</h3>
          <p>
            You have the right to ask us for a copy of your personal data; to
            correct, delete or restrict (stop any active) processing of your
            personal data; and to obtain the personal data you provide to us for
            a contract or with your consent in a structured, machine readable
            format. In addition, you can object to the processing of your
            personal data in some circumstances (in particular, where we don’t
            have to process the data to meet a contractual or other legal
            requirement, or where we are using the data for direct marketing).
            These rights may be limited, for example if fulfilling your request
            would reveal personal data about another person, or if you ask us to
            delete information which we are required by law to keep or have
            compelling legitimate interests in keeping.
          </p>
          <p>
            To exercise any of these rights, you can get in touch with using the
            details set out below. If you have unresolved concerns, you have the
            right to complain to the Information Commissioner’s Office.
          </p>
          <p>
            <strong>
              Please note that we cannot provide you with any information about
              you held by any of our Franchisees as we do not have access to
              this information. To obtain a copy of any information held by a
              Franchisee, you should make a request of the Franchisee directly
              via your local Store.
            </strong>
          </p>
          <h3>PASSWORDS</h3>
          <p>
            You are required to create a password when you register an account
            with us. Creating a secure password is one of the best ways of
            keeping your information safe.
          </p>
          <p>
            You should never write your passwords down anywhere, or share them
            with anyone, including close family members. We will never telephone
            you to ask for any personal information you have given online.
          </p>
          <p>
            Although it may be tempting to use the same password if you have
            more than one online account on different websites, it is far more
            secure to have a different and unique password for each online
            account you have. This reduces the chances of all your accounts
            being accessed if one of your passwords is compromised.
          </p>
          <p>
            You should also regularly change your password to improve the
            security of your online account. Similarly, if you ever have any
            suspicions that someone may have accessed your account, you should
            immediately change your password.
          </p>
          <p>You can re-set your password on your account on the website.</p>
          <p>
            If you&lsquo;ve forgotten your password, enter your email address on
            the &lsquo;forgotten your password&lsquo; page and we&lsquo;ll send
            you an email. Simply follow the instructions in the email to change
            your password.
          </p>
          <h3>PHISHING</h3>
          <p>
            Phishing is the name given to attempts to steal personal details and
            financial account details from a website user.
            &ldquo;Phishers&rdquo; use fake or &ldquo;spoof&rdquo; emails to
            lead users to counterfeit websites where the user is tricked into
            entering their personal details, such as credit card numbers, user
            names and passwords.
          </p>
          <p>
            If you do receive such an email or are asked for your password by
            anyone claiming to work for us, please report the incident using our
            <a href="/contact-us" className="text-primary">
              <strong>Contact Form</strong>
            </a>{' '}
            or in writing to us at Favorite Fried Chicken Limited, 7 Davy Road,
            Clacton on Sea, Essex, CO15 4XD, for the attention of the Data
            Security Manager.
          </p>
          <h3>HOW LONG WILL YOU RETAIN MY DATA?</h3>
          <p>
            We process the majority of your data for as long as you are an
            active user and for 5 years after this.
          </p>
          <p>
            Where we process personal data for marketing purposes or with your
            consent, we process the data for 5 years unless you ask us to stop,
            when we will only process the data for a short period after this (to
            allow us to implement your requests). We also keep a record of the
            fact that you have asked us not to send you direct marketing or to
            process your data indefinitely so that we can respect your request
            in future.
          </p>
          <p>
            Where we process personal data in connection with performing a
            contract we keep the data for 6 years from your last interaction
            with us.
          </p>
          <p>
            We will retain information held to maintain statutory records in
            line with appropriate statutory requirements or guidance.
          </p>
          <h3>LINKING</h3>
          <p>
            We may link to other websites which are not within our control. Once
            you have left our Websites, we cannot be responsible for the
            protection and privacy of any information which you provide. You
            should exercise caution and look at the privacy statement applicable
            to the website in question.
          </p>
          <h3>CHANGES TO OUR PRIVACY POLICY</h3>
          <p>
            Any changes we make to this Privacy Policy will be posted on this
            page and, where appropriate, notified to you by email. Please check
            back frequently to see any updates or changes to our privacy policy.
          </p>
          <h3>HOW TO CONTACT US</h3>
          <p>
            If you have any questions about our Privacy Policy, please contact
            us at Favorite Fried Chicken Limited, 7 Davy Road, Clacton on Sea,
            Essex, CO15 4XD
          </p>
        </div>
      </section>
    </Master>
  );
}

export default PrivacyPolicy;
