import {fromJS} from 'immutable';

import {
  SAVE_PAYMENT,
  SAVE_FORM_DATA,
  SYNC_CART,
  SYNC_CART_SUCCESS,
  SYNC_CART_ERROR,
  CHECKOUT,
  CHECKOUT_SUCCESS,
  CHECKOUT_ERROR,
  CLEAR_CHECKOUT,
} from './constants';
import {EMPTY_CART} from '../Cart/constants';

const initialState = fromJS({
  state: 'initial',
  status: '',
  mode: 'initial',
  details: {},
  payment: {},
  userId: null,
  bookingId: null,
  paymentMethod: {},
  secret: '',
});

export default function checkoutReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_FORM_DATA:
      return state.set('details', fromJS(action.details));
    case SAVE_PAYMENT:
      return state.set('payment', fromJS(action.payment));
    case SYNC_CART:
      return state
        .set('state', 'loading')
        .set('mode', 'sync_cart')
        .set('status', '');
    case SYNC_CART_SUCCESS:
      return state
        .set('state', 'success')
        .set('userId', action.userId)
        .set('bookingId', action.bookingId);
    case SYNC_CART_ERROR:
      return state.set('state', 'error').set('status', action.status);
    case CHECKOUT:
      return state
        .set('state', 'loading')
        .set('mode', 'checkout')
        .set('status', '')
        .set('paymentMethod', fromJS(action.paymentMethod))
        .set('secret', action.secret);
    case CHECKOUT_SUCCESS:
      return state.set('state', 'success');
    case CHECKOUT_ERROR:
      return state.set('state', 'error').set('status', action.status);
    case EMPTY_CART:
      return fromJS(initialState);
    case CLEAR_CHECKOUT:
      return fromJS(initialState);
    default:
      return state;
  }
}
