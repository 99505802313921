export const FETCH_MENU = 'Favorite/Shop/FETCH_MENU';

export const FETCH_MENU_SUCCESS = 'Favorite/Shop/FETCH_MENU_SUCCESS';

export const FETCH_MENU_ERROR = 'Favorite/Shop/FETCH_MENU_ERROR';

export const SAVE_SHOP = 'Favorite/Shop/SAVE_SHOP';

export const FETCH_SHOP = 'Favorite/Shop/FETCH_SHOP';

export const FETCH_SHOP_SUCCESS = 'Favorite/Shop/FETCH_SHOP_SUCCESS';

export const FETCH_SHOP_ERROR = 'Favorite/Shop/FETCH_SHOP_ERROR';

export const DESELECT_SHOP = 'Favorite/Shop/DESELECT_SHOP';

export const SAVE_POSTCODE = 'Favorite/Shop/SAVE_POSTCODE';
