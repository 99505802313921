import {
  FETCH_MENU,
  FETCH_MENU_SUCCESS,
  FETCH_MENU_ERROR,
  FETCH_SHOP,
  FETCH_SHOP_SUCCESS,
  SAVE_SHOP,
  DESELECT_SHOP,
  SAVE_POSTCODE,
} from './constants';

export const fetchMenu = (slug) => ({
  type: FETCH_MENU,
  slug,
});

export const fetchMenuSuccess = (menu) => ({
  type: FETCH_MENU_SUCCESS,
  menu,
});

export const fetchMenuError = (status) => ({
  type: FETCH_MENU_ERROR,
  status,
});

export const fetchShop = (slug) => ({
  type: FETCH_SHOP,
  slug,
});

export const fetchShopSuccess = (shop) => ({
  type: FETCH_SHOP_SUCCESS,
  shop,
});

export const fetchShopError = (status) => ({
  type: status,
});

export const saveShop = (shop) => ({
  type: SAVE_SHOP,
  shop,
});

export const deselectShop = () => ({
  type: DESELECT_SHOP,
});

export const savePostcode = (postcode) => ({
  type: SAVE_POSTCODE,
  postcode,
});
