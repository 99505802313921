/* eslint-disable */
const initialForm = {
  password: '',
  password_confirmation: '',
};

export default function validateEditPassword(inputs = initialForm) {
  if (inputs.password.trim().length === 0) {
    return 'Password field is required';
  }
  if(inputs.password !== inputs.password_confirmation) {
    return 'Password fields must match';
  }
  return 'validated';
}
