import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import {
  makeSelectDetails,
  makeSelectState,
  makeSelectStatus,
} from './selectors';
import CartItem from './CartItem';
import {
  makeSelectCheckoutMode,
  makeSelectProducts,
  makeSelectVoucher,
} from '../Cart/selectors';
import {
  cartTotal,
  getDeliveryCharges,
  getVoucherPrice,
} from '../../Libs/prices';
import {makeSelectShop} from '../Shop/selectors';
import Price from '../../Components/Price';
import {syncCart} from './actions';
import Loadable from '../../Components/Loadable';
import MarketingPreferences from './MarketingPreferences';
import {makeSelectToken} from '../Authentication/selectors';

function Step3() {
  const {slug} = useParams();
  const details = useSelector(makeSelectDetails());
  const products = useSelector(makeSelectProducts());
  const voucher = useSelector(makeSelectVoucher());
  const token = useSelector(makeSelectToken());
  const mode = useSelector(makeSelectCheckoutMode());
  const branch = useSelector(makeSelectShop());
  const state = useSelector(makeSelectState());
  const status = useSelector(makeSelectStatus());
  const dispatch = useDispatch();
  const subTotal = cartTotal(products, mode);
  const discount = getVoucherPrice(voucher, subTotal);
  const deliveryCharges = getDeliveryCharges(
    branch.delivery_operation,
    mode,
    subTotal,
  );
  useEffect(() => {
    dispatch(syncCart());
  }, [dispatch]);
  return (
    <div className="step-3">
      <div className="column f-2">
        <div className="customer-summray">
          <div className="header">
            <h3>ADDRESS DETAILS</h3>
          </div>
          <div className="body">
            <p>
              <strong>
                {details.first_name} {details.last_name}
              </strong>
            </p>
            <p>{details.address}</p>
            <p>{details.city}</p>
            <p>{details.county}</p>
            <p>{details.post_code}</p>
          </div>
          <br />
          <div className="footer">
            <p>
              <strong>Email:</strong>
              {details.email}
            </p>
            <p>
              <strong>Telephone</strong>
              {details.telephone}
            </p>
          </div>
        </div>
      </div>
      <div className="column f-3">
        <div className="order-summary">
          <div className="header">
            <h3>YOUR ORDER</h3>
          </div>
          {products.map((product, index) => (
            <CartItem key={product.title + index} product={product} />
          ))}
          <div className="notes">
            <h5>Order Instructions / Notes</h5>
            <p>{details.notes}</p>
          </div>
          <div className="summary">
            <div className="row">
              <p className="label">Subtotal</p>
              <p className="value">
                <Price>{subTotal}</Price>
              </p>
            </div>
            <div className="row">
              <p className="label">Voucher</p>
              <p className="value">
                {discount > 0 ? '-' : ''}
                <Price>{discount}</Price>
              </p>
            </div>
            <div className="row">
              <div className="label">
                Delivery {mode === 'Takeaway' && '(CLICK AND COLLECT)'}
              </div>
              <div className="value">
                {mode === 'Takeaway' ? (
                  'Free'
                ) : (
                  <Price>{deliveryCharges}</Price>
                )}
              </div>
            </div>
            <div className="row">
              <p className="label">Total</p>
              <p className="total">
                <Price>{subTotal + deliveryCharges - discount}</Price>
              </p>
            </div>
          </div>
        </div>
        {token.length === 0 ? <MarketingPreferences /> : null}

        {state === 'error' && (
          <div className="alert alert-danger">
            <p>{status}</p>
          </div>
        )}
        <div className="actions">
          {state === 'error' && mode === 'sync_cart' ? (
            <button type="button" disabled className="online">
              Checkout
            </button>
          ) : (
            <Link to={`/shop/${slug}/payments`} className="online">
              <Loadable color="white" active={state === 'loading'}>
                Payments
              </Loadable>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default Step3;
