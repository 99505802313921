import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import InputField from '../../Components/InputField';
import {editPassword} from './actions';
import {makeSelectMode, makeSelectState, makeSelectStatus} from './selectors';
import Loadable from '../../Components/Loadable';
import FullLoader from '../../Components/FullLoader';
import validateEditPassword from '../../Libs/validateEditPassword';

function EditPassword() {
  const history = useHistory();
  const dispatch = useDispatch();
  const mode = useSelector(makeSelectMode());
  const state = useSelector(makeSelectState());
  const status = useSelector(makeSelectStatus());
  const [password, setPassword] = useState('');
  const [cPassword, setCPassword] = useState('');
  const [validation, setValidation] = useState(status);

  const handleEdit = (e) => {
    e.preventDefault();
    const details = {
      password,
      password_confirmation: cPassword,
    };
    const validated = validateEditPassword(details);
    setValidation(validated);
    if (validated === 'validated') {
      dispatch(editPassword(details));
    }
  };
  useEffect(() => {
    setValidation(status);
  }, [status]);
  return (
    <div className="w-75 account">
      <h1>EDIT PASSWORD</h1>
      {state === 'loading' ? (
        <FullLoader />
      ) : (
        <>
          {state === 'success' && mode === 'password' ? (
            <p className="alert alert-success">Password successfully updated.</p>
          ) : null}
          {!(
            validation === 'validated' ||
            validation === 'initial' ||
            validation.length === 0
          ) ? (
            <p className="alert alert-danger">{validation}</p>
          ) : null}
          <form onSubmit={handleEdit}>
            <InputField
              name="password"
              value={password}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <InputField
              name="confirm_password"
              value={cPassword}
              type="password"
              onChange={(e) => setCPassword(e.target.value)}
              required
            />
            <div className="actions">
              <button
                type="button"
                className="light"
                onClick={() => history.goBack()}
              >
                BACK TO ACCOUNT
              </button>
              <button type="submit" className="secondary">
                <Loadable active={state === 'loading'} color="white">
                  UPDATE DETAILS
                </Loadable>
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
}

export default EditPassword;
