import {createStructuredSelector} from 'reselect';
import {connect} from 'react-redux';

import {fetchMenu, fetchShop} from './actions';
import {makeSelectMenu, makeSelectShop, makeSelectState} from './selectors';
import Shop from './Shop';

const mapDispatchToProps = (dispatch) => ({
  onFetchMenu: (slug) => dispatch(fetchMenu(slug)),
  onFetchShop: (slug) => dispatch(fetchShop(slug)),
});

const mapStateToProps = createStructuredSelector({
  menu: makeSelectMenu(),
  state: makeSelectState(),
  shop: makeSelectShop(),
});

export default connect(mapStateToProps, mapDispatchToProps)(Shop);
