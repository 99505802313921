import {all, call, put, takeLatest, select} from 'redux-saga/effects';

import {push, goBack} from 'connected-react-router';
import apiUrl from '../../Configs/ApiUrl';
import apiCall from '../../Services/ApiCall';
import {
  ADD_ADDRESS,
  EDIT_ADDRESS,
  EDIT_EMAIL,
  EDIT_PASSWORD,
  EDIT_PROFILE,
  MAKE_DEFAULT,
  REMOVE_ADDRESS,
} from './constants';
import {
  addAddressError,
  addAddressSuccess,
  editAddressError,
  editAddressSuccess,
  editEmailError,
  editEmailSuccess,
  editPasswordError,
  editPasswordSuccess,
  editProfileError,
  editProfileSuccess,
  makeDefaultError,
  makeDefaultSuccess,
  removeAddressError,
  removeAddressSuccess,
} from './actions';
import AuthorizedPostHeaders from '../../Configs/AuthorizedPostHeaders';
import {makeSelectToken} from '../Authentication/selectors';
import {clearUser} from '../Authentication/actions';
import AuthorizedGetHeaders from '../../Configs/AuthorizedGetHeaders';
import {makeSelectShop} from '../Shop/selectors';

export function* editPassword(action) {
  const store = yield select(makeSelectShop());
  const t = yield select(makeSelectToken());
  const requestUrl = `${apiUrl}/users/updateUserPassword`;
  try {
    const {token, user} = yield call(
      apiCall,
      requestUrl,
      AuthorizedPostHeaders(action.details, t),
    );
    yield put(editPasswordSuccess(token, user));
  } catch (e) {
    if (e.message === 'Unauthorized') {
      yield put(clearUser());
      yield put(push(`/shop/${store.slug}/authenticate`));
    } else {
      yield put(editPasswordError(e.message));
    }
    // console.log('err password', e);
  }
}
export function* editEmail(action) {
  const store = yield select(makeSelectShop());
  const t = yield select(makeSelectToken());
  const requestUrl = `${apiUrl}/users/updateUserEmail`;
  try {
    const {token, user} = yield call(
      apiCall,
      requestUrl,
      AuthorizedPostHeaders(
        {
          ...action.details,
        },
        t,
      ),
    );
    yield put(editEmailSuccess(token, user));
  } catch (e) {
    if (e.message === 'Unauthorized') {
      yield put(clearUser());
      yield put(push(`/shop/${store.slug}/authenticate`));
    } else {
      yield put(editEmailError(e.message));
    }
    console.log('err email', e);
  }
}
export function* editProfile(action) {
  const store = yield select(makeSelectShop());
  const token = yield select(makeSelectToken());
  const requestUrl = `${apiUrl}/users/editUserAddress/${action.id}`;
  try {
    const user = yield call(
      apiCall,
      requestUrl,
      AuthorizedPostHeaders(
        {
          ...action.details,
          name: `${action.details.first_name} ${action.details.last_name}`,
        },
        token,
      ),
    );
    yield put(editProfileSuccess(user));
    goBack();
  } catch (e) {
    if (e.message === 'Unauthorized') {
      yield put(clearUser());
      yield put(push(`/shop/${store.slug}/authenticate`));
    } else {
      yield put(editProfileError(e.message));
    }
    // console.log('err profile', e);
  }
}

export function* addAddress(action) {
  // console.log('action', action);
  const store = yield select(makeSelectShop());
  const token = yield select(makeSelectToken());
  const requestUrl = `${apiUrl}/users/addUserAddress`;
  try {
    const user = yield call(
      apiCall,
      requestUrl,
      AuthorizedPostHeaders(
        {
          ...action.details,
          name: `${action.details.first_name} ${action.details.last_name}`,
        },
        token,
      ),
    );
    // console.log('add address', user);
    yield put(addAddressSuccess(user));
    yield put(goBack());
  } catch (e) {
    if (e.message === 'Unauthorized') {
      yield put(clearUser());
      yield put(push(`/shop/${store.slug}/authenticate`));
    } else {
      yield put(addAddressError(e.message));
    }
    console.log('err add address', e);
  }
}

export function* editAddress(action) {
  const store = yield select(makeSelectShop());
  const token = yield select(makeSelectToken());
  const requestUrl = `${apiUrl}/users/editUserAddress/${action.id}`;
  try {
    const user = yield call(
      apiCall,
      requestUrl,
      AuthorizedPostHeaders(
        {
          ...action.details,
          name: `${action.details.first_name} ${action.details.last_name}`,
          town: action.details.city,
        },
        token,
      ),
    );
    // console.log('edit address goback', user);
    yield put(editAddressSuccess(user));
    // yield put(goBack());
  } catch (e) {
    if (e.message === 'Unauthorized') {
      yield put(clearUser());
      yield put(push(`/shop/${store.slug}/authenticate`));
    } else {
      yield put(editAddressError(e.message));
    }
    console.log('err edit address', e);
  }
}

export function* removeAddress(action) {
  const store = yield select(makeSelectShop());
  // console.log('action', action);
  const token = yield select(makeSelectToken());
  const requestUrl = `${apiUrl}/users/removeUserAddress/${action.id}`;
  try {
    const user = yield call(
      apiCall,
      requestUrl,
      AuthorizedPostHeaders(action.details, token),
    );
    // console.log('remove address', response);
    yield put(removeAddressSuccess(user));
  } catch (e) {
    if (e.message === 'Unauthorized') {
      yield put(clearUser());
      yield put(push(`/shop/${store.slug}/authenticate`));
    } else {
      yield put(removeAddressError(e.message));
    }
    console.log('err remove address', e);
  }
}

export function* makeDefault(action) {
  const store = yield select(makeSelectShop());
  // console.log('action', action);
  const token = yield select(makeSelectToken());
  const requestUrl = `${apiUrl}/users/updateDefaultAddress/${action.id}`;
  try {
    const user = yield call(apiCall, requestUrl, AuthorizedGetHeaders(token));
    // console.log('remove address', user);
    yield put(makeDefaultSuccess(user));
  } catch (e) {
    if (e.message === 'Unauthorized') {
      yield put(clearUser());
      yield put(push(`/shop/${store.slug}/authenticate`));
    } else {
      yield put(makeDefaultError(e.message));
    }
    // console.log('err remove address', e);
  }
}

export function* editPasswordSaga() {
  yield takeLatest(EDIT_PASSWORD, editPassword);
}

export function* editEmailSaga() {
  yield takeLatest(EDIT_EMAIL, editEmail);
}

export function* editProfileSaga() {
  yield takeLatest(EDIT_PROFILE, editProfile);
}

export function* addAddressSaga() {
  yield takeLatest(ADD_ADDRESS, addAddress);
}

export function* editAddressSaga() {
  yield takeLatest(EDIT_ADDRESS, editAddress);
}

export function* removeAddressSaga() {
  yield takeLatest(REMOVE_ADDRESS, removeAddress);
}

export function* makeDefaultSaga() {
  yield takeLatest(MAKE_DEFAULT, makeDefault);
}

export default function* profileSaga() {
  yield all([
    editPasswordSaga(),
    editProfileSaga(),
    editEmailSaga(),
    addAddressSaga(),
    editAddressSaga(),
    removeAddressSaga(),
    makeDefaultSaga(),
  ]);
}
