import {fromJS} from 'immutable';
import {REHYDRATE} from 'redux-persist';

const initialState = fromJS({
  hydrated: false,
});

export default function hydrateReducer(state = initialState, action) {
  if (action.type === REHYDRATE) {
    return state.set('hydrated', true);
  }
  return state;
}
