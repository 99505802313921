import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {makeSelectShop} from '../Shop/selectors';
import Paypal from './Paypal';
import PayOnCollection from './PayOnCollection';
import PayOnDelivery from './PayOnDelivery';
import Discard from './Discard';
import {makeSelectState, makeSelectStatus} from '../Response/selectors';
import FullLoader from '../../Components/FullLoader';
import {emptyCart} from '../Cart/actions';
import {makeSelectToken} from '../Authentication/selectors';
import WithCard from './WithCard';

function PaymentMethods() {
  const {push} = useHistory();
  const [showCard, setShowCard] = useState(false);
  const {slug} = useParams();
  const dispatch = useDispatch();
  const token = useSelector(makeSelectToken());
  const shop = useSelector(makeSelectShop());
  const state = useSelector(makeSelectState());
  const status = useSelector(makeSelectStatus());
  useEffect(() => {
    if (state === 'success') {
      dispatch(emptyCart());
      if (token.length) {
        push(`/shop/${slug}/profile/orders`);
      }
      push(`/shop/${slug}/menu`);
    }
  }, [dispatch, push, slug, state, token]);
  if (state === 'loading') {
    return (
      <div className="payments">
        <FullLoader />
      </div>
    );
  }
  if (showCard) {
    return (
      <div className="payments">
        <div className="note">
          ***Incomplete bookings will not be entertained.
        </div>
        {state === 'error' && <p className="alert alert-danger">{status}</p>}
        <WithCard
          showCard={showCard}
          setShowCard={setShowCard}
          methods={shop.paymentmods}
        />
        <Discard methods={shop.paymentmods} />
      </div>
    );
  }
  return (
    <div className="payments">
      <div className="note">
        ***Incomplete bookings will not be entertained.
      </div>
      {state === 'error' && <p className="alert alert-danger">{status}</p>}
      <Paypal methods={shop.paymentmods} />
      <PayOnCollection methods={shop.paymentmods} />
      <PayOnDelivery methods={shop.paymentmods} />
      <WithCard
        showCard={showCard}
        setShowCard={setShowCard}
        methods={shop.paymentmods}
      />
      <Discard methods={shop.paymentmods} />
    </div>
  );
}

export default PaymentMethods;
