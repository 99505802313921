import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useRouteMatch} from 'react-router-dom';
import DefaultAddress from './DefaultAddress';
import {makeSelectUser} from '../Authentication/selectors';
import {makeSelectMode, makeSelectState} from './selectors';
import FullLoader from '../../Components/FullLoader';
import {makeDefault, removeAddress} from './actions';

function Addresses() {
  const dispatch = useDispatch();
  const user = useSelector(makeSelectUser());
  const state = useSelector(makeSelectState());
  const mode = useSelector(makeSelectMode());
  const [defaultAddress, ...additional] = user.user_addresses;
  const {url} = useRouteMatch();

  const handleRemove = (e) => {
    e.preventDefault();
    dispatch(removeAddress(e.target.value));
  };

  const handleMakeDefault = (e) => {
    e.preventDefault();
    dispatch(makeDefault(e.target.value));
  };
  return (
    <div className="w-75 account">
      {state === 'loading' &&
      (mode === 'remove-address' || mode === 'make-default') ? (
        <FullLoader />
      ) : (
        <>
          <h1>ADDRESS BOOK </h1>
          <Link className="new" to={`${url}/new`} type="button">
            ADD NEW
          </Link>
          <div className="addresses">
            <DefaultAddress address={defaultAddress} />
            <div className="seperator">
              <hr />
            </div>
            <div className="row additional">
              <h2>ADDITIONAL ADDRESSES</h2>
              {additional.map((address) => (
                <div key={address.id} className="additional-wrapper">
                  <div key={address.id} className="row">
                    <div className="column f-1">
                      <h5>{address.name}</h5>
                      <div className="actions">
                        <Link to={`${url}/edit/${address.id}`}>EDIT</Link>
                        <button
                          className="remove"
                          type="button"
                          value={address.id}
                          onClick={handleRemove}
                        >
                          REMOVE
                        </button>
                      </div>
                    </div>
                    <div className="column f-2">
                      <p>{address.address}</p>
                      <button
                        type="button"
                        className="make-default"
                        value={address.id}
                        onClick={handleMakeDefault}
                      >
                        Make Default Address
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Addresses;
