import React from 'react';
import {Link} from 'react-router-dom';
import Master from '../Master';
import Navbar from '../../Components/Navbar';
import Header from '../../Components/Header';

const About = () => {
  return (
    <Master navbar={Navbar}>
      <Header title="About" />
      <section id="about">
        <div className="row">
          <h1>OUR STORY</h1>
        </div>
        <div className="row content-row">
          <div className="column our-story">
            <h5>
              Favorite started out in 1986 with a commitment to bring great
              taste, value and service to it&lsquo;s customers and those good
              old-fashioned principles are still at the centre of everything we
              do.
            </h5>
            <img
              src={require('../../Assets/images/join-img-left.png')}
              alt="Favorite"
            />
          </div>
          <div className="column our-story-desc">
            <h4>WE&lsquo;RE ABOUT FAMILY</h4>
            <p>
              Favorite is a family business, as are the majority of our
              franchise stores, we greatly appreciate your custom and when you
              dine with us you&lsquo;re helping support British business and
              your local economy.
            </p>
            <h4>WE&lsquo;RE ABOUT QUALITY</h4>
            <p>
              We believe great quality freshly prepared ingredients are at the
              centre of great taste.
            </p>
            <p>
              Favorite Traditional Chicken pieces are prepared and cooked in
              store using only top quality fresh chicken from reliable approved
              suppliers.
            </p>
            <p>
              Our Chicken Fillet Burgers, whether it’s Traditional, Fillet of
              Fire or Double Ringer, are just that – all tender chicken breast
              hand prepared and cooked the way only Favorite can. The same for
              our Crispy Fillet Strips & Chicken Poppas, whole pieces of chicken
              breast, not minced, not processed, just natural succulent chicken.
            </p>
            <p>
              Favorite Fries are pure potato, blanched in sunflower oil and all
              Favorite cooking oils are free from harmful trans fats.
            </p>
            <p>
              In 1998 Favorite was the first UK food chain to declare a
              &ldquo;GM Free Zone&rdquo;.
            </p>
            <h4>WE&lsquo;RE ABOUT GOOD TIMES</h4>
            <p>
              At Favorite we love food and enjoying great tasting food is one of
              life’s greatest pleasures. Whether you’re treating yourself to
              lunch, are out for the evening or sharing a night in with family
              and friends, Favorite has something for everyone to enjoy.
            </p>
            <h4>WE&lsquo;RE ABOUT THE LOCAL COMMUNITY</h4>
            <p>
              Our franchisees mainly live locally, they’re a part of the
              community and our stores are local businesses and employ local
              people. They greatly appreciate your custom and every time you
              enjoy a meal at Favorite you are helping to support British
              business and your local economy.
            </p>
          </div>
        </div>
        <div className="row faqs" id="faqs">
          <div className="column">
            <h1>FAVORITE QUESTIONS WE&lsquo;RE ASKED MOST</h1>
            <p className="q">Where is my nearest store?</p>
            <p className="a">
              You can use our Store Finder to locate your nearest store.{' '}
              <Link to="/find-store" className="text-primary">
                https://favorite.co.uk/store-finder
              </Link>{' '}
              Simply type in your postcode or town and your nearest stores will
              be shown with address, telephone number, opening hours and if
              Favorite Online is available you can order direct.
            </p>
            <p className="q">How can I order online?</p>
            <p className="a">
              Several of our Favorite stores offer online ordering through our
              website. If you search for your nearest store and see a blue box
              with ‘ORDER ONLINE’ you can order direct for delivery or Click &
              Collect.
            </p>
            <p className="a">
              If a store does not offer Favorite delivery you may still be able
              to order your delivery through our partners – JustEat, UberEats or
              Deliveroo. Their delivery radius varies so please check online or
              through your app.
            </p>
            <p className="q">
              Where can I find information regarding allergens?
            </p>
            <p className="a">
              There are 14 major allergens which all food businesses have to
              mention when used as ingredients. Please click here to view them
              all.{' '}
              <Link className="text-primary" to="/our-food/allergens">
                https://favorite.co.uk/our-food/allergens
              </Link>{' '}
              You can click into each menu item to view the allergen
              information.
            </p>
            <p className="q">How can I change my delivery address?</p>
            <p className="a">
              You can change or amend your delivery address up until when you
              place your order.
            </p>
            <p className="q">What time do you deliver until?</p>
            <p className="a">
              Delivery hours vary between stores so please check via our Store
              Finder.{' '}
              <Link to="/find-store" className="text-primary">
                https://favorite.co.uk/store-finder
              </Link>
            </p>
            <p className="q">Why was my order cancelled?</p>
            <p className="a">
              If there is a problem with payment or the store is unable to
              deliver for any reason, then your order may be cancelled. Any
              payment taken will be refunded within 72 working hours. For any
              queries, please email{' '}
              <span className="text-primary">feedback@favorite.co.uk</span>
            </p>
            <p className="q">What do I do if I have a problem with my order?</p>
            <p className="a">
              Firstly, if you ordered a delivery you will need to contact the
              platform you ordered through. If this was JustEat, UberEats or
              Deliveroo, please log into your account and raise an issue with
              them.
            </p>
            <p className="a">
              If you ordered via our Favorite website or visited a store,
              firstly, please call the store to rectify your issue. You can
              contact us with feedback via email at{' '}
              <span className="text-primary">feedback@favorite.co.uk</span>
            </p>
            <p className="q">Why does my chicken look pink?</p>
            <p className="a">
              Please note that our Spicy Crunchy Wings, Spicy BBQ Wings and our
              Fillet of Fire® Burgers are marinaded prior to cooking in order to
              give them their unique flavour. This may sometimes cause them to
              appear pink in colour when cooked.
            </p>
            <p className="q">How can I apply for a job at Favorite?</p>
            <p className="a">
              If you are interested in working in a Favorite franchise please
              fill out the contact form online here{'  '}
              <Link className="text-primary" to="/jobs">
                https://favorite.co.uk/work-at-favorite
              </Link>
            </p>
            <p className="a">
              We&lsquo;ll then pass your information on to the relevant store
              owner and they will contact you as and when there are any
              vacancies.
            </p>
          </div>
        </div>
      </section>
    </Master>
  );
};

export default About;
