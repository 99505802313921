import React, {useEffect, useState} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';
// import {NavHashLink} from 'react-router-hash-link';
import {useDispatch, useSelector} from 'react-redux';
import {makeSelectShop} from '../Pages/Shop/selectors';
import {checkIfObjectEmpty} from '../Libs/object';
import {deselectShop} from '../Pages/Shop/actions';

const Navbar = () => {
  const {postcode} = useParams();
  const dispatch = useDispatch();
  const {slug} = useParams();
  const [q, setQ] = useState(postcode || '');
  const [showNavbar, setShowNavbar] = useState(false);
  const shop = useSelector(makeSelectShop());
  const {
    location: {pathname},
    push,
  } = useHistory();
  useEffect(() => {
    if (!checkIfObjectEmpty(shop)) {
      dispatch(deselectShop());
    }
  });
  return (
    <section className="navbar" id={showNavbar ? 'toggled-navbar' : ''}>
      <Link to={slug ? `/shop/${slug}/menu` : '/'} className="nav-logo" />
      <ul className="nav">
        {/* <li className="nav-item dropdown">
          <button type="button" className={`dropbtn ${pathname === `/our-food/order-favorite` ? 'active-nav' : ''}`}>
            ORDER NOW
          </button> */}
        {/* <ul className="dropdown-content">
            <li>
              <Link to="/our-food/order-now">Order Now</Link>
            </li> */}
        {/* <li> */}
        {/*  <NavHashLink smooth to="/our-food/order-favorite#apps"> */}
        {/*    DOWNLOAD APP */}
        {/*  </NavHashLink> */}
        {/* </li> */}
        {/* </ul> */}
        {/* </li> */}
        <li className="nav-item dropdown">
          <Link to="/" className={`dropbtn ${pathname === `/` ? 'active-nav' : ''}`}>
            Home
          </Link>
        </li>
        <li className="nav-item dropdown">
          <Link to="/book-table" className={`dropbtn ${pathname === `/book-table` ? 'active-nav' : ''}`}>
            BOOK TABLE
          </Link>
        </li>
        <li className="nav-item dropdown">
          <a className={`dropbtn ${pathname === `/ou` || pathname === '/our-food/allergens' ? 'active-nav' : ''}`}>
            OUR MENU
          </a>
          <ul className="dropdown-content">
            <li>
              <Link to="/food-menu">FOOD MENU</Link>
            </li>
            <li>
              <Link to="/lunch-menu">LUNCH MENU</Link>
            </li>
            <li>
              <Link to="/drink-menu">DRINK MENU</Link>
            </li>
          </ul>
        </li>
        {/* <li className="nav-item dropdown"> */}
        {/*  <a */}
        {/*    className={`dropbtn ${ */}
        {/*      pathname === `/about` || pathname === '/contact-us' */}
        {/*        ? 'active-nav' */}
        {/*        : '' */}
        {/*    }`} */}
        {/*  > */}
        {/*    ABOUT */}
        {/*  </a> */}
        {/*  <ul className="dropdown-content"> */}
        {/*    <li> */}
        {/*      <Link to="/about">OUR STORY</Link> */}
        {/*    </li> */}
        {/*    <li> */}
        {/*      <NavHashLink smooth to="/about#faqs"> */}
        {/*        CUSTOMER FAQS */}
        {/*      </NavHashLink> */}
        {/*    </li> */}
        {/*    <li> */}
        {/*      <Link to="/contact-us">CONTACT US</Link> */}
        {/*    </li> */}
        {/*  </ul> */}
        {/* </li> */}
        {/* <li className="nav-item dropdown last"> */}
        {/*  <a className={`dropbtn ${pathname === `/jobs` ? 'active-nav' : ''}`}> */}
        {/*    JOBS */}
        {/*  </a> */}
        {/*  <ul className="dropdown-content"> */}
        {/*    <li> */}
        {/*      <Link to="/jobs">IN-STORE JOBS</Link> */}
        {/*    </li> */}
        {/*  </ul> */}
        {/* </li> */}
      </ul>
      {/* <div className="actions">
        <form
          className="search-form"
          onSubmit={() => {
            push(`/find-store/${q}`);
          }}
        >
          <div className="search-label">
            <i className="fas fa-map-marker-alt search-icon" />
            <h4 className="label">FIND YOUR NEAREST STORE</h4>
          </div>
          <input
            className="search-input post-code-form"
            required
            value={q}
            type="text"
            name="postcode"
            placeholder="ENTER YOUR POSTCODE"
            onChange={(e) => setQ(e.target.value)}
          />
          <button className="search-button post-code-form" type="submit">
            GO
          </button>
        </form>
        <div className="search-link">
          <Link to="/find-store">
            <i className="fas fa-map-marker-alt" />
            <h4 className="label">FIND YOUR NEAREST STORE</h4>
          </Link>
        </div>
        <div className="toggle-menu">
          {showNavbar ? (
            <a href="#" className="toggle-nav" onClick={() => setShowNavbar(false)}>
              <i className="fas fa-times" />
            </a>
          ) : (
            <a href="#" className="toggle-nav" onClick={() => setShowNavbar(true)}>
              <i className="fas fa-bars" />
            </a>
          )}
        </div>
      </div> */}
    </section>
  );
};

export default Navbar;
