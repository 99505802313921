import React from 'react';

function InputField({name, label, prefix, required, ...rest}) {
  const l = (name || label).replace('_', ' ');
  return (
    <div className="form-group">
      <p className="label">
        {l} <span>{required ? '*' : null}</span>
      </p>
      <div className="form-input">
        {prefix.length > 0 && <span className="form-prefix">{prefix}</span>}
        <input type="text" name={name} {...rest} placeholder={l} />
      </div>
    </div>
  );
}

InputField.defaultProps = {
  prefix: '',
  label: '',
};
export default InputField;
