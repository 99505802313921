import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {makeSelectToken} from '../Authentication/selectors';
import apiCall from '../../Services/ApiCall';
import apiUrl from '../../Configs/ApiUrl';
import AuthorizedGetHeaders from '../../Configs/AuthorizedGetHeaders';
import FullLoader from '../../Components/FullLoader';
import NotFound404 from '../../Components/404';
import Price from '../../Components/Price';

function Vouchers() {
  const token = useSelector(makeSelectToken());
  const [state, setState] = useState('loading');
  const [status, setStatus] = useState('');
  const [vouchers, setVouchers] = useState([]);
  useEffect(() => {
    apiCall(`${apiUrl}/vouchers`, AuthorizedGetHeaders(token))
      .then((response) => {
        console.log('vouchers', response);
        setVouchers(response);
        setState('success');
      })
      .catch((e) => {
        setState('error');
        setStatus(e.message);
      });
  }, [token]);
  if (state === 'loading') {
    return (
      <div className="w-75 account">
        <h1>VOUCHERS</h1>
        <FullLoader />
      </div>
    );
  }
  if (state === 'error') {
    return (
      <div className="w-75 account">
        <h1>VOUCHERS</h1>
        <p className="alert alert-danger">{status}</p>
      </div>
    );
  }
  if (vouchers.length === 0) {
    return (
      <div className="w-75 account">
        <h1>VOUCHERS</h1>
        <NotFound404
          title="Vouchers not found"
          message="You have no available vouchers at this time. Please check back later."
        />
      </div>
    );
  }
  return (
    <div className="w-75 account">
      <h1>VOUCHERS</h1>
      <table className="vouchers">
        <thead>
          <tr>
            <th>Title</th>
            <th>Type</th>
            <th>Amount</th>
            <th>Total</th>
            <th>Order Status</th>
          </tr>
        </thead>
        <tbody>
          {vouchers.map((voucher) => {
            // console.log('voucher', voucher.total);
            return (
              <tr className="voucher" key={voucher.id}>
                <td>{voucher.title}</td>
                <td>{voucher.type}</td>
                <td>
                  {voucher.type === 'percentage' ? (
                    `${voucher.amount}%`
                  ) : (
                    <Price>{voucher.amount}</Price>
                  )}
                </td>
                <td>
                  {voucher.total ? <Price>{voucher.total}</Price> : 'N/A'}
                </td>
                <td className="text-center btn-cell">
                  <button type="button" className="apply">
                    Apply
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Vouchers;
