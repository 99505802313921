import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import moment from 'moment';
import {Link, useParams} from 'react-router-dom';
import apiCall from '../../Services/ApiCall';
import apiUrl from '../../Configs/ApiUrl';
import AuthorizedGetHeaders from '../../Configs/AuthorizedGetHeaders';
import {makeSelectToken} from '../Authentication/selectors';
import FullLoader from '../../Components/FullLoader';

import Takeaway from '../../Assets/images/take-away.png';
import Delivery from '../../Assets/images/food-delivery.png';
import Price from '../../Components/Price';

function Orders() {
  const {slug} = useParams();
  const token = useSelector(makeSelectToken());
  const [state, setState] = useState('loading');
  const [status, setStatus] = useState('');
  const [orders, setOrders] = useState([]);
  useEffect(() => {
    apiCall(`${apiUrl}/cart/history`, AuthorizedGetHeaders(token))
      .then((response) => {
        setOrders(response || []);
        setState('success');
      })
      .catch((e) => {
        setState('error');
        setStatus(e.message);
      });
  }, [token]);
  if (state === 'loading') {
    return (
      <div className="w-75 account">
        <h1>ORDERS HISTORY</h1>
        <FullLoader />
      </div>
    );
  }
  if (state === 'error') {
    return (
      <div className="w-75 account">
        <h1>ORDERS HISTORY</h1>
        <p className="alert alert-danger">{status}</p>
      </div>
    );
  }
  if (orders.length === 0) {
    return (
      <div className="w-75 account">
        <h1>ORDERS HISTORY</h1>
        <section id="not-found" className="row">
          <h1>Orders not found</h1>
          <p>You have not placed any orders yet.</p>
          <Link to={`/shop/${slug}/menu`}>CLICK HERE TO RETURN TO MENU</Link>
        </section>
      </div>
    );
  }
  return (
    <div className="w-75 account">
      <h1>ORDERS HISTORY</h1>
      <div className="orders">
        {orders.map((order) => (
          <div key={order.id} className="order">
            <div className="booking-info">
              <div className="checkout-mode">
                <img
                  src={order.checkout_mode === 'Delivery' ? Delivery : Takeaway}
                  alt={order.checkout_mode}
                />
              </div>
              <div className="address-details">
                <div className="address">{order.user_address.address}</div>
                <div className="postcode">{order.user_address.postcode}</div>
                <div className="order-id">#{order.unique_id}</div>
              </div>
            </div>
            <div className="payment-info">
              <div className="amount">
                <Price>{order.total_charges}</Price>
              </div>
              <div className="status">
                {order.payment.transaction_status === 'completed'
                  ? 'Payed'
                  : 'Not Payed'}
              </div>
            </div>
            <div className="time-info">
              <p className="status">Order time</p>
              <p className="value">
                {moment(order.created_at).format('YYYY-MM-DD HH:mm')}
              </p>
            </div>
            <div className="payment-method">
              <span>Payment method</span>
              <p>{order.payment.type}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Orders;
