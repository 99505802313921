import firebase from 'firebase/app';
import 'firebase/database';

const config = {
  apiKey: 'AIzaSyCjuFzH6_8m8sOdtLhYE6e9uBTKBQlnySM',
  authDomain: 'izmirbarandgrill.firebaseapp.com',
  databaseURL: 'https://izmirbarandgrill.firebaseio.com',
  projectId: 'izmirbarandgrill',
  storageBucket: 'izmirbarandgrill.appspot.com',
  messagingSenderId: '549232730653',
  appId: '1:549232730653:web:8ce30943df980caac09cf4',
  measurementId: 'G-SQJGYD9Q49',
};

firebase.initializeApp(config);

export default firebase;
