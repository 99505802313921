import React from 'react';
import {Link} from 'react-router-dom';

function ItemsNotFound({title, message, children, condition}) {
  if (condition) {
    return (
      <section id="items-not-found" className="row">
        <h1>{title}</h1>
        <p>{message}</p>
        <Link to="/">CLICK HERE TO RETURN TO HOME</Link>
      </section>
    );
  }
  return children;
}

export default ItemsNotFound;
