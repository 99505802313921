import React from 'react';
import {checkIfObjectEmpty} from '../../Libs/object';

const Products = ({tier, onSelectItem, index, selectedItem, enabled}) => {
  const tierSelected = !checkIfObjectEmpty(selectedItem);
  return (
    <div className={`tier ${tierSelected ? 'tier-selected' : ''}`}>
      {tier.required === 1 && checkIfObjectEmpty(selectedItem) && (
        <div className="required">*</div>
      )}
      <select
        name="item"
        disabled={!enabled}
        value={checkIfObjectEmpty(selectedItem) ? '' : selectedItem.index}
        onChange={(e) =>
          onSelectItem(index, tier.products[e.target.value], {
            id: tier.id,
            title: tier.title,
            type: tier.type,
            basket_title: tier.basket_title,
            type_id: tier.type_id,
          })
        }
      >
        <option value="" disabled>
          {tier.title}
        </option>
        {tier.products.map((item, i) => {
          return (
            <option value={i} key={item.title}>
              {item.title}
            </option>
          );
        })}
      </select>
      {tierSelected && (
        <div className="check-box">
          <i className="fa fa-check-circle" />
        </div>
      )}
    </div>
  );
};

export default Products;
