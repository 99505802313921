import React, {useEffect, useState} from 'react';
import {Redirect, useLocation, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Master from '../Master';
import ShopNavbar from '../../Components/ShopNavbar';
import Steps from './Steps';
import Content from './Content';
import {makeSelectToken} from '../Authentication/selectors';
import {makeSelectMode, makeSelectState} from './selectors';
import {trimSlashes} from '../../Libs/object';
import {clearCheckout} from './actions';
import {makeSelectProducts} from '../Cart/selectors';

function Checkout() {
  const dispatch = useDispatch();
  const {pathname} = useLocation();
  const {goBack} = useHistory();
  const token = useSelector(makeSelectToken());
  const state = useSelector(makeSelectState());
  const products = useSelector(makeSelectProducts());
  const mode = useSelector(makeSelectMode());
  const [step, setStep] = useState('step-1');
  const [done, addToDone] = useState([]);
  useEffect(() => {
    dispatch(clearCheckout());
    if (token.length > 0) {
      setStep('step-2');
      addToDone([...done, 'step-1']);
    }
    if (products.length === 0) {
      goBack();
    }
  }, [token]);
  if (state === 'success' && mode === 'checkout') {
    return <Redirect to={`${trimSlashes(pathname)}/response`} />;
  }
  return (
    <Master navbar={ShopNavbar}>
      <section id="checkout">
        <Steps step={step} done={done} setStep={setStep} />
        <Content
          setStep={setStep}
          step={step}
          done={done}
          addToDone={addToDone}
        />
      </section>
    </Master>
  );
}

export default Checkout;
