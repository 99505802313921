import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import FullLoader from '../../Components/FullLoader';
import InputField from '../../Components/InputField';
import Loadable from '../../Components/Loadable';
import {makeSelectState, makeSelectStatus} from './selectors';
import validateAddAddress from '../../Libs/validateAddAddress';
import {addAddress} from './actions';
import {quantizeAddress, reformatTelephoneNumber} from '../../Libs/profile';

function AddAddress() {
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector(makeSelectState());
  const [aState, setAState] = useState('initial');
  const [addresses, setAddresses] = useState([]);
  const status = useSelector(makeSelectStatus());
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [telephone, setTelephone] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [county, setCounty] = useState('');
  const [postcode, setPostcode] = useState('');
  const [validation, setValidation] = useState('validated');

  const handleAdd = (e) => {
    e.preventDefault();
    const details = {
      first_name: firstName,
      last_name: lastName,
      telephone,
      address,
      city,
      county,
      postcode,
    };
    const validated = validateAddAddress(details);
    setValidation(validated);
    if (validated === 'validated') {
      dispatch(
        addAddress({...details, telephone: reformatTelephoneNumber(telephone)}),
      );
    }
  };

  const searchPostCode = async () => {
    setAddresses([]);
    setAState('loading');
    try {
      const response = await fetch(
        `https://api.getaddress.io/find/${postcode}?api-key=s5r1ZZOZ-kuzBUQ9n_yZqw29840`,
      );
      const json = await response.json();
      if (json.addresses && json.addresses?.length) {
        setAState('success');
        setAddresses(json?.addresses);
      } else {
        setAState('error');
      }
    } catch (e) {
      setAState('error');
      console.log('post code search error', e);
    }
  };

  const selectAddress = (adrs, cty, cnty) => {
    setAddress(adrs);
    setCity(cty);
    setCounty(cnty);
  };

  if (state === 'loading') {
    return (
      <div className="w-75 account">
        <h1>NEW ADDRESS</h1>
        <FullLoader />
      </div>
    );
  }
  return (
    <div className="w-75 account">
      <h1>NEW ADDRESS</h1>
      {/* eslint-disable-next-line no-nested-ternary */}
      {validation !== 'validated' ? (
        <p className="alert alert-danger">{validation}</p>
      ) : state === 'error' ? (
        <p className="alert alert-danger">{status}</p>
      ) : null}
      <form onSubmit={handleAdd}>
        <InputField
          className="w-80"
          name="first_name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
        />
        <InputField
          className="w-80"
          name="last_name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
        />

        <InputField
          className="w-80"
          prefix="+44"
          name="mobile"
          value={telephone}
          onChange={(e) => setTelephone(e.target.value)}
          required
        />
        <div className="seperator">
          <hr />
        </div>
        <div className="row">
          <div className="column">
            <InputField
              className="w-73"
              name="postcode"
              value={postcode}
              onChange={(e) => setPostcode(e.target.value)}
              required
            />
          </div>
          <button type="button" className="secondary" onClick={searchPostCode}>
            <Loadable color="white" active={aState === 'loading'}>
              GET ADDRESSES
            </Loadable>
          </button>
        </div>
        {addresses.length > 0 && (
          <div className="address-suggestions">
            <h2>Please select an address</h2>
            {addresses.map((addressString) => {
              const [adrs, cty, cnty] = quantizeAddress(addressString);
              return (
                <div
                  key={addressString}
                  className={`address-suggestion ${
                    adrs === address ? 'selected' : ''
                  }`}
                  onClick={() => selectAddress(adrs, cty, cnty)}
                >
                  <div className="address">
                    <h5>{adrs}</h5>
                  </div>
                  <div className="other">
                    <div className="other-info">{cty},</div>
                    <div className="other-info">{cnty}</div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div className="actions">
          <button
            type="button"
            className="light"
            onClick={() => history.goBack()}
          >
            BACK TO ACCOUNT
          </button>
          <button type="submit" className="secondary">
            <Loadable active={state === 'loading'} color="white">
              SAVE ADDRESS
            </Loadable>
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddAddress;
