/**
 * Combine all reducers in this file and export the combined reducers.
 */

import {combineReducers} from 'redux-immutable';
import {connectRouter} from 'connected-react-router/immutable';
import menuReducer from '../Pages/Shop/reducer';
import hydrateReducer from './HydrateReducer';
import cartReducer from '../Pages/Cart/reducer';
import {authReducer, userReducer} from '../Pages/Authentication/reducer';
import checkoutReducer from '../Pages/Checkout/reducer';
import responseReducer from '../Pages/Response/reducer';
import profileReducer from '../Pages/Profile/reducer';

export default function createReducer(history) {
  return combineReducers({
    router: connectRouter(history),
    shop: menuReducer,
    hydrate: hydrateReducer,
    cart: cartReducer,
    auth: authReducer,
    checkout: checkoutReducer,
    response: responseReducer,
    user: userReducer,
    profile: profileReducer,
  });
}
