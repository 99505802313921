import React, {useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Master from '../Master';
import ShopNavbar from '../../Components/ShopNavbar';
import Info from './Info';
import CartItem from './CartItem';
import {
  makeSelectCheckoutMode,
  makeSelectProducts,
  makeSelectVoucher,
} from './selectors';
import Price from '../../Components/Price';
import {
  cartTotal,
  getDeliveryCharges,
  getVoucherPrice,
} from '../../Libs/prices';
import {makeSelectShop} from '../Shop/selectors';
import {checkoutAble} from '../../Libs/checkout';
import Status from './Status';
import {addVoucherError, addVoucherSuccess} from './actions';
import apiCall from '../../Services/ApiCall';
import apiUrl from '../../Configs/ApiUrl';
import GetHeaders from '../../Configs/GetHeaders';
import Loadable from '../../Components/Loadable';
import CheckoutMode from '../../Components/CheckoutMode';

function Cart() {
  const {slug} = useParams();
  const dispatch = useDispatch();
  const products = useSelector(makeSelectProducts());
  const shop = useSelector(makeSelectShop());
  const voucher = useSelector(makeSelectVoucher());
  const mode = useSelector(makeSelectCheckoutMode());
  const branch = useSelector(makeSelectShop());
  const [code, setCode] = useState('');
  const [state, setState] = useState('initial');
  const [status, setStatus] = useState('');
  const subTotal = cartTotal(products, mode);
  const discount = getVoucherPrice(voucher, subTotal);
  const deliveryCharges = getDeliveryCharges(
    branch.delivery_operation,
    mode,
    subTotal,
  );
  const total = subTotal + deliveryCharges - discount;
  const shouldCheckout = checkoutAble(shop, total, mode);

  const handleRedeem = (e) => {
    setState('loading');
    setStatus('');
    e.preventDefault();

    apiCall(`${apiUrl}/vouchers/${code}/verify`, GetHeaders)
      .then((v) => {
        if (getVoucherPrice(v, subTotal) === 0) {
          setState('error');
          setStatus('Voucher Not Applicable');
        } else {
          setState('success');
          dispatch(addVoucherSuccess(v));
          setCode('');
        }
      })
      .catch((err) => {
        setState('error');
        dispatch(addVoucherError(err.message));
        setStatus(err.message);
      });
  };
  return (
    <Master navbar={ShopNavbar}>
      <section id="shopping-cart">
        <div className="row">
          <h3>BASKET</h3>
        </div>
        <div className="row">
          <Info />
          <div className="column f2">
            <div className="cart-details">
              <div className="c-head">
                <div className="c-item">&nbsp;</div>
                <div className="c-price">Price</div>
                <div className="c-quantity">Quantity</div>
                <div className="c-subtotal">Sub Total</div>
              </div>
              <div className="c-body">
                {products.length ? (
                  products.map((product, index) => (
                    <CartItem
                      key={product.title + index}
                      product={product}
                      index={index}
                    />
                  ))
                ) : (
                  <div className="alert-danger">
                    <p>Cart is empty</p>
                  </div>
                )}
              </div>
              <div className="c-footer">
                <div className="row">
                  <div className="label">Sub Total</div>
                  <div className="value">
                    <Price>{subTotal}</Price>
                  </div>
                </div>
                <div className="row">
                  <div className="label">Voucher</div>
                  <div className="value">
                    {discount > 0 ? '-' : ''}
                    <Price>{discount}</Price>
                  </div>
                </div>
                <div className="row">
                  <div className="label">
                    Delivery {mode === 'Takeaway' && '(CLICK AND COLLECT)'}
                  </div>
                  <div className="value">
                    {mode === 'Takeaway' ? (
                      'Free'
                    ) : (
                      <Price>{deliveryCharges}</Price>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="label">Total</div>
                  <div className="total">
                    <Price>{total}</Price>
                  </div>
                </div>
              </div>
            </div>
            {state === 'error' && (
              <p className="alert alert-danger">{status}</p>
            )}
            {state === 'success' && (
              <p className="alert alert-success">
                Voucher applied successfully.
              </p>
            )}

            <div className="cart-footer">
              <div className="cart-checkout-mode">
                <CheckoutMode />
              </div>
              <form className="voucher" onSubmit={handleRedeem}>
                <div className="label">VOUCHER</div>
                <input
                  type="text"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  name="voucher"
                />
                <button type="submit" className="action" onClick={handleRedeem}>
                  <Loadable active={state === 'loading'} color="white">
                    REDEEM
                  </Loadable>
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="column">
            <Link to={`/shop/${slug}/menu`} className="continue">
              <h4>CONTINUE SHOPPING</h4>
            </Link>
          </div>
          <Status shouldCheckout={shouldCheckout} />
        </div>
      </section>
    </Master>
  );
}

export default Cart;
