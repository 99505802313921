import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import InputField from '../../Components/InputField';
import {makeSelectDetails} from './selectors';
import {checkIfObjectEmpty} from '../../Libs/object';
import validateCheckoutForm from '../../Libs/validateCheckoutForm';
import {getFirstLastName} from '../../Libs/checkout';
import apiCall from '../../Services/ApiCall';
import apiUrl from '../../Configs/ApiUrl';
import {makeSelectCheckoutMode} from '../Cart/selectors';
import Loadable from '../../Components/Loadable';
import {makeSelectShop} from '../Shop/selectors';
import {
  deformatTelephoneNumber,
  reformatTelephoneNumber,
} from '../../Libs/profile';

function GuestStep2({handleSaveFormData}) {
  const details = useSelector(makeSelectDetails());
  const checkoutMode = useSelector(makeSelectCheckoutMode());
  const shop = useSelector(makeSelectShop());
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [telephone, setTelephone] = useState('');
  const [notes, setNotes] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [county, setCounty] = useState('');
  const [postCode, setPostCode] = useState('');
  const [state, setState] = useState('initial');
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');

  const handleContinue = (evt) => {
    evt.preventDefault();
    const d = {
      first_name: firstName,
      last_name: lastName,
      telephone,
      address,
      city,
      county,
      postcode: postCode,
      email,
      notes,
      user_address_id: -1,
    };
    const isValidated = validateCheckoutForm(d);
    if (isValidated === 'validated') {
      if (checkoutMode === 'Takeaway') {
        handleSaveFormData({
          ...d,
          name: `${firstName} ${lastName}`,
          telephone: reformatTelephoneNumber(telephone),
        });
      } else {
        setState('loading');
        apiCall(
          `${apiUrl}/branches/validateAddress/${shop.id}/${encodeURI(
            `${address}, ${postCode}`,
          )}`,
        )
          .then(() => {
            setState('success');
            handleSaveFormData({
              ...d,
              name: `${firstName} ${lastName}`,
              telephone: reformatTelephoneNumber(telephone),
            });
          })
          .catch((e) => {
            setStatus('Delivery not available on selected address');
            setState('error');
            console.log('validate address', e);
          });
      }
    } else {
      setStatus(isValidated);
    }
  };

  useEffect(() => {
    if (!checkIfObjectEmpty(details)) {
      const [first, last] = getFirstLastName(details.name);
      setFirstName(first || '');
      setLastName(last || '');
      setTelephone(deformatTelephoneNumber(details.telephone) || '');
      setAddress(details.address || '');
      setCity(details.city || '');
      setCounty(details.county || '');
      setPostCode(details.postcode || '');
      setEmail(details.email || '');
      setNotes(details.notes || '');
    }
  }, []);
  return (
    <div className="step-2">
      <div className="row">
        <div className="header">
          <h3>ADDRESS DETAILS</h3>
        </div>
      </div>
      {status.length ? (
        <div className="row">
          <p className="alert alert-danger w-100">{status}</p>
        </div>
      ) : null}
      <form className="column" onSubmit={handleSaveFormData}>
        <div className="row">
          <div className="column">
            <InputField
              name="first_name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>
          <div className="column">
            <InputField
              name="last_name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="column">
            <InputField
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <div className="separator">
              <hr />
            </div>
          </div>
          <div className="column">
            <InputField
              name="Mobile"
              prefix="+44"
              value={telephone}
              onChange={(e) => setTelephone(e.target.value)}
              required
            />
            <div className="separator">
              <hr />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="column">
            <InputField
              name="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
            <InputField
              name="city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
            />
            <InputField
              name="county"
              value={county}
              onChange={(e) => setCounty(e.target.value)}
              required
            />
            <InputField
              name="post_code"
              value={postCode}
              onChange={(e) => setPostCode(e.target.value)}
              required
            />
          </div>
          <div className="column">
            <div className="instructions">
              <strong>Order Instructions</strong>
              <textarea
                name="notes"
                cols="30"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                rows="7"
              />
            </div>
            <div className="row">
              <button
                className="continue"
                type="submit"
                onClick={handleContinue}
              >
                <Loadable color="white" active={state === 'loading'}>
                  CONTINUE
                </Loadable>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default GuestStep2;
