export const quantizeAddress = (address) => {
  const addressComponents = address.split(',');
  return [
    addressComponents[0],
    addressComponents[addressComponents.length - 2],
    addressComponents[addressComponents.length - 1],
  ];
};

export const reformatTelephoneNumber = (telephone) => {
  if (telephone.startsWith('0')) {
    return `0044${telephone.substring(1).replaceAll(' ', '')}`;
  }
  return `0044${telephone.replaceAll(' ', '')}`;
};

export const deformatTelephoneNumber = (telephone) => {
  if (telephone.startsWith('0044')) {
    return telephone.substring(4);
  }
  return telephone;
};
