import React from 'react';
import {useSelector} from 'react-redux';
import {getProperPrice, priceByMode} from '../../Libs/prices';
import {checkIfObjectEmpty} from '../../Libs/object';
import {makeSelectCheckoutMode} from '../Cart/selectors';

const Options = ({
  tier,
  onSelectOption,
  index,
  size,
  selectedOption,
  enabled,
}) => {
  const mode = useSelector(makeSelectCheckoutMode());
  const tierSelected = !checkIfObjectEmpty(selectedOption);
  return (
    <div className={`tier ${tierSelected ? 'tier-selected' : ''}`}>
      {tier.required === 1 && checkIfObjectEmpty(selectedOption) && (
        <div className="required">*</div>
      )}
      <select
        name="item"
        disabled={!enabled}
        value={checkIfObjectEmpty(selectedOption) ? '' : selectedOption.index}
        onChange={(e) =>
          onSelectOption(index, tier.options[e.target.value], {
            id: tier.id,
            title: tier.title,
            type: tier.type,
            basket_title: tier.basket_title,
            type_id: tier.type_id,
            index: e.target.value,
          })
        }
      >
        <option value="" disabled>
          {tier.title}
        </option>
        {tier.options.map((option, i) => {
          const prices = getProperPrice(option, size);
          const itemPrice = priceByMode(prices, mode);
          return (
            <option value={i} key={option.title}>
              {option.title} {itemPrice === 0 ? '' : `+£${itemPrice / 100}`}
            </option>
          );
        })}
      </select>
      {tierSelected && (
        <div className="check-box">
          <i className="fa fa-check-circle" />
        </div>
      )}
    </div>
  );
};

export default Options;
