export const LOGIN = 'Favorite/Authenticate/LOGIN';

export const LOGIN_SUCCESS = 'Favorite/Authenticate/LOGIN_SUCCESS';

export const LOGIN_ERROR = 'Favorite/Authenticate/LOGIN_ERROR';

export const REGISTER = 'Favorite/Authenticate/REGISTER';

export const REGISTER_SUCCESS = 'Favorite/Authenticate/REGISTER_SUCCESS';

export const REGISTER_ERROR = 'Favorite/Authenticate/REGISTER_ERROR';

export const LOGOUT = 'Favorite/Authenticate/LOGOUT';

export const LOGOUT_SUCCESS = 'Favorite/Authenticate/LOGOUT_SUCCESS';

export const LOGOUT_ERROR = 'Favorite/Authenticate/LOGOUT_ERROR';

export const FORGOT_PASSWORD = 'Favorite/Authenticate/FORGOT_PASSWORD';

export const FORGOT_PASSWORD_SUCCESS =
  'Favorite/Authenticate/FORGOT_PASSWORD_SUCCESS';

export const FORGOT_PASSWORD_ERROR =
  'Favorite/Authenticate/FORGOT_PASSWORD_ERROR';

export const RESET_PASSWORD = 'Favorite/Authenticate/RESET_PASSWORD';

export const RESET_PASSWORD_SUCCESS =
  'Favorite/Authenticate/RESET_PASSWORD_SUCCESS';

export const RESET_PASSWORD_ERROR =
  'Favorite/Authenticate/RESET_PASSWORD_ERROR';

export const CLEAR_USER = 'Favorite/Authenticate/CLEAR_USER';
