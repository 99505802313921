import React from 'react';

function Status({state, status, mode}) {
  if (state === 'error') {
    return <p className="alert alert-danger">{status}</p>;
  }
  if (mode === 'reset' && state === 'success') {
    return (
      <p className="alert alert-info">Reset request submitted successfully</p>
    );
  }
  if (mode === 'done' && state === 'success') {
    return (
      <p className="alert alert-info">
        Password reset successfully. Please login in to continue.
      </p>
    );
  }
  return null;
}

export default Status;
