import React from 'react';
import {Link, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Price from '../../Components/Price';
import {makeSelectCheckoutMode} from '../Cart/selectors';
import {onlyPriceWithDiscount} from '../../Libs/prices';
import OriginalPrice from '../../Components/OriginalPrice';

function Item({item}) {
  const {slug} = useParams();
  const mode = useSelector(makeSelectCheckoutMode());
  if (item.product_status === 'offline') {
    return null;
  }
  if (item.visible_in === mode.toLowerCase() || item.visible_in === 'both') {
    return (
      <div className="item">
        <div className="info">
          <Link to={`/shop/${slug}/product/${item.slug}`} className="title">
            {item.title}
          </Link>
          <p className="description">{item.description}</p>
          <div className="price-wrapper">
            <OriginalPrice product={item} />
            <p className="price">
              <Price>{onlyPriceWithDiscount(item, mode)}</Price>
            </p>
          </div>
        </div>
        <div className="action">
          <Link to={`/shop/${slug}/product/${item.slug}`}>
            <i className="fas fa-plus" />
            VIEW
          </Link>
        </div>
      </div>
    );
  }
  return null;
}

export default Item;
