import React from 'react';

function Price({children}) {
  if (parseInt(children, 10) === 0) {
    return null;
  }
  return <span>£{parseFloat(children / 100).toFixed(2)}</span>;
}

export default Price;
