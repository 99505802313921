export const storeStatus = (shop, mode) => {
  if (mode === 'Takeaway') {
    return shop.takeaway_timings === 'Open' && shop.takeaway === 1 && shop.branch_status === 'online';
  }
  if (mode === 'Delivery') {
    return shop.delivery_timings === 'Open' && shop.delivery === 1 && shop.branch_status === 'online';
  }
  return false;
};

export const storeAvailability = (shop, mode) => {
  if (mode === 'Takeaway') {
    return shop.takeaway_timings === 'Open' && shop.branch_status === 'online';
  }
  if (mode === 'Delivery') {
    return shop.delivery_timings === 'Open' && shop.branch_status === 'online';
  }
  return false;
};
