export const checkoutAble = (shop, total, mode) => {
  if (mode === 'Delivery') {
    if (total >= shop.min_delivery_order) {
      return true;
    }
    return false;
  }
  if (mode === 'Takeaway') {
    if (total >= shop.min_delivery_order) {
      return true;
    }
    return false;
  }
  return false;
};

export const getFirstLastName = (name) => name.split(' ');
