import {all, fork} from 'redux-saga/effects';
import menuSaga from '../Pages/Shop/saga';
import authSaga from '../Pages/Authentication/saga';
import syncCartSaga from '../Pages/Checkout/saga';
import paySaga from '../Pages/Response/saga';
import profileSaga from '../Pages/Profile/saga';
import addVoucherSaga from '../Pages/Cart/saga';

export default function* rootSaga() {
  yield all([
    fork(menuSaga),
    fork(authSaga),
    fork(syncCartSaga),
    fork(paySaga),
    fork(profileSaga),
    fork(addVoucherSaga),
  ]);
}
