import React from 'react';
import Loader from 'react-loaders';

function Loadable({children, active, ...rest}) {
  if (active) {
    return <Loader type="line-scale" active {...rest} />;
  }
  return children;
}

export default Loadable;
