import React from 'react';
import {checkIfObjectEmpty} from '../../Libs/object';

function Modal({product, setProduct}) {
  const handleClose = (e) => {
    e.preventDefault();
    if (e.target.classList.contains('modal')) {
      setProduct({});
    }
  };
  if (checkIfObjectEmpty(product)) {
    return null;
  }
  return (
    <div className="modal" onClick={handleClose}>
      <div className="content">
        <div className="header">
          <h2>{product.title}</h2>
          <span className="remove" onClick={() => setProduct({})}>
            x
          </span>
        </div>
        <div className="body">
          <div className="img">
            <img src={product.image} alt="" />
          </div>
          <div className="details">
            <p>{product.description}</p>
            <h3>ALLERGEN KEYS</h3>
            {product.allergens.map((allergen) => (
              <div key={allergen.id} className="allergen">
                <img src={allergen.image} alt={allergen.title} />
                <p>{allergen.title}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
