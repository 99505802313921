import React from 'react';

function TodayTakeawayStatus({shop}) {
  return (
    <div className="operation">
      <h3>Click & Collect</h3>
      <p>{shop.takeaway === 1 ? 'Yes' : 'No'}</p>
      <span>({shop.takeaway_time_taken} minutes)</span>
    </div>
  );
}

export default TodayTakeawayStatus;
