import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {makeSelectHydrated} from '../Redux/selectors';
import FullLoader from './FullLoader';

function Hydrated({hydrated, children}) {
  if (hydrated) {
    return children;
  }
  return <FullLoader />;
}

const mapStateToProps = createStructuredSelector({
  hydrated: makeSelectHydrated(),
});

export default connect(mapStateToProps)(Hydrated);
