import React from 'react';
import StoreSummary from '../Pages/Shop/StoreSummary';
import MenuItems from '../Pages/Shop/MenuItems';
import CheckoutMode from './CheckoutMode';

function Items() {
  return (
    <div className="items">
      <StoreSummary />
      <div className="toggle-checkout">
        <div className="checkout-wrapper">
          <CheckoutMode />
        </div>
      </div>
      <MenuItems />
    </div>
  );
}

export default Items;
