import {createSelector} from 'reselect';

export const selectProfile = (state) => state.get('profile');

export const makeSelectState = () =>
  createSelector(selectProfile, (ProfileState) => ProfileState.get('state'));

export const makeSelectMode = () =>
  createSelector(selectProfile, (ProfileState) => ProfileState.get('mode'));

export const makeSelectStatus = () =>
  createSelector(selectProfile, (ProfileState) => ProfileState.get('status'));
