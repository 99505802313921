import React from 'react';
import Master from '../Master';
import Hero from '../../Components/Hero';
// import Suggestions from '../../Components/Suggestions';
// import Offers from '../../Components/Offers';
import Navbar from '../../Components/Navbar';
// import Message from './Message';

const Home = () => {
  return (
    <Master navbar={Navbar}>
      <section id="home">
        {/* <Message */}
        {/*  message="NOTICE!" */}
        {/*  description="We are not delivering  orders until further notice (only dine-in and collection are available)." */}
        {/*  thanks="Sorry for any inconvenience, Thank You" */}
        {/* /> */}
        <Hero />
        {/* <Suggestions /> */}
        {/* <Offers /> */}
      </section>
    </Master>
  );
};

export default Home;
