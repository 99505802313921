import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import apiCall from '../../Services/ApiCall';
import apiUrl from '../../Configs/ApiUrl';
import {makeSelectShop} from '../Shop/selectors';
import Loadable from '../../Components/Loadable';
import {makeSelectCheckoutMode} from '../Cart/selectors';

function UserAddress({
  address,
  setSelectedAddress,
  selectedAddress,
  setStatus,
}) {
  const shop = useSelector(makeSelectShop());
  const checkoutMode = useSelector(makeSelectCheckoutMode());
  const [state, setState] = useState('initial');
  const handleSelectAddress = (a) => {
    if (checkoutMode === 'Takeaway') {
      setSelectedAddress(a);
      return;
    }
    setState('loading');
    setStatus('');
    apiCall(
      `${apiUrl}/branches/validateAddress/${shop.id}/${encodeURI(
        `${a.address}, ${a.postcode}`,
      )}`,
    )
      .then(() => {
        setState('success');
        setSelectedAddress(a);
      })
      .catch((e) => {
        setStatus('Delivery not available on selected address');
        setState('error');
        console.log('validate address', e);
      });
  };
  return (
    <div
      className={`address-suggestion ${
        selectedAddress.address === address.address ? 'selected' : ''
      }`}
      onClick={() => handleSelectAddress(address)}
    >
      {state === 'loading' && (
        <div className="validating">
          <Loadable active />
        </div>
      )}
      <h4 className="name">{address.name}</h4>
      <p className="address">{address.address}</p>
      <p className="other">
        <span className="postcode">{address.postcode}</span>,&nbsp;{' '}
        <span className="town">{address.town}</span>
      </p>
    </div>
  );
}

export default UserAddress;
