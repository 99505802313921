import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {makeSelectToken} from '../Authentication/selectors';
import GuestStep2 from './GuestStep2';
import AuthenticatedStep2 from './AuthenticatedStep2';
import {saveFromData} from './actions';

function Step2({setStep, addToDone, done}) {
  const dispatch = useDispatch();
  const token = useSelector(makeSelectToken());
  const handleSaveFormData = (details) => {
    dispatch(saveFromData(details));
    addToDone([...done, 'step-2']);
    setStep('step-3');
  };
  if (token.length === 0) {
    return (
      <div className="step-2">
        <GuestStep2 handleSaveFormData={handleSaveFormData} />
      </div>
    );
  }
  return (
    <div className="step-2">
      <AuthenticatedStep2 handleSaveFormData={handleSaveFormData} />
    </div>
  );
}

export default Step2;
