import {fromJS} from 'immutable';

import {
  EDIT_PASSWORD_ERROR,
  EDIT_EMAIL_ERROR,
  EDIT_EMAIL_SUCCESS,
  EDIT_EMAIL,
  EDIT_PASSWORD_SUCCESS,
  EDIT_PASSWORD,
  EDIT_PROFILE_ERROR,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE,
  ADD_ADDRESS,
  ADD_ADDRESS_SUCCESS,
  ADD_ADDRESS_ERROR,
  EDIT_ADDRESS,
  EDIT_ADDRESS_SUCCESS,
  EDIT_ADDRESS_ERROR,
  REMOVE_ADDRESS_SUCCESS,
  REMOVE_ADDRESS_ERROR,
  REMOVE_ADDRESS,
  MAKE_DEFAULT,
  MAKE_DEFAULT_SUCCESS,
  MAKE_DEFAULT_ERROR,
} from './constants';

const initialState = fromJS({
  state: 'initial',
  status: 'initial',
  mode: 'initial',
});

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case EDIT_EMAIL:
      return state
        .set('mode', 'email')
        .set('status', '')
        .set('state', 'loading');
    case EDIT_EMAIL_SUCCESS:
      return state.set('state', 'success');
    case EDIT_EMAIL_ERROR:
      return state.set('state', 'error').set('status', action.status);
    case EDIT_PROFILE:
      return state
        .set('mode', 'profile')
        .set('status', '')
        .set('state', 'loading');
    case EDIT_PROFILE_SUCCESS:
      return state.set('state', 'success');
    case EDIT_PROFILE_ERROR:
      return state.set('state', 'error').set('status', action.status);
    case EDIT_PASSWORD:
      return state
        .set('state', 'loading')
        .set('mode', 'password')
        .set('status', '');
    case EDIT_PASSWORD_SUCCESS:
      return state.set('state', 'success');
    case EDIT_PASSWORD_ERROR:
      return state.set('state', 'error').set('status', action.status);
    case ADD_ADDRESS:
      return state
        .set('state', 'loading')
        .set('mode', 'add-address')
        .set('status', '');
    case ADD_ADDRESS_SUCCESS:
      return state.set('state', 'success');
    case ADD_ADDRESS_ERROR:
      return state.set('state', 'error').set('status', action.status);
    case EDIT_ADDRESS:
      return state
        .set('state', 'loading')
        .set('mode', 'edit-address')
        .set('status', '');
    case EDIT_ADDRESS_SUCCESS:
      return state.set('state', 'success');
    case EDIT_ADDRESS_ERROR:
      return state.set('state', 'error').set('status', action.status);
    case REMOVE_ADDRESS:
      return state
        .set('state', 'loading')
        .set('mode', 'remove-address')
        .set('status', '');
    case REMOVE_ADDRESS_SUCCESS:
      return state.set('state', 'success');
    case REMOVE_ADDRESS_ERROR:
      return state.set('state', 'error').set('status', action.status);
    case MAKE_DEFAULT:
      return state
        .set('state', 'loading')
        .set('mode', 'make-default')
        .set('status', '');
    case MAKE_DEFAULT_SUCCESS:
      return state.set('state', 'success');
    case MAKE_DEFAULT_ERROR:
      return state.set('state', 'error').set('status', action.status);
    default:
      return state;
  }
}
