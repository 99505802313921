import React from 'react';
import {useParams} from 'react-router-dom';

import Items from '../../Components/Items';
import SideCart from '../../Components/SideCart';
import Master from '../Master';
import ShopNavbar from '../../Components/ShopNavbar';

function FindItem() {
  const {q} = useParams();
  return (
    <Master navbar={ShopNavbar}>
      <section id="find-item">
        <div className="row">
          <div className="head">
            <h3>
              Search results for : <span className="text-primary">{q}</span>
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="w-75">
            <Items />
          </div>
          <div className="w-25">
            <SideCart />
          </div>
        </div>
      </section>
    </Master>
  );
}

export default FindItem;
