import {createSelector} from 'reselect';

export const selectShop = (state) => state.get('shop');

export const makeSelectMenu = () =>
  createSelector(selectShop, (ShopState) => ShopState.get('menu').toJS());

export const makeSelectState = () =>
  createSelector(selectShop, (ShopState) => ShopState.get('state'));

export const makeSelectShop = () =>
  createSelector(selectShop, (ShopState) => ShopState.get('shop').toJS());
