import React, {useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {useDispatch} from 'react-redux';
import Loadable from '../../Components/Loadable';
import {checkout} from '../Checkout/actions';

const PaymentForm = ({secret, setShowCard, showCard, total}) => {
  const {slug} = useParams();
  const dispatch = useDispatch();
  const {push} = useHistory();
  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();

  const stripe = useStripe();
  const elements = useElements();

  const handleFormSubmit = async (ev) => {
    ev.preventDefault();

    setProcessingTo(true);

    const cardElement = elements.getElement('card');

    try {
      const paymentMethodReq = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setProcessingTo(false);
        return;
      }
      if (paymentMethodReq.paymentMethod.id) {
        dispatch(
          checkout(
            {
              payment_mode: 'with card',
              payment_amount: total,
            },
            paymentMethodReq,
            secret,
          ),
        );
        push(`/shop/${slug}/checkout/response`);
      }
    } catch (err) {
      setCheckoutError(err.message);
    }
  };
  const iframeStyles = {
    base: {
      border: '1px solid',
      color: '#000',
      fontSize: '16px',
      iconColor: '#000',
      '::placeholder': {
        color: '#000',
      },
    },
    invalid: {
      iconColor: 'red',
      color: 'red',
    },
    complete: {
      iconColor: 'green',
    },
  };

  const cardElementOpts = {
    iconStyle: 'solid',
    style: iframeStyles,
    hidePostalCode: true,
  };

  if (showCard === false) {
    return (
      <button
        type="button"
        className="method with-card"
        onClick={() => setShowCard(true)}
      >
        <Loadable color="white" active={secret.length === 0}>
          With Card
        </Loadable>
      </button>
    );
  }

  return (
    <form className="form-with-card" onSubmit={handleFormSubmit}>
      <div className="card-element">
        <CardElement options={cardElementOpts} />
      </div>
      {checkoutError && <span>{checkoutError}</span>}
      <button
        type="submit"
        className="method card-button"
        disabled={isProcessing || !stripe}
      >
        <Loadable color="white" active={isProcessing}>
          Pay
        </Loadable>
      </button>
    </form>
  );
};

export default PaymentForm;
