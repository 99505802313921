import React, {useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';

import Master from '../Master';
import Navbar from '../../Components/Navbar';
import Header from '../../Components/Header';
import foodMenu from '../../Assets/pdf/drinkMenu.pdf';

function FoodMenu() {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({numPages}) {
    setNumPages(numPages);
  }
  return (
    <Master navbar={Navbar}>
      <Header title="DRINK MENU" />
      <section id="our-food">
        <div className="row">
          <h1 style={{textAlign: 'center'}}>DRINK MENU</h1>
        </div>

        <div className="row">
          <div className="column">
            <Document file={foodMenu} options={{workerSrc: '/pdf.worker.js'}} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          </div>
        </div>
      </section>
    </Master>
  );
}

export default FoodMenu;
