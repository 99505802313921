import React from 'react';
import {useSelector} from 'react-redux';
import {getProperPrice, priceByMode} from '../../Libs/prices';
import {getAddonQuantity, getSelectedAddonQuantity} from '../../Libs/product';
import {makeSelectCheckoutMode} from '../Cart/selectors';

function Addons({
  tier,
  onSelectAddons,
  index,
  size,
  selectedAddons = [],
  changeAddonQuantity,
  enabled,
}) {
  const mode = useSelector(makeSelectCheckoutMode());
  const tierSelected = getAddonQuantity(selectedAddons) > 0;
  return (
    <div className={`tier ${tierSelected ? 'tier-selected' : ''}`}>
      {!enabled && <div className="tier-disabled" />}
      {tier.required === 1 && <div className="required">*</div>}
      <div className="addons">
        <div className="header">
          <div className="title">{tier.title}</div>
          <div className="max-addons">
            <p>Max Allowed</p>
            <strong>{tier.max_items}</strong>
          </div>
          <div className="free-addons">
            <p>Free Allowed</p>
            <strong>{tier.free_items}</strong>
          </div>
          {tierSelected && (
            <div className="check-box">
              <i className="fa fa-check-circle" />
            </div>
          )}
        </div>
        <div className="body">
          {tier.addons?.map((addon, i) => {
            const prices = getProperPrice(addon, size);
            const itemPrice = priceByMode(prices, mode);
            const freeAddons = getSelectedAddonQuantity(
              selectedAddons.free,
              addon.id,
            );
            const paidAddons = getSelectedAddonQuantity(
              selectedAddons.paid,
              addon.id,
            );
            return (
              <div className="addon" key={i}>
                {freeAddons > 0 && (
                  <span className="free-quantity">
                    {getSelectedAddonQuantity(selectedAddons.free, addon.id)}
                    &nbsp;
                  </span>
                )}
                {paidAddons > 0 && (
                  <span className="paid-quantity">
                    {getSelectedAddonQuantity(selectedAddons.paid, addon.id)}
                    &nbsp;
                  </span>
                )}
                <span
                  className="addon-title"
                  onClick={() =>
                    onSelectAddons(index, addon, {
                      id: tier.id,
                      title: tier.title,
                      type: tier.type,
                      basket_title: tier.basket_title,
                      type_id: tier.type_id,
                      index: i,
                      free_addons: tier.free_items,
                      max_addons: tier.max_items,
                    })
                  }
                >
                  <span>{addon.title}</span>{' '}
                  <strong>
                    {itemPrice === 0 ? '' : `+£${itemPrice / 100}`}
                  </strong>
                </span>
                {(freeAddons > 0 || paidAddons > 0) && (
                  <span
                    className="remove-addon"
                    onClick={() =>
                      changeAddonQuantity(
                        index,
                        paidAddons > 0 ? 'paid' : 'free',
                        addon.id,
                        paidAddons > 0 ? paidAddons - 1 : freeAddons - 1,
                      )
                    }
                  >
                    <i className="fa fa-trash" />
                  </span>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Addons;
