import React, {useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';

import Store from './Store';
import FullLoader from '../../Components/FullLoader';

const Stores = ({stores: [nearest, ...rest], loading}) => {
  const {postcode} = useParams();
  const [q, setQ] = useState(postcode || '');
  const history = useHistory();
  return (
    <div id="stores">
      <h1 className="title">STORE FINDER</h1>
      {loading ? (
        <FullLoader />
      ) : (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              history.push(`/find-store/${e.target[0].value}`);
            }}
          >
            <p>FIND YOUR NEAREST STORE</p>
            <div className="form-group">
              <input
                type="text"
                value={q}
                onChange={(e) => setQ(e.target.value)}
              />
              <button type="submit">FIND STORE</button>
            </div>
          </form>
          {nearest ? (
            <>
              <h1 className="nearest-title">YOUR NEAREST FAVORITE STORE</h1>
              <Store store={nearest} />
              <h1 className="others-title">
                OTHER FAVORITE STORES IN YOUR AREA
              </h1>
              <p className="note">(DELIVERY NOT GUARANTEED AT YOUR LOCATION)</p>
              {rest.map((store) => (
                <Store store={store} key={store.slug} />
              ))}
            </>
          ) : (
            <h1 className="not-found">STORES NOT FOUND</h1>
          )}
        </>
      )}
    </div>
  );
};

export default Stores;
