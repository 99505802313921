export const ADD_TO_CART = 'Favorite/Cart/ADD_TO_CART';

export const REMOVE_FROM_CART = 'Favorite/Cart/REMOVE_FROM_CART';

export const CHANGE_QUANTITY = 'Favorite/Cart/CHANGE_QUANTITY';

export const EMPTY_CART = 'Favorite/Cart/EMPTY_CART';

export const CHANGE_CHECKOUT_MODE = 'Favorite/Cart/CHANGE_CHECKOUT_MODE';

export const ADD_VOUCHER = 'Favorite/Cart/ADD_VOUCHER';

export const ADD_VOUCHER_SUCCESS = 'Favorite/Cart/ADD_VOUCHER_SUCCESS';

export const ADD_VOUCHER_ERROR = 'Favorite/Cart/ADD_VOUCHER_ERROR';

export const BACK_TO_CART = 'Favorite/Cart/BACK_TO_CART';

export const CLEAR_VOUCHER = 'Favorite/Cart/CLEAR_VOUCHER';
