import React, {Component} from 'react';
import {withRouter, Redirect} from 'react-router-dom';

import Master from '../Master';
import ShopNavbar from '../../Components/ShopNavbar';
import MenuSummary from '../../Components/MenuSummary';
import Items from '../../Components/Items';
import SideCart from '../../Components/SideCart';
import FullLoader from '../../Components/FullLoader';
import NotFound404 from '../../Components/404';
import {checkIfObjectEmpty} from '../../Libs/object';

class Shop extends Component {
  componentDidMount() {
    const {
      onFetchMenu,
      onFetchShop,
      match: {
        params: {slug},
      },
    } = this.props;
    onFetchMenu(slug);
    onFetchShop(slug);
  }

  render() {
    const {menu, shop, state} = this.props;
    return (
      <NotFound404
        title="Shop Not Found"
        message="Sorry, the shop you requested could not be found or is no longer available."
        condition={state === 'error' || (state === 'success' && checkIfObjectEmpty(shop))}
      >
        <Master navbar={ShopNavbar}>
          <section className="clearfix" id="shop">
            <div className="row w-75">
              <div className="menu-summary w-33">
                {state !== 'success' && menu.length === 0 ? <FullLoader active /> : <MenuSummary />}
              </div>
              <div className="shop-items w-66">
                {state !== 'success' && menu.length === 0 ? <FullLoader active /> : <Items />}
              </div>
            </div>
            <div className="shop-side-cart w-25">
              <SideCart />
            </div>
          </section>
        </Master>
      </NotFound404>
    );
  }
}

export default withRouter(Shop);
