import React from 'react';
import {useSelector} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import {makeSelectShop} from '../Shop/selectors';
import {makeSelectCheckoutMode} from './selectors';
import {storeStatus} from '../../Libs/store';

function Status({shouldCheckout}) {
  const {slug} = useParams();
  const shop = useSelector(makeSelectShop());
  const mode = useSelector(makeSelectCheckoutMode());
  const min =
    mode === 'Delivery' ? shop.min_delivery_order : shop.min_takeaway_order;
  const shopStatus = storeStatus(shop, mode);
  if (shouldCheckout && shopStatus) {
    return (
      <div className="column f-end">
        <Link className="checkout" to={`/shop/${slug}/checkout`}>
          <h4>PROCEED TO CHECKOUT</h4>
        </Link>
      </div>
    );
  }
  return (
    <div className="disabled">
      {shopStatus === false ? (
        <p className="alert alert-danger">{`${
          mode === 'Delivery' ? mode : 'Collection'
        } is offline right now.`}</p>
      ) : (
        <p className="alert alert-danger">{`${
          mode === 'Delivery' ? mode : 'Collection'
        } order should be at least £${min / 100}`}</p>
      )}
      <button type="button">
        <h4>PROCEED TO CHECKOUT</h4>
      </button>
    </div>
  );
}

export default Status;
