import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import Master from '../Master';
import Navbar from '../../Components/Navbar';
import Header from '../../Components/Header';
import InputField from '../../Components/InputField';

function Contact() {
  return (
    <Master navbar={Navbar}>
      <Header title="Contact Us" />
      <section id="contact-us">
        <div className="hero">
          <h1>CONTACT FAVORITE</h1>
          <p>We welcome your feedback.</p>
          <p>
            If you have any questions or queries regarding Favorite please
            contact us via our contact form or the details below.
          </p>
        </div>
        <div className="row">
          <div className="column info f-2">
            <div className="row">
              <div className="column f-1">
                <h3>HEAD OFFICE</h3>
              </div>
              <div className="column f-2">
                <p>
                  Favorite Fried Chicken Limited, 7 Davy Road, Clacton-on-Sea,
                  Essex, CO15 4XD, United Kingdom
                </p>
              </div>
            </div>
            <div className="row">
              <div className="column f-1">
                <h3>TELEPHONE</h3>
              </div>
              <div className="column f-2">
                <p>01255 222568</p>
              </div>
            </div>
          </div>
          <form className="column f-3">
            <InputField name="name" required />
            <InputField name="email" required />
            <div className="form-group">
              <p className="label">Type of Enquiry</p>
              <div className="radio">
                <input type="radio" id="general" name="type" value="general" />
                <label htmlFor="general">General Feedback</label>
                <input
                  type="radio"
                  id="customer"
                  name="type"
                  value="customer"
                />
                <label htmlFor="customer">Customer Feedback</label>
              </div>
            </div>
            <div className="form-group">
              <div className="label">Message</div>
              <textarea rows={4} />
            </div>
            <div className="recaptcha">
              <div className="label" />
              <ReCAPTCHA
                sitekey="6Lf129sZAAAAAF7OZOXgq8ZRLXKwhPXXNl03j3Oz"
                onChange={() => false}
              />
            </div>
            <div className="footer">
              <p>
                <span className="text-primary">*</span>
                <span>Denotes mandatory fields</span>
              </p>
              <button type="submit">SEND</button>
            </div>
          </form>
        </div>
      </section>
    </Master>
  );
}

export default Contact;
