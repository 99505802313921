import React from 'react';
import {useSelector} from 'react-redux';
import {makeSelectToken} from '../Authentication/selectors';

function Steps({step, done, setStep}) {
  const token = useSelector(makeSelectToken());
  const handleClick = (s, clickable) => {
    if (!(s === 'step-1' && token.length)) {
      if (clickable) {
        setStep(s);
      }
    }
  };
  return (
    <div className="steps">
      <a
        onClick={() => handleClick('step-1', done.includes('step-1'))}
        className={`step-1 ${step === 'step-1' ? 'active' : ''} ${
          done.includes('step-1') ? 'done' : ''
        }`}
      >
        <p>STEP 1</p>
        <h4>LOGIN/REGISTER</h4>
      </a>
      <a
        onClick={() => handleClick('step-2', done.includes('step-2'))}
        className={`step-2 ${step === 'step-2' ? 'active' : ''} ${
          done.includes('step-2') ? 'done' : ''
        }`}
      >
        <p>STEP 2</p>
        <h4>ADDRESS DETAILS</h4>
      </a>
      <a
        onClick={() => handleClick('step-1', done.includes('step-3'))}
        className={`step-3 ${step === 'step-3' ? 'active' : ''} ${
          done.includes('step-3') ? 'done' : ''
        }`}
      >
        <p>STEP 3</p>
        <h4>REVIEW</h4>
      </a>
    </div>
  );
}

export default Steps;
