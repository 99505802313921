import React from 'react';
import {useSelector} from 'react-redux';
import {makeSelectShop} from './selectors';
import TodayStoreOperation from './TodayStoreOperation';
import TodayDeliveryOperation from './TodayDeliveryOperation';
import TodayTakeawayStatus from './TodayTakeawayStatus';
import TodayDeliveryStatus from './TodayDeliveryStatus';

function StoreSummary() {
  const shop = useSelector(makeSelectShop());
  return (
    <div className="store">
      <div className="info">
        <div className="title">
          <h1>{shop.title}</h1>
          <span>/{shop.locality}</span>
        </div>
        <div className="address">
          {shop.address}, {shop.zipcode}
        </div>
      </div>
      <div className="operations">
        <TodayStoreOperation operation={shop.schedule} status={shop.schedule} />
        <TodayDeliveryOperation operation={shop.delivery_schedule} status={shop.delivery} />
        <TodayTakeawayStatus shop={shop} />
        <TodayDeliveryStatus shop={shop} />
      </div>
    </div>
  );
}

export default StoreSummary;
