import React from 'react';
import {Link, useParams} from 'react-router-dom';

function DefaultAddress({address}) {
  const {slug} = useParams();
  return (
    <div className="default">
      <div className="header">
        <h2>DEFAULT ADDRESS</h2>
        <div className="actions">
          <Link to={`/shop/${slug}/profile/address/edit/${address.id}`}>
            EDIT
          </Link>
        </div>
      </div>
      <div className="body">
        <p>
          <strong>{address.name}</strong>
        </p>
        <p>{address.address}</p>
        <p>{address.town}</p>
        <p>{address.county}</p>
        <p>{address.postcode}</p>
        <p>United Kingdom</p>
      </div>
    </div>
  );
}

export default DefaultAddress;
