import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import Master from '../Master';
import Stores from './Stores';
import Map from './Map';
import Navbar from '../../Components/Navbar';
import Header from '../../Components/Header';
import apiCall from '../../Services/ApiCall';
import apiUrl from '../../Configs/ApiUrl';
import GetHeaders from '../../Configs/GetHeaders';
import {savePostcode} from '../Shop/actions';

const FindStore = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [stores, setStores] = useState([]);
  const {postcode} = useParams();
  useEffect(() => {
    dispatch(savePostcode(postcode));
    setLoading(true);
    apiCall(`${apiUrl}/branches/byPostcode/${postcode}/${1}`, GetHeaders)
      .then((branches) => {
        setStores(branches);
        setLoading(false);
      })
      .catch(() => {
        // error
        // console.log('error', error);
        setLoading(false);
      });
  }, [dispatch, postcode]);
  return (
    <Master navbar={Navbar}>
      <Header title="Find your nearest Store" />
      <section id="find-store">
        <Stores loading={loading} stores={stores} />
        <Map loading={loading} stores={stores} />
      </section>
    </Master>
  );
};

export default FindStore;
