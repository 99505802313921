import React from 'react';
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {saveShop} from '../Shop/actions';
import {emptyCart} from '../Cart/actions';
import {storeAvailability} from '../../Libs/store';
import Operation from './Operation';

const Store = ({store}) => {
  const dispatch = useDispatch();
  if (!store) {
    return null;
  }
  console.log(
    store.takeaway_schedule.takeawayDayStatus.monday === 1
      ? `${store.takeaway_schedule.takeawayFrom[1]}-${store.takeaway_schedule.takeawayTo[1]}`
      : 'OFFLINE',
  );
  return (
    <div className="store">
      <div className="header">
        <div className="info">
          <h1>{store.title}</h1>
          <span>({store.miles} miles)</span>
          <p>
            {store.address}, {store.zipcode}
          </p>
        </div>
        <div className="action">
          <Link
            to={`/shop/${store.slug}/menu`}
            onClick={() => {
              dispatch(saveShop(store));
              dispatch(emptyCart());
            }}
          >
            ORDER ONLINE
          </Link>
        </div>
      </div>
      <div className="status">
        <div className="phone">
          <p className="label">Telephone</p>
          <p className="value">{store.mobile}</p>
        </div>
        <div className="delivery">
          <p className="label">Delivery Service</p>
          <span className={storeAvailability(store, 'Delivery') ? 'online' : 'offline'}>
            {storeAvailability(store, 'Delivery') ? 'online' : 'offline'}
          </span>
          <p className="value">{store.delivery === 1 ? 'Yes' : 'No'}</p>
        </div>
        <div className="collection">
          <p className="label">Click & Collect</p>
          <span className={storeAvailability(store, 'Takeaway') ? 'online' : 'offline'}>
            {storeAvailability(store, 'Takeaway') ? 'online ' : 'offline'}
          </span>
          <p className="value">{store.takeaway === 1 ? 'Yes' : 'No'}</p>
        </div>
      </div>
      <Operation takeaway_schedule={store.takeaway_schedule} delivery_schedule={store.delivery_schedule} />
      <div className="social">
        {store.facebook_url ? (
          <a target="_blank" rel="noopener noreferrer" href={store.facebook_url} type="button">
            <i className="fab fa-facebook-f" aria-hidden="true" />
            Join us on Facebook
          </a>
        ) : null}
      </div>
    </div>
  );
};

export default Store;
