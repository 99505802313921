import {fromJS} from 'immutable';

import {
  ADD_TO_CART,
  ADD_VOUCHER_ERROR,
  ADD_VOUCHER_SUCCESS,
  CHANGE_CHECKOUT_MODE,
  CHANGE_QUANTITY,
  CLEAR_VOUCHER,
  EMPTY_CART,
  REMOVE_FROM_CART,
} from './constants';

const initialCartState = fromJS({
  products: [],
  voucher: {},
  mode: 'Delivery',
});

export default function cartReducer(state = initialCartState, action) {
  switch (action.type) {
    case ADD_TO_CART:
      return state.update('products', (products) =>
        products.push(fromJS(action.product)),
      );
    case REMOVE_FROM_CART:
      return state.deleteIn(['products', action.index]);
    case CHANGE_QUANTITY:
      return state.setIn(
        ['products', action.index, 'quantity'],
        action.quantity,
      );
    case EMPTY_CART:
      return state.set('products', fromJS([])).set('voucher', fromJS({}));
    case CHANGE_CHECKOUT_MODE:
      return state.set('mode', action.mode);
    case ADD_VOUCHER_SUCCESS:
      return state.set('voucher', fromJS(action.voucher));
    case ADD_VOUCHER_ERROR:
      return state.set('voucher', fromJS({}));
    case CLEAR_VOUCHER:
      return state.set('voucher', fromJS({}));
    default:
      return state;
  }
}
